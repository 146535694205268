import { useQuery, useSubscription } from "@apollo/client";
import { GET_ALL_INQUIRIES } from "./graphql/Query";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeBar from "./components/TimeBar";
import Origin from "./components/Origin";
import Destination from "./components/Destination";
import Place from "./components/Place";
import Position from "./components/Position";
import Source from "./components/Source";
import AddId from "./components/AddId";
import Status from "./components/Status";
import Flags from "./components/Flags";
import Consultants from "./components/Consultants";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { GET_INQ_LIST } from "~/graphql/inq_list/Query";
import LeaderBoard from "./components/LeaderBoard";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";

export default function Charts({ dates, brands, allBrand, source }: any) {
  const [canProfitArr, setCanProfitArr] = useState<any>([]);

  const brdIdsLet: any = [];
  allBrand?.map((brd: any) => {
    brdIdsLet.push(brd?.id);
  });
  const [brd_ids, setbrdIds] = useState<any>([...brdIdsLet]);

  useEffect(() => {
    const brdIdsLet: any = [];
    if (brands.length > 0 && brands?.[0]) {
      brands?.map((brd: any) => {
        brdIdsLet.push(brd?.id);
      });
    } else {
      allBrand?.map((brd: any) => {
        brdIdsLet.push(brd?.id);
      });
    }
    setbrdIds([...brdIdsLet]);
  }, [brands]);

  const customUrl = GetCustomUrl(window.location.host);

  const { thpHouse } = useThpListDetail(customUrl);

  const startDateTime = moment(dates?.[0]?.$d).startOf("day").toISOString();
  const endDateTime = moment(dates?.[1]?.$d).endOf("day").toISOString();

  const userRole = useUserDefaultRole();
  const logedUserId = useUserId();

  const consultantCondition = {
    _and: [
      {
        _or: [
          userRole === "consultant"
            ? {
                _or: [
                  {
                    _and: [
                      { picked_by: { _is_null: true } },
                      { status: { _eq: "pending" } },
                    ],
                  },
                  {
                    picked_by: { _eq: logedUserId },
                  },
                ],
              }
            : {},
        ],
      },
      {
        _or: [
          source.source_add_id !== "Organic" && source.source_add_id !== null
            ? {
                source_add_id: { _eq: source.source_add_id },
              }
            : {},
        ],
      },
      {
        _or: [
          source.source_add_id === "Organic"
            ? {
                source_add_id: { _is_null: true },
              }
            : {},
        ],
      },
    ],
    created_at: { _gte: startDateTime, _lte: endDateTime },
    brd_id: { _in: brd_ids },
  };

  const condition = {
    _and: [
      {
        _or: [
          source.source_add_id !== "Organic" && source.source_add_id !== null
            ? {
                source_add_id: { _eq: source.source_add_id },
              }
            : {},
        ],
      },
      {
        _or: [
          source.source_add_id === "Organic"
            ? {
                source_add_id: { _is_null: true },
              }
            : {},
        ],
      },
    ],
    created_at: { _gte: startDateTime, _lte: endDateTime },
    brd_id: { _in: brd_ids },
  };

  const { data, loading, error } = useQuery(GET_INQ_LIST, {
    variables: {
      condition,
    },
  });

  const {
    data: CD,
    loading: CL,
    error: CE,
  } = useQuery(GET_INQ_LIST, {
    variables: {
      condition: consultantCondition,
    },
  });

  if (loading) return <>Loading...</>;
  if (error) return <>{error.message}</>;
  if (CL) return <>Loading...</>;
  if (CE) return <>{CE.message}</>;

  return (
    <div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow items-cente px-2 w-full flex justify-between mb-4 py-4 px-6">
        <p className="flex items-center gap-2">
          <span className="font-bold">Remaining Invoice Credits:</span>
          <span>{thpHouse?.[0]?.invoice_credits}</span>
        </p>
        <p className="flex items-center gap-2">
          <span className="font-bold">Remaining Seat Credits:</span>{" "}
          <span>{thpHouse?.[0]?.seat_credits}</span>
        </p>
        <p className="flex items-center gap-2">
          <span className="font-bold">Remaining Brand Credits:</span>{" "}
          <span>{thpHouse?.[0]?.brand_credits}</span>
        </p>
      </div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow px-2 w-full mb-4 p-4">
        <LeaderBoard
          brd_ids={brd_ids}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          canProfitArr={canProfitArr}
          setCanProfitArr={setCanProfitArr}
        />
      </div>
      <div className="gap-4 w-full text-primary dark:text-dark-primary grid md:grid-cols-2 grid-cols-1">
        <div className="rounded-lg bg-secondary dark:bg-dark-secondary shadow px-5">
          <Status
            inquiries={userRole === "admin" ? data?.inq_list : CD.inq_list}
          />
        </div>
        <div className="rounded-lg bg-secondary dark:bg-dark-secondary shadow px-5">
          <Flags
            inquiries={userRole === "admin" ? data?.inq_list : CD?.inq_list}
          />
        </div>
      </div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow px-2 w-full mt-4 min-h-[300px]">
        <Consultants inquiries={data?.inq_list} />
      </div>
      <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary  shadow px-2 w-full mt-4 min-h-[300px]">
        <TimeBar inquiries={data?.inq_list} />.
      </div>
      {userRole === "admin" && (
        <>
          <div className="gap-4 w-full mt-4 text-primary dark:text-dark-primary grid md:grid-cols-2 grid-cols-1">
            <div className="rounded-lg bg-secondary dark:bg-dark-secondary shadow px-4 min-h-[250px]">
              <Origin inquiries={data?.inq_list} />
            </div>
            <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow px-4 min-h-[250px]">
              <Destination inquiries={data?.inq_list} />
            </div>
          </div>
          <div className=" text-primary dark:text-dark-primary gap-4 w-full mt-4 grid md:grid-cols-2 grid-cols-1">
            <div className="rounded-lg bg-secondary dark:bg-dark-secondary shadow px-4 min-h-[250px]">
              <Place inquiries={data?.inq_list} />
            </div>
            <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow px-4  min-h-[250px]">
              <Position inquiries={data?.inq_list} />
            </div>
          </div>
          <div className=" text-primary dark:text-dark-primary gap-4 w-full mt-4 grid md:grid-cols-2 grid-cols-1">
            <div className="rounded-lg bg-secondary dark:bg-dark-secondary shadow px-4 min-h-[250px]">
              <Source inquiries={data?.inq_list} />
            </div>
            <div className="rounded-lg text-primary dark:text-dark-primary bg-secondary dark:bg-dark-secondary shadow px-4 min-h-[250px]">
              <AddId inquiries={data?.inq_list} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
