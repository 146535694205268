import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { INSERT_SUGGESTION } from "./graphql/Mutation";
import { toast } from "react-toastify";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { GET_FARES } from "./graphql/Query";
import FareCard from "./components/FareCard";
import { Button } from "@mui/material";
import SideDrawer from "~/components/common/sideDrawer";
import AddSugesstion from "~/components/common/AddSugesstion";
import moment from "moment";
import { getAmadeusFlights } from "~/utils/flights";
import AddSugFares from "./components/AddSugFares";

const DestinationFares = ({ page, brd_id }: any) => {
  const form = useForm();
  const [insertSuggestion] = useMutation(INSERT_SUGGESTION);
  const { loading, data } = useQuery(GET_FARES, {
    variables: { id: page?.id },
  });
  const [opened, { open, close }] = useDisclosure(false);
  const client = useApolloClient();

  const [sidePopup, setSidePopup] = useState(false);

  const getLegData = (data: any, leg: string, cabin: string) => {
    let stops: any = [];
    let obj: any = {
      type: leg,
      cabin: cabin,
    };
    data?.stops?.map((stop: any, i: number) => {
      const item = {
        airline_iata: stop?.airline?.iata,
        depart: stop?.departCity?.iata_code,
        depart_datetime: stop?.departDateTime?.split("GMT")[0],
        arrival: stop?.arrivalCity?.iata_code,
        arrival_datetime: stop?.arrivalDateTime?.split("GMT")[0],
        flight_no: stop?.airlineNumber,
        ordering: i,
      };

      stops.push(item);
    });
    let temp = {
      data: [...stops],
    };
    obj["inq_suggestion_leg_stops"] = temp;
    return obj;
  };

  const submitSugesstion = async (inpData: any) => {
    // ;
    let finalObj: any = {
      airline_page_id: page?.id,
      brd_id: brd_id,
      inq_suggestion_costs: {
        data: {
          basic_fare: inpData?.costPrice,
          sale_price: inpData?.offerPrice,
        },
      },
    };

    if (Number(inpData?.tripType) === 1) {
      const leg = getLegData(
        inpData?.departLeg,
        "departLeg",
        inpData?.cabinType
      );
      finalObj["inq_suggestion_legs"] = {
        data: [leg],
      };
    } else if (Number(inpData?.tripType) === 2) {
      const leg = getLegData(
        inpData?.departLeg,
        "departLeg",
        inpData?.cabinType
      );
      const leg2 = getLegData(
        inpData?.returnLeg,
        "returnLeg",
        inpData?.cabinType
      );
      finalObj["inq_suggestion_legs"] = {
        data: [leg, leg2],
      };
    }

    // return 0;
    const res: any = await insertSuggestion({
      variables: {
        data: finalObj,
      },
    });
    if (res?.data?.insert_inq_suggestion_one?.id) {
      toast.success("Suggestion added successfuly");
      form?.reset();
      await client.resetStore();
      close();
    } else {
      toast.error("Something went wrong!");
    }
  };

  if (loading) return <p>loading...</p>;
  const suggestions = data?.inq_suggestion;
  return (
    <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow-sm 2xl:col-span-2  sm:p-6 ">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 text-xl font-semibold ">
          Destination Fares Settings
        </h3>
        <Button onClick={() => setSidePopup(!sidePopup)} variant="contained">
          <FaPlus /> Add Fare
        </Button>
      </div>
      <div className=" mt-3">
        {suggestions?.length > 0 &&
          suggestions?.map((sg: any, i: number) => (
            <FareCard key={i} sg={sg} />
          ))}
      </div>
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={<AddSugFares />}
      />
    </div>
  );
};

export default DestinationFares;
