import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useUserData, useUserId } from "@nhost/react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ASSIGNED_BRANDS } from "~/modules/DashboardModule/segments/DashboardStats/graphql/Query";
import { useEffect, useState } from "react";

export default function useAssigedBrands() {
  const { travelHouse }: any = travelHouseStore();
  const userId = useUserId();
  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: {
      id: userId,
      thp_id: travelHouse?.id,
    },
  });

  const [brands, setBrands] = useState(null);
  const [brd_ids, setBrdIds] = useState(null);
  const [brdPhones, setBrdPhones] = useState(null);

  useEffect(() => {
    const brdRec: any = [];
    const brdIds: any = [];
    const brd_phones: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({
            id: brd?.brd_id,
            name: brd?.brd_list?.name,
            phone: brd?.brd_list?.phoneNumberId,
          });
          brdIds.push(brd?.brd_id);
          brd_phones.push(brd?.brd_list?.phoneNumberId);
        });
      } else {
        data?.thp_list?.[0]?.brd_lists?.map((brd: any) => {
          brdRec.push({
            id: brd?.id,
            name: brd?.name,
            phone: brd?.brd_list?.phoneNumberId,
          });
          brdIds.push(brd?.id);
          brd_phones.push(brd?.brd_list?.phoneNumberId);
        });
      }
      setBrands(brdRec);
      setBrdIds(brdIds);
      setBrdPhones(brd_phones);
    }
  }, [data]);

  return { loading, brands, error, brd_ids, brdPhones, allBrdData: data };
}
