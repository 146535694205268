import React, { useState } from "react";
import _ from "lodash";
import Cookies from "universal-cookie";
import PassengerBox from "./components/PassengerBox";
import PassengerAdd from "./components/PassengerAdd";
import { useUserDefaultRole, useUserId } from "@nhost/react";

const PassengersCard = ({ inquiry }: any) => {
  // ;

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();
  const [add, setAdd] = useState(false);
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
      <div className="self-stretch flex pt-0 px-0 pb-[5px] items-center justify-start border-b-[1px] border-solid border-lightsteelblue">
        <b className="self-stretch relative leading-[20px]">Passengers</b>
        {inquiry.status !== "completed" && (
          <div className="flex justify-end gap-3 w-full">
            {!inquiry?.selected_suggestion &&
              (user_id === inquiry?.picked_by ||
                userDefaultRole === "admin") && (
                <button
                  onClick={() => setAdd(true)}
                  className="block border border-basic rounded-full px-3 py-1 text-basic cursor-pointer hover:bg-basic hover:text-white"
                >
                  Add Passenger
                </button>
              )}
          </div>
        )}
      </div>
      <div className="self-stretch grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 items-start justify-start gap-[33px] text-mini">
        {_.sortBy(inquiry?.inq_passengers, "created_at")?.map(
          (passenger: any, i: any) => (
            <PassengerBox
              selected_suggestion_id={inquiry?.suggestion_id}
              passenger={passenger}
              inquiry={inquiry}
              inq_id={inquiry.id}
              totalPassengers={inquiry?.inq_passengers?.length}
            />
          )
        )}
        {add && (
          <PassengerAdd
            setAdd={setAdd}
            inquiry_id={inquiry?.id}
            user_id={inquiry?.users.id}
            picked_by={inquiry?.picked_by}
            hideForm={setAdd}
            selected_suggestion_id={inquiry.suggestion_id}
          />
        )}
      </div>
    </div>
  );
};

export default PassengersCard;
