import SearchBar from "./components/SearchBar";
import ChatRow from "./components/ChatRow";
import Header from "./components/Header";
import { useEffect, useRef, useState } from "react";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_CONVERSATIONS, GET_QUERY_CONVERSATIONS } from "./graphql/Query";
import ChatRowSekeleton from "./components/Sekelton/components/ChatRowSekeleton";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import ErrorIcon from "@mui/icons-material/Error";
import dayjs from "dayjs";
import moment from "moment";
import Consultantfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Consultantfilter";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_CONSULTANTS } from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/graphql/Query";
import { UPDATE_CONV_CONSULTANT } from "~/graphql/brd_whatsapp_conversations/Mutation";
import { toast } from "react-toastify";
import useBrandWhatsappLabels from "~/hooks/useBrandWhatsappLabels";
import { ADD_CONV_LABELS } from "~/graphql/brd_whatsapp_conv_labels/Mutation";

const ConversationList = ({
  setShowWhatsappChatBox,
  setShowWhatsappSidebar,
  community,
  setShowLabelBar,
}: any) => {
  const { brands, brdPhones, brd_ids }: any = useAssigedBrands();

  const { whatsappLabels } = useBrandWhatsappLabels(brd_ids);

  const userRole = useUserDefaultRole();
  const userID = useUserId();

  const [updateConsultantID] = useMutation(UPDATE_CONV_CONSULTANT);
  const [insertConvLabels] = useMutation(ADD_CONV_LABELS);

  const { travelHouse }: any = travelHouseStore();
  const { loading, data, error } = useQuery(GET_CONSULTANTS, {
    variables: {
      thp_id: travelHouse?.id,
    },
  });

  const [brandPhones, setBrandPhones] = useState<any>();
  const [offset, setOffset] = useState<any>(0);
  const [conversations, setConversations] = useState<any>([]);
  const [filteredConv, setFilteredConv] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [selectedConsultant, setSelectedConsultant] = useState<any>([]);
  const [assignConsultant, setAssignConsultant] = useState<any>();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedConversations, setSelectedConversations] = useState<any>();
  const [selectedLabel, setSelectedLabel] = useState<any>([]);
  const [fiterLabel, setFilterLabel] = useState<any>();

  const today = dayjs();

  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];
  const [dates, setDates] = useState<any>(currentMonth);

  const startDate = moment(dates?.[0]?.$d).format("YYYY-MM-DD");
  const endDate = moment(dates?.[1]?.$d).format("YYYY-MM-DD");
  const startDateTime = `${startDate}T00:00:00Z`;
  const endDateTime = `${endDate}T23:59:59Z`;

  const [role, setRole] = useState<any>(["conversation", "assigned"]);

  const client = useApolloClient();

  const [conversationSearchTerm, setConversationSearchTerm] = useState("");

  useEffect(() => {
    if (!brandPhones) {
      setBrandPhones(brdPhones);
    }

    console.log("brdPhones", brdPhones);
  }, [brdPhones]);

  useEffect(() => {
    if (brandPhones?.length >= 1) {
      client
        .subscribe({
          query: GET_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              assigned_consultant: selectedConsultant?.[0]?.id
                ? { _eq: selectedConsultant?.[0]?.id }
                : {},
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .subscribe({
          next(conv: any) {
            if (conv?.data?.brd_whatsapp_conversations?.length < 1 && !offset) {
              setConversations([]);
            } else if (userRole === "admin") {
              setConversations([
                ...conversations,
                ...conv?.data?.brd_whatsapp_conversations,
              ]);
            } else if (userRole === "consultant") {
              const filteredConversations =
                conv?.data?.brd_whatsapp_conversations?.filter(
                  (conversation: any) => {
                    const showConsultantConv =
                      conversation?.brd_list?.brd_details?.[0]
                        ?.show_consultant_whatsapp_conv;
                    const isAssigned =
                      conversation?.assigned_consultant === userID;
                    return showConsultantConv || isAssigned;
                  }
                );
              setConversations([...conversations, ...filteredConversations]);
            }
          },
        });
    }
  }, [brandPhones, role, offset, fiterLabel]);

  useEffect(() => {
    setOffset(0);

    if (brandPhones?.length >= 1 && conversationSearchTerm?.length > 2) {
      client
        .query({
          query: GET_QUERY_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              ...(conversationSearchTerm
                ? {
                    _or: [
                      { user_phone: { _ilike: `%${conversationSearchTerm}%` } },
                      {
                        whatsapp_name: {
                          _ilike: `%${conversationSearchTerm}%`,
                        },
                      },
                    ],
                  }
                : {}),
              assigned_consultant: selectedConsultant?.[0]?.id
                ? { _eq: selectedConsultant?.[0]?.id }
                : {},
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .then((response) => {
          const conv = response.data;
          if (conv?.brd_whatsapp_conversations?.length < 1 && !offset) {
            setFilteredConv([]);
          } else if (userRole === "admin") {
            setFilteredConv([...conv?.brd_whatsapp_conversations]);
          } else if (userRole === "consultant") {
            const filteredConversations =
              conv?.brd_whatsapp_conversations?.filter((conversation: any) => {
                const showConsultantConv =
                  conversation?.brd_list?.brd_details?.[0]
                    ?.show_consultant_whatsapp_conv;
                const isAssigned = conversation?.assigned_consultant === userID;
                return showConsultantConv || isAssigned;
              });
            setFilteredConv([...filteredConversations]);
          }
        });
    }

    if (brandPhones?.length >= 1 && !conversationSearchTerm) {
      client
        .query({
          query: GET_QUERY_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              assigned_consultant: selectedConsultant?.[0]?.id
                ? { _eq: selectedConsultant?.[0]?.id }
                : {},
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .then((response) => {
          const conv = response.data;
          if (conv?.brd_whatsapp_conversations?.length < 1 && !offset) {
            setFilteredConv([]);
          } else if (userRole === "admin") {
            setFilteredConv([...conv?.brd_whatsapp_conversations]);
          } else if (userRole === "consultant") {
            const filteredConversations =
              conv?.brd_whatsapp_conversations?.filter((conversation: any) => {
                const showConsultantConv =
                  conversation?.brd_list?.brd_details?.[0]
                    ?.show_consultant_whatsapp_conv;
                const isAssigned = conversation?.assigned_consultant === userID;
                return showConsultantConv || isAssigned;
              });
            setFilteredConv([...filteredConversations]);
          }
        });
    }
  }, [conversationSearchTerm]);

  useEffect(() => {
    setOffset(0);

    if (
      brandPhones?.length >= 1 &&
      selectedConsultant?.length > 0 &&
      userRole === "admin"
    ) {
      client
        .query({
          query: GET_QUERY_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              assigned_consultant: selectedConsultant?.[0]?.id
                ? { _eq: selectedConsultant?.[0]?.id }
                : {},
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .then((response) => {
          const conv = response.data;
          if (conv?.brd_whatsapp_conversations?.length < 1 && !offset) {
            setFilteredConv([]);
          } else if (userRole === "admin") {
            setFilteredConv([...conv?.brd_whatsapp_conversations]);
          }
        });
    }

    if (
      brandPhones?.length >= 1 &&
      selectedConsultant?.length < 1 &&
      userRole === "admin"
    ) {
      client
        .query({
          query: GET_QUERY_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              ...(selectedConsultant?.[0]?.id && {
                assigned_consultant: { _eq: selectedConsultant?.[0]?.id },
              }),
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .then((response) => {
          const conv = response.data;
          if (conv?.brd_whatsapp_conversations?.length < 1 && !offset) {
            setFilteredConv([]);
          } else if (userRole === "admin") {
            setFilteredConv([...conv?.brd_whatsapp_conversations]);
          }
        });
    }
  }, [selectedConsultant]);

  useEffect(() => {
    setOffset(0);
    if (brandPhones?.length >= 1) {
      client
        .query({
          query: GET_QUERY_CONVERSATIONS,
          variables: {
            condition: {
              brand_phone: { _in: brandPhones },
              type: { _in: role },
              last_message_time: { _gte: startDateTime, _lte: endDateTime },
              ...(selectedConsultant?.[0]?.id && {
                assigned_consultant: { _eq: selectedConsultant?.[0]?.id },
              }),
              ...(fiterLabel && {
                whatsapp_labels: {
                  label_id: { _eq: fiterLabel },
                },
              }),
            },
            offset: offset,
          },
        })
        .then((response) => {
          const conv = response.data;
          if (conv?.brd_whatsapp_conversations?.length < 1 && !offset) {
            setFilteredConv([]);
          } else if (userRole === "admin") {
            setFilteredConv([...conv?.brd_whatsapp_conversations]);
          } else if (userRole === "consultant") {
            const filteredConversations =
              conv?.brd_whatsapp_conversations?.filter((conversation: any) => {
                const showConsultantConv =
                  conversation?.brd_list?.brd_details?.[0]
                    ?.show_consultant_whatsapp_conv;
                const isAssigned = conversation?.assigned_consultant === userID;
                return showConsultantConv || isAssigned;
              });
            setFilteredConv([...filteredConversations]);
          }
        })
        .catch((error) => {
          console.error("Error fetching conversations:", error);
        });
    }
  }, [dates]);

  useEffect(() => {
    setTimeout(() => {
      if (conversations?.length < 1) {
        setErrorMessage("No Conversations Found");
      }
    }, 8000);
  }, [conversations]);

  const assignConvs = async () => {
    try {
      if (selectedConversations?.length < 1) {
        toast.error("No Converstion is Selected");
      } else if (!assignConsultant) {
        toast.error("No Consultant is Selected");
      } else {
        await Promise.all(
          selectedConversations?.map(async (item: any) => {
            await updateConsultantID({
              variables: {
                id: item,
                ConsID: assignConsultant,
                type: "assigned",
              },
            });
          })
        );

        // Show success message if all promises are resolved
        toast.success("Conversations Assignes Successfully!");
        setSelectedConversations(null);
      }
    } catch (error) {}
  };

  const assignLabel = async () => {
    try {
      if (selectedLabel?.length < 1) {
        toast.error("No Label is Selected");
      } else {
        await Promise.all(
          selectedConversations?.map(async (item: any) => {
            await Promise.all(
              selectedLabel?.map(async (item1: any) => {
                await insertConvLabels({
                  variables: {
                    data: {
                      conversation_id: item,
                      label_id: item1,
                    },
                  },
                });
              })
            );
          })
        );

        // Show success message if all promises are resolved
        toast.success("Labels Assignes Successfully!");
        setSelectedConversations(null);
      }
    } catch (error) {}
  };

  return (
    <div className="border dark:border-dark-secondary md:max-w-[460px] w-full relative h-full flex flex-col">
      <div>
        <Header
          setShowLabelBar={setShowLabelBar}
          brand_phone={brandPhones}
          community={community}
          setSelectedConversations={setSelectedConversations}
          selectedConversations={selectedConversations}
        />
        <SearchBar
          setSearch={setConversationSearchTerm}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setConversations={setConversations}
          userRole={userRole}
          setSelectedConsultant={setSelectedConsultant}
          setErrorMessage={setErrorMessage}
          setBrandPhones={setBrandPhones}
          setOffset={setOffset}
          brdPhones={brdPhones}
          brands={brands}
          dates={dates}
          setDates={setDates}
          whatsappLabels={whatsappLabels}
          setFilterLabel={setFilterLabel}
        />
      </div>
      <div className="flex p-[20px] pb-[10px] items-center gap-3 bg-whatsappSecondaryBg dark:bg-dark-secondary">
        <Chip
          sx={{ cursor: "pointer" }}
          label="Conversations"
          variant={
            role?.[0] === "conversation" && role?.[1] === "assigned"
              ? "filled"
              : "outlined"
          }
          color="primary"
          onClick={() => {
            setFilteredConv([]);
            setConversations([]);
            setErrorMessage("");
            setOffset(0);
            setRole(["conversation", "assigned"]);
          }}
        />
        <Chip
          sx={{ cursor: "pointer" }}
          label="Others"
          variant={role?.[0] === "others" ? "filled" : "outlined"}
          color="primary"
          onClick={() => {
            setFilteredConv([]);
            setConversations([]);
            setErrorMessage("");
            setOffset(0);
            setRole(["others"]);
          }}
        />
        <Chip
          sx={{ cursor: "pointer" }}
          label="Assign"
          variant={role?.[0] === "assigned" ? "filled" : "outlined"}
          color="primary"
          onClick={() => {
            setFilteredConv([]);
            setConversations([]);
            setErrorMessage("");
            setOffset(0);
            setRole(["assigned"]);
          }}
        />
        <Chip
          sx={{ cursor: "pointer" }}
          label="UnAssign"
          variant={
            role?.[0] === "conversation" && !role?.[1] ? "filled" : "outlined"
          }
          color="primary"
          onClick={() => {
            setFilteredConv([]);
            setConversations([]);
            setErrorMessage("");
            setOffset(0);
            setRole(["conversation"]);
          }}
        />
      </div>

      <div className="text-primary dark:text-dark-primary bg-whatsappSecondaryBg dark:bg-dark-secondary overflow-scroll flex-grow">
        {selectedConversations && userRole === "admin" && (
          <div className="flex flex-col p-[20px] pt-0 items-center gap-3 bg-whatsappSecondaryBg dark:bg-dark-secondary">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={data?.users}
              onChange={(_: any, data: any) => {
                setAssignConsultant(data?.id);
              }}
              getOptionLabel={(option: any) =>
                option?.thp_users_profile?.[0]?.pseudo_name
                  ? `${option?.thp_users_profile?.[0]?.pseudo_name} (${option?.defaultRole})`
                  : `${option?.displayName} (${option?.defaultRole})`
              }
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Select Consultant" />
              )}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ fontSize: "13px" }}
              onClick={assignConvs}
            >
              Assign Consultant
            </Button>
            <div className="flex items-center flex-wrap justify-center">
              {whatsappLabels?.map((item: any) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => {
                          if (selectedLabel?.includes(item.id)) {
                            const newSelectedLabel = selectedLabel?.filter(
                              (item1: any) => item1 !== item.id
                            );
                            setSelectedLabel(newSelectedLabel);
                          } else {
                            setSelectedLabel([...selectedLabel, item.id]);
                          }
                        }}
                      />
                    }
                    label={item?.name}
                  />
                );
              })}
            </div>
            <Button
              fullWidth
              variant="contained"
              sx={{ fontSize: "13px" }}
              onClick={assignLabel}
            >
              Assign Label
            </Button>
          </div>
        )}
        {filteredConv?.length > 0 ? (
          <>
            {filteredConv?.map((conversation: any) => {
              return (
                <ChatRow
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                  setShowWhatsappSidebar={setShowWhatsappSidebar}
                  conversation={conversation}
                  setSelectedConversations={setSelectedConversations}
                  selectedConversations={selectedConversations}
                />
              );
            })}
          </>
        ) : conversations?.length < 1 && !errorMessage ? (
          <>
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
            <ChatRowSekeleton />
          </>
        ) : (
          <>
            {conversations?.map((conversation: any) => {
              return (
                <ChatRow
                  setShowWhatsappChatBox={setShowWhatsappChatBox}
                  setShowWhatsappSidebar={setShowWhatsappSidebar}
                  conversation={conversation}
                  setSelectedConversations={setSelectedConversations}
                  selectedConversations={selectedConversations}
                />
              );
            })}
          </>
        )}
        {errorMessage && conversations?.length < 1 && (
          <div className="flex items-center justify-center gap-3 mt-12">
            <ErrorIcon color="error" />
            <p className="font-bold">{errorMessage}</p>
          </div>
        )}
        {!conversationSearchTerm && conversations?.length > 0 && (
          <div className="flex py-3 justify-center items-center bg-whatsappSecondaryBg dark:bg-dark-secondary">
            <ExpandMoreIcon
              onClick={() => setOffset(offset + 10)}
              sx={{ cursor: "pointer" }}
              color="primary"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationList;
