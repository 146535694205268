import { getCurrentUrl } from "../utils/getCurrentUrl";

const sideMenuList = [
  //Admin side menu
  {
    role: "admin",
    menuItems: [
      {
        name: "Admin",
        url: `/`,
        subMenu: [
          {
            name: "Dashboard",
            url: `/admin`,
          },
          {
            name: "Inquiries",
            url: `/admin/inquiries`,
          },
          {
            name: "Customers",
            url: `/admin/customers`,
          },
          {
            name: "Vendors",
            url: `/admin/vendors`,
          },
          {
            name: "Tickets Request",
            url: `/admin/ticket-requests`,
          },
        ],
      },
      {
        name: "Accounts & HR",
        url: `/`,
        subMenu: [
          {
            name: "Payments Request",
            url: `/admin/customer-payments`,
          },
          {
            name: "Payments Methods",
            url: `/admin/payment-methods`,
          },
          {
            name: "Journal Transactions",
            url: `/admin/transactions`,
          },
          {
            name: "Accounts List",
            url: `/admin/accounts`,
          },
          {
            name: "Mark Attendance",
            url: `/admin/attendance`,
          },
          {
            name: " Process Payroll",
            url: `/admin/process-payroll`,
          },
          {
            name: "Google Ads",
            url: `/admin/google-ads`,
          },
          {
            name: "Google Campaigns",
            url: `/admin/google-campaigns`,
          },
        ],
      },
      {
        name: "Reports",
        url: `/`,
        subMenu: [
          {
            name: "Comissions Report",
            url: `R#1`,
          },
        ],
      },
    ],
  },
  {
    role: "manager",
    menuItems: [
      {
        name: "Dashboard",
        url: "/manager",
      },
      {
        name: "Customers",
        url: "/manager/customers",
      },
      {
        name: "Inquiries",
        url: "/manager/inquiries/new-customer-inquiries",
      },
      {
        name: "Assigned Inquiries",
        url: "/manager/inquiries/assigned-inquiries",
      },
      {
        name: "Inbox",
        url: `/manager/inbox`,
      },
      {
        name: "Subscriptions",
        url: "/manager/subscriptions",
      },
      {
        name: "Teams",
        url: "/manager/teams",
      },
      {
        name: "Vendors",
        url: "/manager/vendors",
      },
      {
        name: "Payments Methods",
        url: "/manager/payment-methods",
      },
      {
        name: "Commissions",
        url: "/manager/commission",
      },
      {
        name: "Profit Slabs",
        url: "/manager/profit-slabs",
      },
    ],
  },

  //Consultants side menu
  {
    role: "consultant",
    menuItems: [
      {
        name: "Dashboard",
        url: `/consultant`,
      },
      {
        name: "Inquiries",
        url: `/consultant/inquiries`,
      },
    ],
  },

  //User or customers side menu
  {
    role: "user",
    menuItems: [
      {
        name: "Dashboard",
        url: `/user`,
      },

      {
        name: "Bookings",
        url: `/user/bookings`,
      },

      // {
      //   name: "Payments",
      //   url: `/user/payments`,
      // },
      // {
      //   name: "Inbox",
      //   url: `/user/inbox`,
      // },
    ],
  },

  //Accountants side menu
  {
    role: "accountant",
    menuItems: [
      {
        name: "Dashboard",
        url: "/accountant",
      },
      {
        name: "Payments Request",
        url: `/accountant/customer-payments`,
      },
      {
        name: "Payments Methods",
        url: `/accountant/payment-methods`,
      },
      {
        name: "Journal Transactions",
        url: `/accountant/transactions`,
      },
      {
        name: "Accounts List",
        url: `/accountant/accounts`,
      },
      {
        name: "Mark Attendance",
        url: `/accountant/attendance`,
      },
      {
        name: " Process Payroll",
        url: `/accountant/process-payroll`,
      },

      {
        name: "Vendors",
        url: `/accountant/vendors`,
      },
    ],
  },
  {
    role: "super-admin",
    menuItems: [
      {
        name: "Dashboard",
        url: "/super-admin",
      },
      {
        name: "Travel Houses",
        url: "/super-admin/travel-houses",
      },
    ],
  },
];

export default sideMenuList;
