import THPLogo from "../../../assets/svg/THPLogo";
import ArrowRight from "../../../assets/svg/ArrowRight";
import WhiteLine from "../../../assets/svg/WhiteLine";
import DashboardLogo from "../../../assets/svg/DsahboardLogo.svg";
import DashboardLogoWhite from "../../../assets/svg/DashboardLogoWhite.svg";
import { NavLink } from "react-router-dom";

// import commonStore from "@store/common";
// import brandDetail from "@store/brandDetail";
import travelHouseStore from "../../../store/travelHouse/travelHouseStore";
import commonStore from "~/store/commonStore/commonStore";
import { useClickOutside } from "@mantine/hooks";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AirplaneTicketOutlinedIcon from "@mui/icons-material/AirplaneTicketOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

import CustomList from "./components/CustomList";
import { useUserDefaultRole } from "@nhost/react";
const SideBar = ({ showMenu, setShowMenu }: any) => {
  const sidebarRef = useClickOutside(() => setSidePopup(false));
  const { travelHouse }: any = travelHouseStore();
  const { sidePopup, setSidePopup } = commonStore();
  const role = useUserDefaultRole();
  // const handleClick = (name: String) => {
  // setActiveItem(name);
  // };
  const currentPath = window.location.pathname;
  const [activeDropdown, setActiveDropdown] = useState(currentPath);

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  return (
    <div className="dark:bg-dark-primary bg-primary">
      <Box
        sx={{
          width: "260px",
          maxWidth: 360,
          // bgcolor: "#F4F5FA",
          paddingRight: "10px",
          overflow: "scroll",
          height: "100vh",
        }}
      >
        <div className="px-6 shadow-white dark:shadow-[#28183D] fixed bg-primary dark:bg-dark-primary w-[260px] z-10 flex items-center justify-between pt-5 shadow-2xl">
          <img
            className="w-[160px]"
            alt="Dashboard Logo"
            src={darkMode ? DashboardLogoWhite : DashboardLogo}
          />
          <IconButton
            className="lg:hidden flex"
            onClick={() => setShowMenu(!showMenu)}
          >
            <RxCross2 className="lg:hidden " size={25} />
          </IconButton>
        </div>

        {role === "admin" ? (
          <div className="text-primary mt-[70px] z-30">
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              heading="ADMIN"
              listItems={[
                {
                  name: "Dashboard",
                  icon: <DashboardOutlinedIcon />,
                  link: "/admin",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Inquiries",
                  icon: <DescriptionOutlinedIcon />,
                  link: "/admin/inquiries",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Customers",
                  icon: <GroupOutlinedIcon />,
                  link: "/admin/customers",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Vendors",
                  icon: <GroupOutlinedIcon />,
                  link: "/admin/vendors",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Tickets Request",
                  icon: <AirplaneTicketOutlinedIcon />,
                  link: "/admin/ticket-requests",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              heading="ACCOUNTS & HR"
              listItems={[
                {
                  name: "Payments Request",
                  icon: <RequestQuoteOutlinedIcon />,
                  link: "/admin/customer-payments",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Payments Methods",
                  icon: <PaymentOutlinedIcon />,
                  link: "/admin/payment-methods",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Journal Transactions",
                  icon: <AccountBalanceOutlinedIcon />,
                  link: "/admin/transactions",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Accounts List",
                  icon: <RecentActorsOutlinedIcon />,
                  link: "/admin/accounts",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Mark Attendance",
                  icon: <ScheduleOutlinedIcon />,
                  link: "/admin/attendance",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Process Payroll",
                  icon: <PaidOutlinedIcon />,
                  link: "/admin/process-payroll",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Google Ads",
                  icon: <AdsClickIcon />,
                  link: "/admin/google-ads",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Google Campaigns",
                  icon: <CampaignIcon />,
                  link: "/admin/google-campaigns",
                },
              ]}
            />

            {/* <CustomList
            setActiveDropdown={setActiveDropdown}
            activeDropdown={activeDropdown}
            heading="Accounts & HR"
            listItems={[
              {
                name: "Inqtext-primary mt-[70px] z-30uires",
                icon: <HomeOutlinedIcon />,
                link: "/inquiries",
                subMenu: [
                  { name: "Inquires", link: "/admin/inquiries" },
                  { name: "Customers", link: "/admin/customers" },
                ],
              },
            ]}
          /> */}
          </div>
        ) : role === "consultant" ? (
          <div className="">
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              heading="ADMIN"
              listItems={[
                {
                  name: "Dashboard",
                  icon: <DashboardOutlinedIcon />,
                  link: "/consultant",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Inquiries",
                  icon: <DescriptionOutlinedIcon />,
                  link: "/consultant/inquiries",
                },
              ]}
            />
            <CustomList
              setActiveDropdown={setActiveDropdown}
              activeDropdown={activeDropdown}
              listItems={[
                {
                  name: "Customers",
                  icon: <GroupOutlinedIcon />,
                  link: "/consultant/customers",
                },
              ]}
            />
          </div>
        ) : (
          role === "super-admin" && (
            <div className="text-primary mt-[70px] z-30">
              <CustomList
                setActiveDropdown={setActiveDropdown}
                activeDropdown={activeDropdown}
                heading="ADMIN"
                listItems={[
                  {
                    name: "Dashboard",
                    icon: <DashboardOutlinedIcon />,
                    link: "/super-admin",
                  },
                ]}
              />
              <CustomList
                setActiveDropdown={setActiveDropdown}
                activeDropdown={activeDropdown}
                listItems={[
                  {
                  name: "Seasonal",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin/seasonal",
                  },
                ]}
               />
               <CustomList
                setActiveDropdown={setActiveDropdown}
                activeDropdown={activeDropdown}
                listItems={[
                  {
                  name: "Email Templates",
                  icon: <DashboardOutlinedIcon />,
                  link: "/super-admin/email-templates",
                  },
                ]}
               />
            </div>
          )
        )}

        {/* <List sx={{ color: '#38333F', marginTop: '45px', marginBottom: '20px' }}>
          <div className="flex items-center">
            <hr className="w-[10%]" /><span className="text-[12px] text-center w-[70%] text-gray-400">APPS & PAGES</span><hr className="w-full" />
          </div>
          <ListItem disablePadding sx={{ display: "list-item" }}>
            <ListItemButton onClick={() => {
              if (open && dropDownNm === 'dashboards') {
                setOpen(false);
              } else {
                setOpen(true);
                setDropDownNm('dashboards');
              }
            }} sx={open && dropDownNm === 'dashboards' ? dropDownHeadActive : defaultItemStyle}>
              <BiHome className="text-black mr-3 pl-1" size={25} />
              <ListItemText primary="Dashboards" />
              {(open && dropDownNm === 'dashboards') ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
            <Collapse sx={{ color: '#38333F' }} in={open && dropDownNm === 'dashboards'} timeout="auto" unmountOnExit>

              <ListItemButton onClick={() => {
                setMenuValue('crm')
              }} sx={menuValue === 'crm' ? activeItem : defaultItemStyle} >
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={15} />
                <ListItemText primary="CRM" />
              </ListItemButton>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => {
                  setMenuValue('analytics')
                }} sx={menuValue === 'analytics' ? activeItem : defaultItemStyle}>
                  <BsCircle className={`mr-3 pl-1 ${menuValue === 'analytics' && 'text-white'} `} size={15} />
                  <ListItemText primary="Analytics" />
                </ListItemButton>
              </List>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => {
                  setMenuValue('eCommerce')
                }} sx={menuValue === 'eCommerce' ? activeItem : defaultItemStyle}>
                  <BsCircle className={`mr-3 pl-1 ${menuValue === 'eCommerce' && 'text-white'} `} size={15} />
                  <ListItemText primary="eCommerce" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        </List>


        <List sx={{ color: '#38333F', marginTop: '45px', marginBottom: '20px' }}>
          <div className="flex items-center">
            <hr className="w-[10%]" /><span className="text-[12px] text-center w-[70%] text-gray-400">APPS & PAGES</span><hr className="w-full" />
          </div>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              setMenuValue('email')
            }} sx={menuValue === 'email' ? activeItem : defaultItemStyle}>
              <MdOutlineMarkEmailRead className={`mr-3 pl-1 ${menuValue === 'email' && 'text-white'} `} size={30} />
              <ListItemText primary="Email" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              setMenuValue('chat')
            }} sx={menuValue === 'chat' ? activeItem : defaultItemStyle}>
              <BiChat className={`mr-3 pl-1 ${menuValue === 'chat' && 'text-white'} `} size={30} />
              <ListItemText primary="Chat" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              setMenuValue('calender')
            }} sx={menuValue === 'calender' ? activeItem : defaultItemStyle}>
              <BiCalendarEvent className={`mr-3 pl-1 ${menuValue === 'calender' && 'text-white'} `} size={30} />
              <ListItemText primary="Calender" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              if (open && dropDownNm === 'invoice') {
                setOpen(false);
              } else {
                setOpen(true);
                setDropDownNm('invoice');
              }
            }} sx={open && dropDownNm === 'invoice' ? dropDownHeadActive : defaultItemStyle}>
              <FaFileInvoice className="text-black mr-3 pl-1" size={25} />
              <ListItemText primary="Invoice" />
              {open && dropDownNm === 'invoice' ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open && dropDownNm === 'invoice'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('list')
              }} sx={menuValue === 'list' ? activeItem : defaultItemStyle} >
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="List" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('preview')
              }} sx={menuValue === 'preview' ? activeItem : defaultItemStyle}>
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="Preview" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('edit')
              }} sx={menuValue === 'edit' ? activeItem : defaultItemStyle}>
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="Edit" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('add')
              }} sx={menuValue === 'add' ? activeItem : defaultItemStyle}>
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="Add" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              if (open && dropDownNm === 'user') {
                setOpen(false);
              } else {
                setOpen(true);
                setDropDownNm('user');
              }
            }} sx={open && dropDownNm === 'user' ? dropDownHeadActive : defaultItemStyle}>
              <BiUser className="text-black mr-3 pl-1" size={25} />
              <ListItemText primary="User" />
              {open && dropDownNm === 'user' ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open && dropDownNm === 'user'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('list2')
              }} sx={menuValue === 'list2' ? activeItem : defaultItemStyle} >
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="List" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('view')
              }} sx={menuValue === 'view' ? activeItem : defaultItemStyle}>
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="View" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
              if (open && dropDownNm === 'roles&permissions') {
                setOpen(false);
              } else {
                setOpen(true);
                setDropDownNm('roles&permissions');
              }
            }} sx={open && dropDownNm === 'roles&permissions' ? dropDownHeadActive : defaultItemStyle}>
              <MdSecurity className="text-black mr-3 pl-1" size={25} />
              <ListItemText primary="Roles & Permissions" />
              {open && dropDownNm === 'roles&permissions' ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open && dropDownNm === 'roles&permissions'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('roles')
              }} sx={menuValue === 'roles' ? activeItem : defaultItemStyle} >
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="Roles" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton onClick={() => {
                setMenuValue('permissions')
              }} sx={menuValue === 'permissions' ? activeItem : defaultItemStyle}>
                <BsCircle className={`mr-3 pl-1 ${menuValue === 'crm' && 'text-white'} `} size={13} />
                <ListItemText primary="Permissions" />
              </ListItemButton>
            </List>
          </Collapse>

        </List> */}
        {/* <List sx={{ color: '#3A3541' }}>

        </List> */}
      </Box>
    </div>
    // <div
    //   ref={sidebarRef}
    //   id="sidebar"
    //   className={`bg-gradient-to-b from-primary to-secondary max-w-[280px] lg:max-w-[200px] lg:max-w-[280px] w-full text-white shadow-lg h-full flex flex-col absolute md:static z-[6] transition-all ${
    //     sidePopup ? `left-[0]` : `left-[-100%]`
    //   } top-0`}
    // >
    //   {/* ${sidebar ? `sidebar-collapse` : ""} */}
    //   <button
    //     id="hamburgerBtn"
    //     onClick={() => setSidePopup(!sidePopup)}
    //     className="w-[25px] h-[25px] text-[18px] font-[700] bg-white flex items-center justify-center rounded-full absolute right-[15px] top-[15px] md:hidden focus:outline-none text-black"
    //   >
    //     <FaTimes />
    //   </button>
    //   <div className="flex flex-col justify-between relative h-[inherit] overflow-auto hide-scroll z-[2] mt-[50px] md:mt-0">
    //     <div>
    //       <div className="px-[20px] lg:px-[38px] pt-[26px] pb-[38px] text-center">
    //         {travelHouse?.logo ? (
    //           <img
    //             src={travelHouse?.logo}
    //             className="w-auto max-h-[60px] block mx-auto"
    //             alt=""
    //           />
    //         ) : (
    //           <DashboardLogo />
    //         )}
    //         <hr className="border-[1px] border-white w-full mx-auto mt-4" />
    //       </div>
    //       <ul className="flex-grow px-[20px] lg:px-[38px]">
    //         {menu?.map((item: any, i: any) => (
    //           <>
    //             {item?.subMenu ? (
    //               <SubmenuItemNew key={i} item={item} />
    //             ) : (
    //               <li className="flex gap-3 items-center">
    //                 <NavLink
    //                   key={i}
    //                   // className={`px-[22px] mb-2 py-1 block menu-link relative text-[15px] `}
    //                   className={({ isActive, isPending }) =>
    //                     isPending
    //                       ? ""
    //                       : isActive
    //                       ? "px-[22px] mb-2 py-1 block menu-link relative text-[15px] w-full bg-[#0000003b] rounded-[10px] "
    //                       : "px-[22px] mb-2 py-1 block menu-link relative text-[15px] w-full "
    //                   }
    //                   to={item.url}
    //                   onClick={() => handleClick(item.name)}
    //                   end
    //                 >
    //                   <span className="absolute left-0 mt-[3px]">
    //                     <ArrowRight />
    //                   </span>
    //                   {item.name}
    //                 </NavLink>
    //               </li>
    //             )}
    //           </>
    //         ))}
    //       </ul>
    //     </div>
    //     {/* <div className="px-[20px] lg:px-[38px] pb-[100px] ">
    //       <NavLink
    //         className={({ isActive, isPending }) =>
    //           isPending
    //             ? ""
    //             : isActive
    //             ? "px-[22px] mb-2 py-1 block menu-link relative text-[15px] w-full bg-[#0000003b] rounded-[10px] "
    //             : "px-[22px] mb-2 py-1 block menu-link relative text-[15px] w-full "
    //         }
    //         to="/logout"
    //         end
    //       >
    //         <span className="absolute left-0 mt-[3px]">
    //           <ArrowRight />
    //         </span>
    //         Settings
    //       </NavLink>
    //     </div> */}
    //   </div>
    //   {/* <THPLogo className="z-[1] -[90px] absolute  mt-[32%]" /> */}
    // </div>
  );
};

export default SideBar;
