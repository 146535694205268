import { gql } from "@apollo/client";

export const GET_ATTENDANCE = gql`
  query GetAttendance($user_id: uuid!, $date: date) {
    thp_attendance(
      where: { user_id: { _eq: $user_id }, date: { _eq: $date } }
    ) {
      id
      hours
      minutes
    }
  }
`;
