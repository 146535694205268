import React, { useState } from "react";
import TicketRequestForm from "./components/TicketRequestForm";
import TicketRequestCard from "./components/TicketRequestCard";
import CloseInquiry from "./components/CloseInquiry";
import CompleteInquiry from "./components/CompleteInquiry";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import SideDrawer from "~/components/common/sideDrawer";
import RefundInquiry from "./components/RefundInquiry";

export default function TicketRequest({ inquiry }: any) {
  const [hideForm, setHideForm] = useState(false);
  const role = useUserDefaultRole();
  const user_id = useUserId();

  const [sidePopup, setSidePopup] = useState<any>(false);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[30px] mb-5">
        <div className="self-stretch flex justify-between pt-0 px-0 pb-[5px] items-start border-b-[1px] border-solid border-lightsteelblue">
          <b className="self-stretch relative leading-[20px] w-full">Ticket</b>
          <div className="flex w-[350px] gap-2">
            <div className="flex justify-end gap-3 w-full">
              {inquiry.status !== "refunded" && (
                <button
                  onClick={() => setSidePopup(true)}
                  className="block border border-basic rounded-full px-3 py-1 text-basic cursor-pointer hover:bg-basic hover:text-white"
                >
                  Refund and Close
                </button>
              )}
              {(user_id === inquiry?.picked_by || role === "admin") &&
                inquiry.status !== "completed" &&
                inquiry.status !== "refunded" && (
                  <CompleteInquiry inquiry={inquiry} />
                )}
            </div>
          </div>
        </div>

        {inquiry?.inq_tickets[0] ? (
          <div className="self-stretch grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4  items-start justify-start gap-[30px] text-mini">
            <TicketRequestCard inquiry={inquiry} />
          </div>
        ) : (
          <TicketRequestForm inquiry={inquiry} setHideForm={setHideForm} />
        )}
      </div>
      <SideDrawer
        setSidePopup={setSidePopup}
        sidePopup={sidePopup}
        component={
          <RefundInquiry inquiry={inquiry} setSidePopup={setSidePopup} />
        }
      />
    </>
  );
}
