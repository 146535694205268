import { gql } from "@apollo/client";

export const ADD_ATTENDANCE = gql`
  mutation thp_attendance($data: [thp_attendance_insert_input!]!) {
    insert_thp_attendance(objects: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ATTENDANCE = gql`
  mutation MyMutation($id: uuid, $data: thp_attendance_set_input!) {
    update_thp_attendance(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
