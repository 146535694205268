import React from "react";
import { DEFAULT_PAYMENT_METHODS } from "./graphql/query";
import { useQuery } from "@apollo/client";

export default function SelectPayType({
  paymentMethods,
  setPayType,
  setStripe,
}: any) {
  const { loading, data, error } = useQuery(DEFAULT_PAYMENT_METHODS);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="max-w-[900px] w-full mx-auto mb-4 border-gray-700 border-b-2 pb-[25px] border-dashed	"
    >
      <div className="flex items-center gap-2 text-[12px]">
        {paymentMethods.filter((pay: any) => pay.type === "bank").length >
          0 && (
          <div className="flex gap-1 items-center">
            <input
              value="bank"
              type="radio"
              name="pay_type"
              onClick={(e: any) => {
                setPayType(e.target.value);
              }}
              className="rounded-full border  border-gray-300"
              id="bank"
            />
            <label htmlFor="bank" className="m-0 font-light">
              Bank
            </label>
          </div>
        )}
        {paymentMethods.filter(
          (pay: any) => pay.type === "card" && pay.default === true
        ).length > 0 && (
          <div className="flex gap-1 items-center">
            <input
              type="radio"
              value="card"
              name="pay_type"
              onClick={(e: any) => {
                setPayType(e.target.value);
                setStripe(
                  paymentMethods.filter(
                    (pay: any) => pay.type === "card" && pay.default === true
                  )[0]
                );
              }}
              className="rounded-full border  border-gray-300"
              id="card"
            />
            <label htmlFor="card" className="m-0 font-light">
              Card
            </label>
          </div>
        )}
        {paymentMethods.filter(
          (pay: any) => pay.type === "stripe-thp" && pay.default === true
        ).length > 0 && (
          <div className="flex gap-1 items-center">
            <input
              type="radio"
              value="stripe"
              name="pay_type"
              onClick={(e: any) => {
                setPayType(e.target.value);
                setStripe({
                  id: paymentMethods.filter(
                    (pay: any) =>
                      pay.type === "stripe-thp" && pay.default === true
                  )[0].id,
                  stripe_pub: data.def_stripe[0].public_key,
                  stripe_secret: data.def_stripe[0].secret_key,
                });
              }}
              className="rounded-full border  border-gray-300"
              id="stripe"
            />
            <label htmlFor="stripe" className="m-0 font-light">
              Stripe THP
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
