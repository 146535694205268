import { Autocomplete, Box, TextField } from "@mui/material";
import useAccTransactions from "~/hooks/useAccTransactions";
import useAccountList from "~/hooks/useAccountList";
import useAllBrandPaymentMethodName from "~/hooks/useAllBrandPaymentMethodName";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useUsers from "~/hooks/useUsers";
import Brandfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Brandfilter";
import Consultantfilter from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/Consultantfilter";
import DateRangePickerCustom from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";

const TransactionFilters = ({
  setSelectedBrand,
  dates,
  setDates,
  setSelectedTransType,
  setSelectedTransAcc,
  setSelectedPayMeth,
  setSelectedUser,
  setInquiryNo,
  setTransactionNo,
  setSelectedConsultant,
  travelHouse,
}: any) => {
  const { brd_ids } = useAssigedBrands();

  const { accountList }: any = useAccountList();

  const { paymentMethodName }: any = useAllBrandPaymentMethodName(brd_ids);

  const { users }: any = useUsers(brd_ids);

  const accListTypes: any = Array.from(
    new Set(accountList.map((account: any) => account.type))
  );

  const accListNames: any = Array.from(
    new Set(accountList.map((account: any) => account.name))
  );

  return (
    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-3">
      <DateRangePickerCustom dates={dates} setDates={setDates} />
      <div className="mt-[8px]">
        <Brandfilter setSelectedBrand={setSelectedBrand} />
      </div>
      <div className="mt-[8px]">
        <Autocomplete
          sx={{ marginBottom: "10px" }}
          disablePortal
          onChange={(_, data: any) => {
            setSelectedTransType(data);
          }}
          options={accListTypes || []}
          getOptionLabel={(option: any) => option}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField label="Transaction Type" {...params} />
          )}
          noOptionsText=""
        />
      </div>
      <div className="mt-[8px]">
        <Autocomplete
          sx={{ marginBottom: "10px" }}
          disablePortal
          onChange={(_, data: any) => {
            setSelectedTransAcc(data);
          }}
          options={accListNames || []}
          getOptionLabel={(option: any) => option}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField label="Transaction Account" {...params} />
          )}
          noOptionsText=""
        />
      </div>
      <div className="mt-[8px]">
        <Autocomplete
          sx={{ marginBottom: "10px" }}
          disablePortal
          onChange={(_, data: any) => {
            setSelectedPayMeth(data?.id);
          }}
          options={paymentMethodName || []}
          getOptionLabel={(option: any) => option.name}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField label="Payment Method" {...params} />
          )}
          noOptionsText=""
        />
      </div>
      <div className="mt-[8px]">
        <Autocomplete
          sx={{ marginBottom: "10px" }}
          disablePortal
          onChange={(_, data: any) => {
            setSelectedUser(data?.id);
          }}
          options={users || []}
          getOptionLabel={(option: any) => option.displayName}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.displayName}
            </Box>
          )}
          renderInput={(params) => <TextField label="User" {...params} />}
          noOptionsText=""
        />
      </div>
      <div className="mt-[8px]">
        <TextField
          fullWidth
          label="Transaction No"
          onChange={(e) => setTransactionNo(e.target.value)}
        />
      </div>
      <div className="mt-[8px]">
        <TextField
          fullWidth
          label="Inquiry No"
          onChange={(e: any) => setInquiryNo(e.target.value)}
        />
      </div>
      <div className="mt-[8px]">
        <Consultantfilter
          setSelectedConsultant={setSelectedConsultant}
          travelHouse={travelHouse}
        />
      </div>
    </div>
  );
};

export default TransactionFilters;
