import React from "react";

export default function Money() {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.047 0.3125H2C1.44772 0.3125 1 0.760215 1 1.3125V8.6875C1 9.23978 1.44772 9.6875 2 9.6875H14.047C14.5993 9.6875 15.047 9.23978 15.047 8.6875V1.3125C15.047 0.760215 14.5993 0.3125 14.047 0.3125Z"
        fill="#393939"
      />
      <path
        d="M8.02351 6.875C8.7993 6.875 9.42821 6.03553 9.42821 5C9.42821 3.96447 8.7993 3.125 8.02351 3.125C7.24771 3.125 6.6188 3.96447 6.6188 5C6.6188 6.03553 7.24771 6.875 8.02351 6.875Z"
        fill="white"
      />
      <path
        d="M14.047 0.3125H2C1.44772 0.3125 1 0.760215 1 1.3125V8.6875C1 9.23978 1.44772 9.6875 2 9.6875H14.047C14.5993 9.6875 15.047 9.23978 15.047 8.6875V1.3125C15.047 0.760215 14.5993 0.3125 14.047 0.3125Z"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6423 6.875V3.125C12.8463 3.125 12.2376 2.51562 12.2376 1.71875H3.8094C3.8094 2.51562 3.2007 3.125 2.4047 3.125V6.875C3.2007 6.875 3.8094 7.48438 3.8094 8.28125H12.2376C12.2376 7.48438 12.8463 6.875 13.6423 6.875Z"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.02351 6.875C8.7993 6.875 9.42821 6.03553 9.42821 5C9.42821 3.96447 8.7993 3.125 8.02351 3.125C7.24771 3.125 6.6188 3.96447 6.6188 5C6.6188 6.03553 7.24771 6.875 8.02351 6.875Z"
        stroke="white"
        stroke-width="0.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
