import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  getPaidAmount,
  getProfitAmount,
  getSalePrice,
} from "~/modules/InquiriesModule/utils/getInquiryStats";
import {
  ADD_TRANSACTION,
  COMPLETE_INQ,
  EDIT_INQUIRY,
} from "./graphql/Mutation";
import useNotifications from "~/hooks/useNotifications";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import moment from "moment";
import useEmail from "~/hooks/emails/useEmail";
import { URLs } from "~/config/enums";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function CompleteInquiry({ inquiry }: any) {
  const [modalOpen, setModelOpen] = useState(false);
  const [completedInq] = useMutation(COMPLETE_INQ);
  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const client = useApolloClient();
  const alert = useNotifications();
  const emailSend = useEmail();

  const [date, setDate] = useState<any>(new Date());

  // djkfhsjf

  const paidAmount = inquiry?.inq_transections
    ?.filter((transaction: any) =>
      ["paid", "partiallyRefunded"].includes(transaction.status)
    )
    ?.map((transaction: any) =>
      transaction.status === "partiallyRefunded"
        ? transaction.amount - transaction.refund_amount
        : transaction.amount
    )
    ?.reduce((sum: number, amount: number) => sum + amount, 0);

  const closeInquiry = async () => {
    if (paidAmount < getSalePrice(inquiry)) {
      toast.error("Paid amount is less then inquiry sale price!");
      return 0;
    }

    if (
      inquiry?.inq_tickets?.[0]?.status !== "issued" ||
      inquiry?.inq_tickets?.[0]?.inq_ticket_files?.[0]?.file_url === null
    ) {
      toast.error("Ticket not issued!");
      return 0;
    }

    const tenDigitCode: any = generateRandom10DigitCode();
    const tenDigitCode2: any = generateRandom10DigitCode();

    const commissionPercentage =
      inquiry?.picked_user?.thp_users_profile?.[0]?.commission || 0;
    const profit = getProfitAmount(inquiry);

    const commissionAmount = (profit / 100) * commissionPercentage;

    const transactions: any = [
      // {
      //   brd_id: inquiry?.brd_id,
      //   def_acc_id: 11,
      //   inq_id: inquiry?.id,
      //   user_id: inquiry?.picked_by,
      //   transactions_no: tenDigitCode2,
      //   date: moment(new Date()).format("YYYY-MM-DD"),
      //   type: "debit",
      //   amount: commissionAmount,
      // },
      // {
      //   brd_id: inquiry?.brd_id,
      //   def_acc_id: 2,
      //   inq_id: inquiry?.id,
      //   user_id: inquiry?.picked_by,
      //   transactions_no: tenDigitCode2,
      //   date: moment(new Date()).format("YYYY-MM-DD"),
      //   type: "credit",
      //   amount: -commissionAmount,
      // },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 4,
        inq_id: inquiry?.id,
        user_id: inquiry?.users.id,
        transactions_no: tenDigitCode,
        date: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: paidAmount,
        created_at: moment(date).format("YYYY-MM-DD"),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 5,
        inq_id: inquiry?.id,
        transactions_no: tenDigitCode,
        date: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -paidAmount,
        created_at: moment(date).format("YYYY-MM-DD"),
      },
    ];
    try {
      const res = await addTransaction({
        variables: {
          transactions: transactions,
        },
      });
      toast.success("Transaction added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.resetStore();

      const response = await completedInq({
        variables: {
          id: inquiry.id,
          status: "completed",
          completedAt: moment(date).format("YYYY-MM-DD"),
        },
      });

      if (response?.data?.update_inq_list?.returning?.length > 0) {
        const emailVariables: any = {
          inquiryNo: inquiry?.inquiry_no,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          brandName: inquiry.brd_list?.name,
          linkToPortal: `${inquiry.brd_list?.domain}`,
          fileLink: URLs.SAMPLE_PDF,
          fileName: `TICKET-${inquiry?.inquiry_no}`,
          consultantName: inquiry.picked_user?.displayName,
        };

        // await emailSend(
        //   10,
        //   inquiry.brd_id,
        //   inquiry.users?.id,
        //   inquiry.users?.email,
        //   inquiry.users?.phoneNumber,
        //   emailVariables
        // );

        toast.success("Inqiury Status Update Success", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();

        await alert.newNotification(
          inquiry.users?.id,
          `Congratulations! Your ticket is uploaded by your consultant, please check your portal`,
          "",
          ``
        );
      } else {
        toast.error("Something went wrong");
      }
    } catch (e: any) {
      toast.error("Transaction", e?.message);
    }
  };

  return (
    <>
      <button
        onClick={() => setModelOpen(!modalOpen)}
        className="block border border-basic rounded-full px-3 py-1 text-basic cursor-pointer hover:bg-basic hover:text-white"
      >
        Complete
      </button>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  defaultValue={dayjs(new Date())}
                  onChange={(date: any) => {
                    setDate(date?.$d);
                  }}
                  sx={{ width: "100%" }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <div className="flex justify-between items-center mt-6">
              <h2 className="text-2xl py-2 font-bold text-gray-700">
                Are you sure?
              </h2>
              <div className="flex gap-5">
                <button
                  onClick={(event: any) => setModelOpen(false)}
                  className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
                >
                  No
                </button>
                <button
                  onClick={(event: any) => closeInquiry()}
                  className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
