// import InputField from "components/FormInputs/InputField";
import React from "react";
import InputField from "components/FormInputs/InputField";
import { CalendarBox } from "components/FormInputs/CalendarBox";

export default function FlightDates({
  errors,
  setValue,
  control,
  register,
}: any) {
  return (
    <>
      <div className="flex flex-row gap-4">
        {/* Flight Fare Expiry */}
        <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="fareExpiry"
          label="Fare Expiry"
          required={true}
        />
        {/* Flight Invoice Due Date */}
        <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="invoiceDueDate"
          label="Invoice Due Date"
          required={true}
        />
      </div>
      <div className="flex flex-row gap-4">
        <div>
          <label className="text-[14px]">PNR</label>
          <input
            type="text"
            {...register("pnr")}
            className="h-[35px]  min-w-[300px] w-full rounded p-2 border-gray-300 border focus:ring-0 focus:outline-0 focus:border-gray-300"
          />
        </div>
        <div>
          <label className="text-[14px]">Booking Fee</label>
          <input
            type="text"
            {...register("bookingFee")}
            className="h-[35px]  min-w-[300px] w-full rounded p-2 border-gray-300 border focus:ring-0 focus:outline-0 focus:border-gray-300"
          />
        </div>
      </div>
    </>
  );
}
