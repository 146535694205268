import {
  Autocomplete,
  Box,
  Button,
  FormLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import countries from "../../../../../../components/FormInputs/CountryBox/data/countries.json";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  INSERT_CONTACT_DETAILS,
  UPDDATE_CONTACT_DETAILS,
} from "./graphql/Mutation";
import { toast } from "react-toastify";

const BrandGeneralInfo = ({ brand }: any) => {
  const contact = brand?.brd_contact_details?.at(0);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const def_office_open = dayjs(
      `2022-04-17T${contact?.office_open?.split(":")?.slice(0, 2).join(":")}`
    );
    setValue("office_open", def_office_open);
    const def_office_close = dayjs(
      `2022-04-17T${contact?.office_close?.split(":")?.slice(0, 2).join(":")}`
    );
    setValue("office_close", def_office_close);
  }, [contact]);

  const [updateContact] = useMutation(UPDDATE_CONTACT_DETAILS);
  const [insertContact] = useMutation(INSERT_CONTACT_DETAILS);
  const client = useApolloClient();

  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const onSubmit = async (inpData: any) => {
    const officeOpenString = inpData?.office_open;
    const officeOpenObject = new Date(officeOpenString);
    const officeOpenTime = officeOpenObject.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const officeCloseString = inpData?.office_close;
    const officeCloseObject = new Date(officeCloseString);
    const officeCloseTime = officeCloseObject.toLocaleTimeString("en-US", {
      hour12: false,
    });

    if (contact?.id) {
      const res = await updateContact({
        variables: {
          id: { id: contact.id },
          data: {
            country: inpData?.country?.label,
            city: inpData?.city,
            address1: inpData?.address1,
            phone: inpData?.phone,
            whatsapp: inpData?.whatsapp,
            email: inpData?.email,
            phone_without_country_code: inpData?.phone_without_country_code,
            office_open: officeOpenTime ? officeOpenTime : null,
            office_close: officeCloseTime ? officeCloseTime : null,
            coordinates: inpData?.coordinates,
          },
        },
      });

      if (res?.data?.update_brd_contact_detail_by_pk?.id) {
        toast.success("Contact updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      }
    } else {
      const res = await insertContact({
        variables: {
          data: {
            country: inpData?.country?.label,
            city: inpData?.city,
            address1: inpData?.address1,
            phone: inpData?.phone,
            whatsapp: inpData?.whatsapp,
            email: inpData?.email,
            phone_without_country_code: inpData?.phone_without_country_code,
            office_open: officeOpenTime ? officeOpenTime : null,
            office_close: officeCloseTime ? officeCloseTime : null,
            coordinates: inpData?.coordinates,
            brd_id: brand.id,
          },
        },
      });

      if (res?.data?.insert_brd_contact_detail_one?.id) {
        toast.success("Contact updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.resetStore();
      }
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Brand Base Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <TextField
              defaultValue={brand?.name}
              error={errors["brand_name"] ? true : false}
              {...register("brand_name", { required: true })}
              label="Brand Name"
              type="text"
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              {...register("country", { required: true })}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.label.toLowerCase().startsWith(inputValueLowerCase)
                    );
                  }}
                  options={countries ? countries : []}
                  getOptionLabel={(option: any) => option.label}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = countries.find(
                      (option) => option.label === contact?.country
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Country"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      error={errors["country"] ? true : false}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              defaultValue={contact?.city}
              {...register("city")}
              label="City"
              type="text"
            />
            <TextField
              defaultValue={contact?.address1}
              {...register("address1")}
              label="Address"
              type="text"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("office_open")}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <TimePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date) => {
                        onChange(date?.$d);
                      }}
                      sx={{ width: "100%" }}
                      label="Office Open Time"
                      defaultValue={dayjs(
                        `2022-04-17T${contact?.office_open
                          ?.split(":")
                          ?.slice(0, 2)
                          .join(":")}`
                      )}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  {...register("office_close")}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <TimePicker
                      value={value}
                      inputRef={ref}
                      onChange={(date) => {
                        onChange(date?.$d);
                      }}
                      sx={{ width: "100%" }}
                      label="Office Close Time"
                      defaultValue={dayjs(
                        `2022-04-17T${contact?.office_close
                          ?.split(":")
                          ?.slice(0, 2)
                          .join(":")}`
                      )}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              defaultValue={contact?.coordinates}
              error={errors["coordinates"] ? true : false}
              {...register("coordinates")}
              label="Coordinates (lat, lng)"
              type="email"
            />
            <TextField
              defaultValue={contact?.email}
              error={errors["email"] ? true : false}
              {...register("email", { required: true })}
              label="Email"
              type="text"
            />
            <div>
              <FormLabel>Phone</FormLabel>
              <Controller
                control={control}
                {...register("phone")}
                render={({ field, fieldState }: any) => (
                  <PhoneInput
                    {...field}
                    defaultValue={contact?.phone}
                    inputProps={{
                      className:
                        "w-full border border-gray-400/70 hover:border-[#3A3541DE] h-[57px] pl-[50px] px-3 py-2 rounded outline-none hover:border hover:border-1 focus:border-2 focus:border-basic",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    dropdownStyle={
                      darkMode
                        ? {
                            backgroundColor: "#312D4B",
                            borderRadius: "3px",
                            color: "#9155FD",
                          }
                        : {
                            backgroundColor: "#F4F5FA",
                            borderRadius: "3px",
                            color: "#3A3541DE",
                          }
                    }
                    country={"us"} // Set your desired default country
                    enableAreaCodes={true}
                    value={contact?.phone} // Show countries dropdown
                  />
                )}
              />
            </div>
            <div>
              <FormLabel>Whatsapp</FormLabel>
              <Controller
                control={control}
                {...register("whatsapp")}
                render={({ field }: any) => (
                  <PhoneInput
                    {...field}
                    defaultValue={contact?.phone}
                    inputProps={{
                      className:
                        "w-full border border-gray-400/70 hover:border-[#3A3541DE] h-[57px] pl-[50px] px-3 py-2 rounded outline-none hover:border hover:border-1 focus:border-2 focus:border-basic",
                    }}
                    buttonStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    dropdownStyle={
                      darkMode
                        ? {
                            backgroundColor: "#312D4B",
                            borderRadius: "3px",
                            color: "#9155FD",
                          }
                        : {
                            backgroundColor: "#F4F5FA",
                            borderRadius: "3px",
                            color: "#3A3541DE",
                          }
                    }
                    country={"us"} // Set your desired default country
                    enableAreaCodes={true}
                    value={contact?.phone} // Show countries dropdown
                  />
                )}
              />
            </div>
            <TextField
              sx={{ width: "100%" }}
              defaultValue={contact?.phone_without_country_code}
              {...register("phone_without_country_code")}
              label="Phone Without Country Code"
            />
          </div>
          <Button variant="contained" type="submit" sx={{ marginTop: "20px" }}>
            Save Brand Settings
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandGeneralInfo;
