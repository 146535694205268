import React, { useState } from "react";
import { toast } from "react-toastify";
// Import Utilz function
import { parseFlightData } from "../../utils/parseFlightData";
import { getDate } from "../../utils/formatData";
import { Button } from "@mui/material";

export default function GDSStatement({
  setDefaultValue,
  setShowForm,
  arrival,
}: any) {
  const [encoded, setEncoded] = useState<any>("");

  // Generate Stop
  const generateStop = (stop: any) => {
    // Stop Obj
    const StopObj = {
      airline: stop.airline,
      airlineNumber: stop.airline_no,
      departCity: stop.departure,
      departDateTime: getDate(stop.departureTime),
      arrivalCity: stop.arrival,
      arrivalDateTime: getDate(stop.arrivalTime),
    };
    return StopObj;
  };

  // Submit Proccess function
  const submitProccess = () => {
    try {
      // Decode GDS Statement
      const decodedStatement = parseFlightData(encoded, arrival);
      // Make Object for form default values
      const formDefaultValue = {
        tripType: decodedStatement.return.length > 0 ? 2 : 1,
        cabinType: "economy",
        departLeg: {
          landCary: "",
          luggage: "",
          stops: decodedStatement.depart.map((stop: any) => {
            return generateStop(stop);
          }),
        },
        returnLeg: {
          landCary: "",
          luggage: "",
          stops: decodedStatement.return.map((stop: any) => {
            return generateStop(stop);
          }),
        },
        offerPrice: "",
        costPrice: "",
      };
      // Update form default Value
      setDefaultValue({ ...formDefaultValue });
      // Show form
      setShowForm(true);
    } catch (e) {
      toast.error("Invalid Statement");
      console.error("error", e);
    }
  };

  // Add Manual Action
  const addManual = () => {
    setShowForm(true);
  };
  return (
    <div className="flex flex-col mt-5">
      {/* text area */}
      <label htmlFor="parse_gds">GDS Statement</label>
      <textarea
        id="parse_gds"
        className="w-[100%] p-2 h-[130px] border-2 border-primary focus:border-primary focus:ring-1 focus:outline-none rounded-lg"
        onChange={(e: any) => {
          setEncoded(e.target.value);
        }}
      >
        {encoded}
      </textarea>
      {/* Buttons */}
      <div className="w-[50%] flex gap-2 mt-5">
        <Button
          variant="contained"
          onClick={() => {
            submitProccess();
          }}
        >
          Procces Statement
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            addManual();
          }}
        >
          Add Manualy
        </Button>
      </div>
    </div>
  );
}
