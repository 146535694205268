import React from "react";
// Import Components
import LocationBox from "components/FormInputs/LocationBox";
import { DateTimeBox } from "components/FormInputs/DateTimeBox";

export default function SingleFlight({
  direction,
  control,
  setValue,
  register,
  errors,
  leg,
  index,
  getValues,
}: any) {
  return (
    <div className="flex gap-2">
      {/* Select Airport or city */}
      <LocationBox
        control={control}
        errors={errors}
        setValue={setValue}
        fieldName={`${leg}.stops.${index}.${direction}City`}
        label={direction}
        labelClasses="capitalize"
        placeholder="Choose your location"
        defaultValue={getValues(`${leg}.stops.${index}.${direction}City`) || ""}
        required={true}
      />
      {/* Select DateTime */}
      <DateTimeBox
        control={control}
        setValue={setValue}
        errors={errors}
        defaultValue={
          getValues(`${leg}.stops.${index}.${direction}DateTime`) || ""
        }
        fieldName={`${leg}.stops.${index}.${direction}DateTime`}
        label="Date and Time"
        inputClasses={"bg-white rounded-lg py-2 mt-[2px]"}
      />
    </div>
  );
}
