import Subscriptions from "./components/Subscriptions";

const ProfileAccComp = () => {
  return (
    <div>
      <Subscriptions />
    </div>
  );
};

export default ProfileAccComp;
