import React from "react";

export default function DistinationLine() {
  return (
    <svg
      width="100%"
      height="2"
      viewBox="0 0 719 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H718.5"
        stroke="#E5352E"
        stroke-opacity="0.5"
        stroke-dasharray="2 2"
      />
    </svg>
  );
}
