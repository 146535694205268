import React, { useState } from "react";
// Import Components
import StopDetail from "./components/StopDetail";
import WeightDetail from "./components/WeightDetail";
// Import Intial Data
import { stopDefaultValue } from "../../../../../../../../../../../../data/InitalDefaultValue";

export default function FlightLeg({
  leg,
  register,
  errors,
  control,
  setValue,
  unregister,
  getValues,
}: any) {
  // Define local state for stops
  const [stops, setStops] = useState<any>([...getValues(`${leg}.stops`)]);

  return (
    <div className="bg-gray-100 rounded-lg p-2">
      <div className="flex flex-row border-b-2 border-gray-300 w-full">
        {/* Section Heading */}
        <h1 className="flex-1 w-full font-bold text-left">
          {leg === "departLeg" ? "DEPARTURE" : "RETURN"}
        </h1>

        {/* Add More Stop button */}
        <button
          type="button"
          className=" bg-primary  m-[2px] rounded-sm  p-[2px] px-[6px]  text-white "
          onClick={() => {
            const formStops = [...stops, stopDefaultValue];
            setValue(`${leg}.stops`, [...formStops]);
            setStops([...formStops]);
          }}
        >
          Add Stop
        </button>
      </div>
      {/* Loop all stops */}
      {stops.map((stop: any, index: any) => (
        <StopDetail
          key={index}
          setStops={setStops}
          stops={stops}
          index={index}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          leg={leg}
          unregister={unregister}
          getValues={getValues}
        />
      ))}

      <hr className="border-gray-300  border-dashed my-4" />
      {/* Get Weight Detail Inputs */}
      <WeightDetail leg={leg} errors={errors} register={register} />
    </div>
  );
}
