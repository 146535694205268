import { useApolloClient, useQuery } from "@apollo/client";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { GET_ACC_TRANS_WITH_INQ_NO } from "~/graphql/acc_transactions/Query";
import { GET_ALL_BRANDS_CONS } from "~/graphql/users/Query";

const LeaderBoard = ({ brd_ids, startDateTime, endDateTime }: any) => {
  const client = useApolloClient();

  const { data } = useQuery(GET_ALL_BRANDS_CONS, {
    variables: {
      brd_ids,
    },
  });

  const [canProfitArr, setCanProfitArr] = useState<any>([]);

  const cancellationProfit = (inq: Array<{ id: string }>, userId: string) => {
    const inqIds = inq
      .filter((item: any) => {
        const completedDate = moment(item.completed_date).toISOString();
        return (
          item.status === "refunded" &&
          completedDate >= startDateTime &&
          completedDate <= endDateTime
        );
      })
      .map((item) => item.id);

    client
      .query({
        query: GET_ACC_TRANS_WITH_INQ_NO,
        variables: {
          inq_id: inqIds,
        },
      })
      .then((res: any) => {
        if (!res?.data?.acc_transactions) {
          console.error("No transactions found");
          return;
        }

        const creditAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "credit"
            )
            .reduce((sum, trans) => sum - trans.amount, 0);
        };

        const debitAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "debit"
            )
            .reduce((sum, trans) => sum + trans.amount, 0);
        };

        const typesToSum = ["income", "expense"];

        const credits = creditAmount(res.data.acc_transactions, typesToSum);
        const debits = debitAmount(res.data.acc_transactions, typesToSum);

        const amount = credits - debits;

        setCanProfitArr(
          (prevArr: Array<{ userId: string; amount: number }>) => [
            ...prevArr,
            { userId: userId, amount: amount },
          ]
        );
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });

    return;
  };

  const [comProfitArr, setComProfitArr] = useState<any>([]);

  const completedProfit = (inq: Array<{ id: string }>, userId: string) => {
    const inqIds = inq
      .filter((item: any) => {
        const completedDate = moment(item.completed_date).toISOString();
        return (
          item.status === "completed" &&
          completedDate >= startDateTime &&
          completedDate <= endDateTime
        );
      })
      .map((item) => item.id);

    client
      .query({
        query: GET_ACC_TRANS_WITH_INQ_NO,
        variables: {
          inq_id: inqIds,
        },
      })
      .then((res: any) => {
        if (!res?.data?.acc_transactions) {
          console.error("No transactions found");
          return;
        }

        const creditAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "credit"
            )
            .reduce((sum, trans) => sum - trans.amount, 0);
        };

        const debitAmount = (transactions: Array<any>, types: string[]) => {
          return transactions
            .filter(
              (trans) =>
                types.includes(trans.def_acc_list.type) &&
                trans.type === "debit"
            )
            .reduce((sum, trans) => sum + trans.amount, 0);
        };

        const typesToSum = ["income", "expense"];

        const credits = creditAmount(res.data.acc_transactions, typesToSum);
        const debits = debitAmount(res.data.acc_transactions, typesToSum);

        const amount = credits - debits;

        setComProfitArr(
          (prevArr: Array<{ userId: string; amount: number }>) => [
            ...prevArr,
            { userId: userId, amount: amount },
          ]
        );
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });

    return;
  };

  useEffect(() => {
    if (data?.users) {
      data.users.forEach((user: any) => {
        completedProfit(user?.picked_inqs, user?.id);

        cancellationProfit(user?.picked_inqs, user?.id);
      });
    }
  }, [data, brd_ids, startDateTime, endDateTime]);

  const dueBalance = (inq: any) => {
    const filteredInqs = inq?.filter(
      (inq: any) =>
        inq.created_at >= startDateTime &&
        inq.created_at <= endDateTime &&
        inq?.inq_tickets?.some((ticket: any) => ticket?.status === "issued")
    );

    const sumOfDueBalances = filteredInqs?.reduce(
      (totalSum: number, inq: any) => {
        const totalInvoiceAmount =
          inq?.inq_invoice?.inq_invoice_items?.reduce(
            (total: number, invoice: any) => total + invoice.amount,
            0
          ) + inq?.selected_suggestion?.bookingFee;

        const totalPaidAmount = inq?.inq_transections
          ?.filter((transaction: any) =>
            ["paid", "partiallyRefunded"].includes(transaction.status)
          )
          ?.map((transaction: any) =>
            transaction.status === "partiallyRefunded"
              ? transaction.amount - (transaction.refund_amount || 0)
              : transaction.amount
          )
          ?.reduce((sum: number, amount: number) => sum + amount, 0);

        const dueBalance = totalInvoiceAmount - totalPaidAmount;

        return totalSum + dueBalance;
      },
      0
    );

    return sumOfDueBalances || 0;
  };

  const rows = data?.users
    ? [
        ...data?.users?.map((user: any, index: any) => ({
          id: index + 1,
          name: user?.thp_users_profile?.[0]?.pseudo_name,
          totalPending:
            user?.picked_inqs?.filter(
              (inq: any) =>
                inq?.status === "inprocess" &&
                inq.created_at >= startDateTime &&
                inq.created_at <= endDateTime &&
                inq?.inq_transections?.some(
                  (transaction: any) => transaction?.status === "paid"
                )
            )?.length || 0,
          inquiries: user?.picked_inqs.filter(
            (inq: any) =>
              inq.created_at >= startDateTime && inq.created_at <= endDateTime
          )?.length,
          todayBookings:
            user?.picked_inqs?.filter(
              (inq: any) =>
                inq?.status === "inprocess" &&
                inq?.inq_transections?.some(
                  (transaction: any) =>
                    moment(transaction?.updated_at).format("YYYY-MM-DD") ===
                      moment(new Date()).format("YYYY-MM-DD") &&
                    (transaction?.status === "paid" ||
                      transaction?.status === "partiallyRefunded")
                )
            )?.length || 0,
          totalBookings:
            user?.picked_inqs?.filter(
              (inq: any) =>
                inq.created_at >= startDateTime &&
                inq.created_at <= endDateTime &&
                inq?.inq_transections?.some(
                  (transaction: any) => transaction?.status === "paid"
                )
            )?.length || 0,
          completeBookings:
            user?.picked_inqs?.filter(
              (inq: any) =>
                inq?.status === "completed" &&
                inq.completed_date >= startDateTime &&
                inq.completed_date <= endDateTime
            )?.length || 0,
          issuedBookings:
            user?.picked_inqs?.filter(
              (inq: any) =>
                inq.created_at >= startDateTime &&
                inq.created_at <= endDateTime &&
                inq?.inq_tickets?.some(
                  (ticket: any) => ticket?.status === "issued"
                )
            )?.length || 0,
          dueBalance: dueBalance(user?.picked_inqs),

          completedProfit: comProfitArr
            .find((item: any) => item.userId === user.id)
            ?.amount?.toFixed(2),
          cancellationProfit: canProfitArr
            .find((item: any) => item.userId === user.id)
            ?.amount?.toFixed(2),
          totalProfit: (
            comProfitArr.find((item: any) => item.userId === user.id)?.amount +
            canProfitArr.find((item: any) => item.userId === user.id)?.amount
          ).toFixed(2),
        })),
      ]
        ?.sort((a, b) => b.totalProfit - a.totalProfit)
        ?.map((row, index) => ({
          ...row,
          id: index + 1, // Assign ID based on the sorted index
        }))
    : [];

  // Calculate sums for each column
  const sumColumns = {
    totalPending: rows.reduce((sum, row) => sum + row.totalPending, 0),
    inquiries: rows.reduce((sum, row) => sum + row.inquiries, 0),
    todayBookings: rows.reduce((sum, row) => sum + row.todayBookings, 0),
    totalBookings: rows.reduce((sum, row) => sum + row.totalBookings, 0),
    completeBookings: rows.reduce((sum, row) => sum + row.completeBookings, 0),
    issuedBookings: rows.reduce((sum, row) => sum + row.issuedBookings, 0),
    dueBalance: rows.reduce((sum, row) => sum + row.dueBalance, 0),
    completedProfit: rows.reduce(
      (sum, row) => sum + parseFloat(row.completedProfit),
      0
    ),
    cancellationProfit: rows.reduce(
      (sum, row) => sum + parseFloat(row.cancellationProfit),
      0
    ),
    totalProfit: rows.reduce(
      (sum, row) => sum + parseFloat(row.totalProfit),
      0
    ),
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Rank", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "totalPending",
      headerName: `Total Pending`,
      width: 110,
    },
    {
      field: "inquiries",
      headerName: `Inquiries`,
      width: 100,
    },
    {
      field: "todayBookings",
      headerName: `Today Bookings`,
      width: 120,
    },
    {
      field: "totalBookings",
      headerName: `Total Bookings`,
      width: 130,
    },
    {
      field: "completeBookings",
      headerName: `Completed Bookings`,
      width: 150,
    },
    {
      field: "issuedBookings",
      headerName: `Issued Bookings`,
      width: 120,
    },
    {
      field: "dueBalance",
      headerName: `Due Balance`,
      width: 100,
    },
    {
      field: "completedProfit",
      headerName: `Completed Profit`,
      width: 130,
    },
    {
      field: "cancellationProfit",
      headerName: `Cancellation Profit`,
      width: 130,
    },
    {
      field: "totalProfit",
      headerName: `Total Profit`,
      width: 120,
    },
  ];

  const CustomFooter = () => <GridFooterContainer></GridFooterContainer>;

  return (
    <>
      <div className="pt-4 w-full border-b-[1px] border-[#ccc] flex justify-between">
        <h2>Leader Board</h2>
      </div>
      <div className="overflow-x-scroll flex p-5 gap-5 mx-5">
        <p style={{ whiteSpace: "nowrap" }}>
          Total Pending: {sumColumns.totalPending}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Inquiries: {sumColumns.inquiries}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Today Bookings: {sumColumns.todayBookings}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Total Bookings: {sumColumns.totalBookings}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Completed Bookings: {sumColumns.completeBookings}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Issued Bookings: {sumColumns.issuedBookings}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Due Balance: {sumColumns.dueBalance}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Completed Profit: {sumColumns.completedProfit.toFixed(2)}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Cancellation Profit: {sumColumns.cancellationProfit.toFixed(2)}
        </p>{" "}
        |
        <p style={{ whiteSpace: "nowrap" }}>
          Total Profit: {sumColumns.totalProfit.toFixed(2)}
        </p>
      </div>
      <div className="m-4" style={{ height: 600 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[10, 20, 30]}
        />
      </div>
    </>
  );
};

export default LeaderBoard;
