import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "~/components/FormInputs/InputField";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_FLIGHT_PAGE_CONTENT } from "./graphql/Mutation";
import { toast } from "react-toastify";
import ContentEditor from "~/components/common/ContentEditor";
import { Button, TextField } from "@mui/material";

const AirlinePageContent = ({ page }: any) => {
  const [content, setContent] = useState(page?.content || "");

  const [updatePageContent] = useMutation(UPDATE_FLIGHT_PAGE_CONTENT);
  const client = useApolloClient();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    const res = await updatePageContent({
      variables: {
        id: { id: page?.id },
        data: { content: content, content_heading: inpData.content_heading },
      },
    });

    if (res?.data?.update_brd_airlines_pages_by_pk?.id) {
      toast.success("Content updated successfully");
      await client.resetStore();
    } else {
      toast.error("Someting went wrong");
    }
  };

  useEffect(() => {
    setValue("content_heading", page?.content_heading);
  }, []);

  return (
    <>
      <div className="p-4 mb-4 bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-lg shadow 2xl:col-span-2  sm:p-6 ">
        <h3 className="mb-4 text-xl font-semibold ">Page Content</h3>
        <form onSubmit={handleSubmit(onSubmit)} action="#">
          <div className="grid grid-cols-6 gap-6 mb-4">
            <div className="col-span-6 sm:col-full ck-editor">
              <TextField
                defaultValue={page?.content_heading}
                error={errors["content_heading"] ? true : false}
                {...register("content_heading", { required: true })}
                label="Short Content"
                type="text"
                fullWidth
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-full ck-editor">
              <label htmlFor="">Detail Content</label>
              <ContentEditor content={content} setContent={setContent} />
            </div>
            <div className="col-span-6 sm:col-full">
              <Button type="submit" variant="contained">
                Save Content
              </Button>
              {/* <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"></button> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AirlinePageContent;
