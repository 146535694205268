import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import GDSStatement from "./components/GDSStatement";
import { initialDefaultValue } from "../../../../../../../../data/InitalDefaultValue";
import SuggestionForm from "./components/SuggestionForm";

export default function AddSuggestion({
  setShowAddModal,
  inquiry_id,
  inquiry,
  user_id,
  user_phone,
  user_email,
  brand_url,
  brand_name,
  arrival,
  brand_domain,
  passengers,
}: any) {
  const [defaultValue, setDefaultValue] = useState<any>(initialDefaultValue);
  const [showForm, setShowForm] = useState<any>(false);

  return (
    <div className="fixed left-0 top-0 w-full h-screen overflow-y-scroll flex justify-end items-start z-50 bg-[#00000070]">
      <div className="modal-container min-h-screen relative bg-white rounded shadow-lg p-6 w-full overflow-y-auto py-4 max-w-[1024px] z-[60] justify-center items-start text-black-100">
        <div>
          <h2 className="text-xl font-bold mb-4 text-black text-center w-full">
            Create Suggestion
          </h2>
          <hr className="border-gray-300  border-dashed my-4" />
          <span
            onClick={() => setShowAddModal(false)}
            className="absolute right-[10px] top-[5px] cursor-pointer "
          >
            <FaTimes />
          </span>
        </div>
        {!showForm && (
          <div>
            <GDSStatement
              setShowForm={setShowForm}
              arrival={arrival}
              setDefaultValue={setDefaultValue}
            />
          </div>
        )}
        {showForm && (
          <SuggestionForm
            defaultValue={defaultValue}
            inquiry_id={inquiry_id}
            inquiry={inquiry}
            user_phone={user_phone}
            user_email={user_email}
            brand_domain={brand_domain}
            brand_url={brand_url}
            brand_name={brand_name}
            passengers={passengers}
            user_id={user_id}
            setShowAddModal={setShowAddModal}
          />
        )}
      </div>
    </div>
  );
}
