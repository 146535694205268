import moment from "moment";
import React, { useState } from "react";
import SaveFloppy from "./assets/SaveFloppy";
import { DELETE_TICKET, EDIT_TICKET_REQUEST } from "./graphql/Mutation";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import EditTicket from "./components/EditTicket";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { URLs } from "~/config/enums";
import { getPaidAmount } from "~/modules/InquiriesModule/utils/getInquiryStats";
import { GET_VENDORS } from "../TicketRequestForm/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import DeleteIcon from "@mui/icons-material/Delete";

export default function TicketRequestCard({ inquiry, hideActions }: any) {
  const [status, setStatus] = useState(inquiry?.status);
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [editRequest] = useMutation(EDIT_TICKET_REQUEST);
  const client = useApolloClient();

  const [deleteTicket] = useMutation(DELETE_TICKET);

  const { travelHouse }: any = travelHouseStore();

  const { loading, data, error } = useQuery(GET_VENDORS, {
    variables: { th_id: travelHouse.id },
  });

  const updateIssuanceStatus = async () => {
    setIsLoading(true);
    const payload = {
      id: inquiry.id,
      status: status,
    };
    try {
      const res = await editRequest({ variables: payload });
      if (res.data?.update_ticket_issuance_request?.returning?.length > 0) {
        toast.success("Request Status Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        await client.resetStore();
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(`${e}`);
    }
  };

  const userRole = useUserDefaultRole();
  const user_id = useUserId();

  return (
    <div className="self-stretch bg-white-100 w-full flex flex-col p-[25px] items-start justify-center text-left text-mini text-darkslategray border-[1px] border-solid border-lightsteelblue">
      <div className="@container w-full">
        <form className="self-stretch flex flex-col gap-[43px] w-full">
          {userRole !== "user" && (
            <>
              <div className="relative flex items-center justify-between">
                {!hideForm && (
                  <div className="flex items-center gap-1">
                    <span>{`Fair Expiry:`}</span>
                    <b>
                      {moment(inquiry?.selected_suggestion?.fare_expiry).format(
                        "DD MM, YYYY"
                      )}
                    </b>
                  </div>
                )}

                {(user_id === inquiry?.picked_by || userRole === "admin") &&
                  inquiry?.status !== "completed" &&
                  !hideActions && (
                    <div className="flex items-center gap-[8px]">
                      <button
                        type="button"
                        className="dark:text-dark-primary text-primary cursor-pointer"
                        onClick={() => {
                          setHideForm(!hideForm);
                        }}
                      >
                        {!hideForm ? "Edit" : "Close"}
                      </button>
                      <DeleteIcon
                        onClick={async () => {
                          const res = await deleteTicket({
                            variables: {
                              id: inquiry?.inq_tickets?.[0]?.id,
                            },
                          });

                          if (
                            res?.data?.delete_inq_ticket?.returning?.[0]?.id
                          ) {
                            toast.success("Ticket Deleted Successfully");
                          }
                        }}
                        sx={{ color: "red", cursor: "pointer" }}
                      />
                    </div>
                  )}
              </div>
              {!hideForm && (
                <div className="flex flex-col gap-3">
                  <div className="relative flex items-center gap-1">
                    <span>{`Cancelation Charges:`}</span>

                    <b>{inquiry?.inq_tickets?.[0]?.cancelation_charges}</b>
                  </div>
                  <div className="relative flex items-center gap-1">
                    <span>{`PNR:`}</span>

                    <b>{inquiry?.inq_tickets?.[0]?.pnr}</b>
                  </div>
                  <div className="relative flex items-center gap-1">
                    <span>{`IBE:`}</span>
                    <br />
                    <b>{inquiry?.inq_tickets?.[0]?.ibe}</b>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="flex flex-col justify-between gap-2 w-full">
            {!hideForm &&
              inquiry?.inq_tickets?.[0]?.inq_ticket_files?.map((item: any) => {
                return (
                  <>
                    {item?.file_url && (
                      <div className="relative flex items-center gap-1">
                        <span>Ticket File:</span>
                        <br />
                        <a
                          className="font-bold"
                          target="_blank"
                          href={`${URLs.FILE_URL}${item?.file_url}`}
                          rel="noreferrer"
                        >
                          Download File For {item?.inq_passengers?.first_name}
                          {item?.inq_passengers?.last_name}
                        </a>
                      </div>
                    )}
                  </>
                );
              })}
            {!hideForm && (
              <div className="relative flex gap-1">
                <span>{`Status:`}</span>
                <br />
                <b className="capitalize">
                  {inquiry?.inq_tickets?.[0]?.status}
                </b>
              </div>
            )}
          </div>
        </form>
      </div>
      {hideForm && (
        <div className="flex items-center gap-2 mb-3 border-t-2 border-gray w-full">
          <EditTicket
            inquiry={inquiry}
            setHideForm={setHideForm}
            paidAmount={getPaidAmount(inquiry)}
            vendors={data.users}
            fareExpiry={inquiry.selected_suggestion?.fare_expiry || undefined}
          />
        </div>
      )}
    </div>
  );
}
