import { useUserDefaultRole } from "@nhost/react";
import React from "react";

export default function CostBreakDown({
  inq_suggestion_cost,
  setShowCostBreakdownModal,
}: any) {
  const userRole = useUserDefaultRole();

  return (
    <div className="fixed top-0 z-[5] left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 rounded-md">
        <div className="flex flex-col md:flex-row w-full justify-between mb-2	">
          <h1 className="text-gray-500 ">Cost Breakdown</h1>
          <button
            className="button btn bg-red-500 text-white  rounded-md p-2 py-[3px]"
            onClick={() => setShowCostBreakdownModal(false)}
          >
            Close
          </button>
        </div>
        <table className="min-w-full text-center divide-y divide-gray-200 ">
          <thead className="bg-gray-50 ">
            <tr>
              {" "}
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Sr
              </th>{" "}
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Name
              </th>{" "}
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Type
              </th>{" "}
              <th
                scope="col"
                className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
              >
                Sale
              </th>{" "}
              {userRole !== "user" && (
                <th
                  scope="col"
                  className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 "
                >
                  Cost + Tax
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {inq_suggestion_cost.map((costDetail: any, index: any) => (
              <tr>
                <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                  {index + 1}
                </td>
                <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                  {costDetail?.inq_passenger?.first_name}{" "}
                  {costDetail?.inq_passenger?.last_name}
                </td>

                <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                  {" "}
                  {costDetail?.inq_passenger?.type}
                </td>

                <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                  {costDetail?.sale_price}
                </td>

                {userRole !== "user" && (
                  <td className="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                    {costDetail.basic_fare || 0} + {costDetail.tax_price || 0} +
                    {costDetail?.atol_price} ={" "}
                    {costDetail.basic_fare +
                      costDetail.tax_price +
                      costDetail?.atol_price}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
