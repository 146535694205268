import DistinationLine from "../../assets/svg/DistinationLine";
import moment from "moment";
import TickBox from "../../assets/svg/TickBox";
import Money from "../../assets/svg/Money";
import AirPlane from "../../assets/svg/AirPlane";
import Edit from "../../assets/img/edit.png";
import { useState } from "react";
import InquiryCardHead from "./components/InquiryCardHead";
import PassengerRecord from "./components/PassengerRecord";
import InquiryCardEdit from "./components/InquiryCardEdit";
import { getCityName } from "~/utils/utilsFunctions";

const InquiryCard = ({ inquiry, header, height, editAble }: any) => {
  const [edit, setEdit] = useState(false);

  return (
    <div
      className={`flex-1 w-full rounded-8xs m-[15px] bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow min-h-[${height}] flex flex-col pt-0 px-0 pb-[15px] box-border items-end justify-between text-left text-xs
    `}
    >
      <InquiryCardHead inquiry={inquiry} show={header} />

      {editAble && (
        <img
          onClick={() => {
            setEdit(!edit);
          }}
          className="cursor-pointer w-[15px] h-[14.94px] m-2 absolute text-basic"
          alt=""
          src={Edit}
        />
      )}

      {!edit ? (
        <div className="mt-2 w-full text-primary dark:text-dark-primary">
          <div className="self-stretch flex flex-row py-0 px-[15px] mt-4 items-center justify-between">
            <div className="flex flex-col items-start justify-start gap-[1px]">
              <div className="self-stretch relative text-3xs ">From</div>
              <div className="self-stretch relative text-13xl font-light text-basic">
                {inquiry?.from}
              </div>
              <div className="self-stretch relative ">
                {getCityName(inquiry?.from)}
              </div>
              <div className="self-stretch relative">
                {" "}
                {moment(inquiry.departure_datetime).format("DD-MM-YYYY")}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_departure ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative">Flexible</div>
              </div>
            </div>
            <div className="w-full">
              <DistinationLine />
            </div>
            <div className="flex flex-col items-end justify-start text-right">
              <div className="self-stretch relative text-3xs ">To</div>
              <div className="relative text-13xl font-light text-basic">
                {" "}
                {inquiry.to}
              </div>
              <div className="self-stretch relative">
                {inquiry.trip_type === "oneWay" ? (
                  <span>-</span>
                ) : (
                  getCityName(inquiry?.to)
                )}
              </div>
              <div className="self-stretch relative">
                {inquiry.trip_type === "oneWay" ? (
                  <span>-</span>
                ) : (
                  moment(inquiry.arrival_datetime).format("DD-MM-YYYY")
                )}
              </div>
              <div className="flex flex-row items-center justify-center gap-[6px]">
                {inquiry.flexible_arrival ? (
                  <TickBox />
                ) : (
                  <div className="relative box-border w-[11px] h-[11px] border-[1px] border-solid border-darkgray"></div>
                )}
                <div className="relative">Flexible</div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col md:flex-row py-0 px-[15px] items-center justify-between">
            <div className="flex flex-col items-start justify-center gap-[20px] w-full">
              <div className="flex flex-row items-end justify-start gap-1">
                <div className="hidden md:inline-block">
                  <AirPlane />
                </div>
                <div className="relative inline-block shrink-0">
                  <span>{`Preferred Airline: `}</span>
                  <span className="font-bold">{inquiry.preferred_airline}</span>
                </div>
              </div>
              <div className="flex flex-row items-center justify-start gap-1">
                <Money />
                <div className="relative inline-block shrink-0">
                  <span>Approx Budget:</span>
                  <b>{inquiry?.approx_budget}</b>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center gap-[20px] w-full">
              <div className="flex flex-row items-start justify-start gap-1">
                <img
                  className="relative w-auto h-[13px]"
                  alt=""
                  src="/vector17.svg"
                />
                <div className="relative inline-block h-3 shrink-0">
                  <span>{`Direct Flight: `}</span>
                  <span className="">
                    {inquiry.direct_flight ? "Yes" : "No"}
                  </span>
                </div>
              </div>

              <PassengerRecord inq_passengers={inquiry?.inq_passengers} />
            </div>
          </div>
        </div>
      ) : (
        <InquiryCardEdit inquiry={inquiry} setEdit={setEdit} />
      )}
    </div>
  );
};

InquiryCard.defaultProps = {
  header: true,
  height: "300px",
  editAble: false,
};

export default InquiryCard;
