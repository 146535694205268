import { FormLabel, TextField } from "@mui/material";
import { useEffect } from "react";

const TipsSectionCard = ({
  ft,
  index,
  register,
  setValue,
  errors,
  cardType,
}: any) => {
  if (ft?.id) {
    setValue(`tips.${index}.id`, ft?.id);
  }

  return (
    <>
      <div className="bg-primary dark:bg-dark-primary rounded-lg shadow p-5">
        {cardType === "featuredCard" && (
          <div className="mb-5">
            <label className="mb-2">Icon</label>
            <TextField
              sx={{ width: "100%" }}
              {...register(`tips.${index}.icon`)}
              type="file"
              inputProps={{
                style: {
                  paddingTop: "7px",
                  paddingBottom: "27px",
                  paddingLeft: "23px",
                },
              }}
              variant="outlined"
              helperText="SVG, PNG, JPG or GIF (MAX. 400x400px)"
            />
          </div>
        )}
        <TextField
          sx={{ width: "100%" }}
          error={errors?.tips && errors?.tips[index]?.title ? true : false}
          {...register(`tips.${index}.title`, { required: true })}
          label="Title"
          type="text"
          defaultValue={ft?.heading ? ft?.heading : ft?.title && ft?.title}
        />
        <div className="mt-3">
          <FormLabel>Description</FormLabel>
          <textarea
            {...register(`tips.${index}.content`, { required: true })}
            className={`${
              errors?.tips && errors?.tips[index]?.content
                ? "border-red-600 bg-transparent rounded w-full focus:border-red-600 focus:ring-red-600 h-40"
                : "bg-transparent rounded w-full border-secondary dark:border-dark-secondary focus:border-basic focus:ring-basic h-40"
            }`}
            defaultValue={
              ft?.description ? ft?.description : ft?.content && ft?.content
            }
          />
        </div>
      </div>
    </>
  );
};

export default TipsSectionCard;
