import React, { useEffect, useState } from "react";
import {
  useApolloClient,
  useLazyQuery,
  useQuery,
  useSubscription,
} from "@apollo/client";
// import gql queries
import {
  GET_ACTIVE_MEMBERS_WITH_BRANDS,
  GET_ACTIVE_MEMBERS_WITH_THP,
} from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import UserAdd from "components/UserCard/components/UserAdd";
import SidePopup from "~/components/common/SidePopup";
import UsersGroup from "./components/UsersGroup";
import { useUserId } from "@nhost/react";
import useAssignedBrands from "~/hooks/useAssignedBrands";
import NoDataFound from "~/components/common/NoDataFound";
import CustomerTable from "../../../CustomerList/component/table";

export default function BrandWise() {
  const { travelHouse }: any = travelHouseStore();

  const client = useApolloClient();
  // const userId = useUserId();

  // const [data, setData] = useState<any>([]);
  const assignedBrands = useAssignedBrands();
  // get data based on query
  const { data, error, loading } = useSubscription(
    GET_ACTIVE_MEMBERS_WITH_BRANDS,
    {
      variables: { brands: assignedBrands, thpId: travelHouse?.id },
    }
  );

  // const fetchMembers = async () => {
  //   if (assignedBrands) {
  //     const dataRes = await client.query({
  //       query: GET_ACTIVE_MEMBERS_WITH_BRANDS,
  //       variables: { brands: assignedBrands },
  //     });
  //     setData(dataRes);
  //   } else {
  //     // dataRes = await getAllMembers({
  //     //   variables: { thp_id: travelHouse?.id },
  //     // });
  //     const dataRes = await client.query({
  //       query: GET_ACTIVE_MEMBERS_WITH_THP,
  //       variables: { thp_id: travelHouse?.id },
  //     });

  //     setData(dataRes);
  //   }
  // };

  // const { loading, data, error } = dataRes;
  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <>
      <CustomerTable data={data?.users} />
      {/* <UsersGroup users={data?.users} role="admin" /> */}
      {/* <UsersGroup users={data?.users} role="consultant" />
      <UsersGroup users={data?.users} role="accountant" />
      <UsersGroup users={data?.users} role="editor" /> */}
      <SidePopup
        text="Add Team Member"
        component={<UserAdd defaultRole={"team"} />}
      />
    </>
  );
}
