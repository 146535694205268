import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import { EDIT_USER } from "../../graphql/Mutation";
import { useMutation, useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useUserId } from "@nhost/react";
import InputField from "components/FormInputs/InputField";
import PhoneBox from "components/FormInputs/PhoneBox";
import AddressBox from "components/FormInputs/AddressBox";
import { GET_BRANDS } from "./graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { EDIT_USER, INSERT_PROFILE, UPDATE_PROFILE } from "./graphql/Mutation";
import BrandsAssign from "./components/BrandsAssign";
import { useUserDefaultRole } from "@nhost/react";
import { GET_ALIASES } from "../UserAdd/graphql/Query";
import { GET_TRAVEL_HOUSE_BY_ID } from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import CountryBox from "~/components/FormInputs/CountryBox/CountryBox";
import useGmailAliases from "~/hooks/useGmailAliases";

export default function UserEdit({ user, brands, setEdit }: any) {
  const profile = user?.thp_users_profile?.at(0);
  const userId = useUserId();
  const { travelHouse }: any = travelHouseStore();
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [insertProfile] = useMutation(INSERT_PROFILE);
  const userRole = useUserDefaultRole();
  const RESTRCITED_ROLES = ["vendor", "user"];
  const {
    loading: loadingBrands,
    error: brandsError,
    data: brd_list,
  } = useQuery(GET_BRANDS, {
    variables: { thp_id: travelHouse.id },
  });

  const [editUser] = useMutation(EDIT_USER);
  const client = useApolloClient();
  const [countryCode, setCountryCode] = useState(
    user?.metadata?.countryCode || ""
  );
  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();

  const country = watch("country");
  useEffect(() => {
    if (country?.code) {
      setCountryCode(country.code.toLowerCase());
    } else {
      setCountryCode("");
    }
  }, [country]);

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      // fetchPolicy: "network-only",
    }
  );

  const { data: getAliasData, loading: getAliasLoading } = useQuery(
    GET_ALIASES,
    {
      variables: {
        refresh_token: travelHouseData?.thp_list[0]?.email_refresh_token,
        th_id: travelHouse.id,
        page: "",
        nextPageToken: "",
        pageSize: "1",
        body: "",
        sendEmail: false,
        subject: "",
        to: "",
        getNewEmailsFlag: false,
        historyId: "",
        aliasDisplayName: "",
        aliasEmail: "",
        createAlias: false,
        getAlias: true,
        from: "",
        alias: false,
        replyFlag: false,
        messageId: "",
        query: false,
        queryData: "",
      },
    }
  );

  const { isLoading, aliases } = useGmailAliases();

  const teamRoles = [
    { name: "Consultant", value: "consultant" },
    { name: "Accountant", value: "accountant" },
    { name: "Admin", value: "admin" },
    { name: "Editor", value: "editor" },
  ];

  const statusOptions = [
    { name: "Active", value: "1" },
    { name: "Suspended", value: "0" },
  ];

  const roleFunc = () => {
    const data = teamRoles.find(
      (option: any) =>
        option.value.toLowerCase() === user?.defaultRole?.toLowerCase()
    );
    return data;
  };

  const defRole = roleFunc(); // Call the function to get the actual value
  const defRoleindex = defRole ? teamRoles.indexOf(defRole) : -1; // Check if data is not null/undefined
  const AliasFunc = () => {
    const data = aliases?.find(
      (option: any) =>
        option?.sendAsEmail?.toLowerCase() ===
        user?.metadata?.alias?.toLowerCase()
    );

    return data;
  };

  const defAlias = AliasFunc(); // Call the function to get the actual value
  const defAliasindex: any = defAlias ? aliases?.indexOf(defAlias) : -1; // Check if data is not null/undefined

  useEffect(() => {
    setAliasValue(defAliasindex !== -1 ? aliases[defAliasindex] : null);
    setRoleValue(defRoleindex !== -1 ? teamRoles[defRoleindex] : null);
    setValue("defaultRole", defRole?.value);
  }, [getAliasData, defRoleindex]);

  const [roleValue, setRoleValue] = useState<any>(null);
  const [aliasValue, setAliasValue] = useState<any>(null);
  const [statusValue, setStatusValue] = useState<any>(
    user?.disabled ? statusOptions[1] : statusOptions[0]
  );

  useEffect(() => {}, [roleValue]);

  const onSubmit = async (data: any) => {
    if (brands === true && data?.brands?.length <= 0) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    if (!brands) {
      toast.error("At least one brand is required for update!");
      return 0;
    }
    let brd_users: any = [];

    if (brands) {
      if (typeof data.brands === "string") {
        brd_users.push({
          thp_id: travelHouse?.id,
          brd_id: data.brands,
          user_id: user.id,
        });
      } else {
        data?.brands?.forEach((brd_id: any) => {
          brd_users.push({
            thp_id: travelHouse?.id,
            brd_id: brd_id,
            user_id: user.id,
          });
        });
      }
    } else {
      user.brd_users.forEach((brd: any) => {
        brd_users.push({
          thp_id: brd?.thp_id,
          brd_id: brd?.brd_id,
          user_id: brd?.user_id,
        });
      });
    }

    const userMetadata: any = user.metaData;
    const metaData = {
      ...userMetadata,
      addressLine1: data?.location?.addressLine1,
      addressLine2: data?.location?.addressLine2,
      countryCode: data?.country?.code,
      country: data?.country?.label,
      city: data?.location?.city,
      postalCode: data.location.postalCode,
      region: data.location.region,
      alias: data?.alias || "",
    };
    const payload = {
      id: user.id,
      newUser: {
        displayName: data.displayName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        defaultRole:
          user.defaultRole === "user" ? user.defaultRole : data.defaultRole,
        metadata: metaData,
        disabled: data.disabled === "1" ? false : true,
      },
      prevPhoneNumber: user?.phoneNumber,
      newUserBrands: brd_users,
    };

    try {
      const res = await editUser({ variables: payload });

      // await client.resetStore();
      if (res.data?.updateUsers?.affected_rows > 0) {
        if (RESTRCITED_ROLES.indexOf(user?.defaultRole) < 0) {
          if (profile?.id) {
            await updateProfile({
              variables: {
                id: { id: profile.id },
                data: {
                  basic_salary:
                    data?.basic_salary === "" ? null : data?.basic_salary,
                  commission: data?.commission === "" ? null : data?.commission,
                  bio: data?.bio,
                  twitter: data?.twitter,
                  pseudo_name: data?.pseudo_name,
                  facebook: data?.facebook,
                  linkedin: data?.linkedin,
                  discord: data?.discord,
                  x: data?.x,
                  skype: data?.skype,
                  youtube: data?.youtube,
                  instagram: data?.instagram,
                },
              },
            });
          } else {
            await insertProfile({
              variables: {
                data: {
                  user_id: user.id,
                  basic_salary: data?.basic_salary,
                  commission: data?.commission,
                  bio: data?.bio,
                  twitter: data?.twitter,
                  pseudo_name: data?.pseudo_name,
                  facebook: data?.facebook,
                  linkedin: data?.linkedin,
                  discord: data?.discord,
                  x: data?.x,
                  skype: data?.skype,
                  youtube: data?.youtube,
                  instagram: data?.instagram,
                },
              },
            });
          }
        }
        // await client.refetchQueries({
        //   include: "all",
        // });
        toast.success("User Updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setEdit(false);
      }
    } catch (e) {
      toast.error(`${e}`);
    }
  };

  // return loading while loading data
  if (loadingBrands) return <div>Loading...</div>;
  // Return error if get gql error
  if (brandsError) return <div>Error in the query {brandsError.message}</div>;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:w-[500px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary"
    >
      <h2 className="text-center text-3xl text-basic ">Edit User</h2>
      <Divider />
      <div className="grid grid-cols-1 mt-10 gap-5">
        {user?.defaultRole !== "user" && user?.defaultRole !== "vendor" && (
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("defaultRole", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data.value);
                  setRoleValue(data);
                }}
                value={roleValue}
                options={teamRoles}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Role"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["defaultRole"] && el.focus();
                    }}
                    error={errors["defaultRole"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          // <select
          //   {...register("defaultRole", { required: true })}
          //   className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          // >
          //   <option
          //     value="consultant"
          //     selected={user?.defaultRole === "consultant" ? true : false}
          //   >
          //     Consultant
          //   </option>
          //   <option
          //     value="accountant"
          //     selected={user?.defaultRole === "accountant" ? true : false}
          //   >
          //     Accountant
          //   </option>
          //   <option
          //     value="admin"
          //     selected={user?.defaultRole === "admin" ? true : false}
          //   >
          //     Admin
          //   </option>
          //   <option
          //     value="editor"
          //     selected={user?.defaultRole === "editor" ? true : false}
          //   >
          //     Editor
          //   </option>
          // </select>
        )}
        <TextField
          label="User Name"
          {...register("displayName", { required: true })}
          error={errors["displayName"] ? true : false}
          defaultValue={user?.displayName}
        />
        {/* <InputField
          register={register}
          errors={errors}
          fieldName="displayName"
          required={true}
          placeHolder="User Name"
          defaultValue={user?.displayName}
        /> */}
        <TextField
          label="User Email"
          {...register("email", { required: true })}
          error={errors["email"] ? true : false}
          defaultValue={user?.email}
        />
        {/* <InputField
          type="email"
          register={register}
          errors={errors}
          fieldName="email"
          required={true}
          placeHolder="User Email"
          defaultValue={user?.email}
        /> */}

        {RESTRCITED_ROLES.indexOf(user?.defaultRole) < 0 && (
          <TextField
            label="Pseudo Name"
            {...register("pseudo_name", { required: true })}
            error={errors["pseudo_name"] ? true : false}
            defaultValue={profile?.pseudo_name}
          />
          // <InputField
          //   register={register}
          //   errors={errors}
          //   fieldName="pseudo_name"
          //   required={true}
          //   className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          //   placeHolder="Pseudo Name"
          //   defaultValue={profile?.pseudo_name}
          // />
        )}
        {userRole === "admin" && user?.defaultRole !== "user" && (
          <>
            {getAliasLoading ? (
              "Loading..."
            ) : (
              <Controller
                control={control}
                {...register("alias")}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    ref={field.ref}
                    onChange={(_, data: any) => {
                      field.onChange(data?.sendAsEmail);
                      setAliasValue(data);
                    }}
                    value={aliasValue}
                    options={aliases || []}
                    getOptionLabel={(option: any) => option?.sendAsEmail}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option?.sendAsEmail}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        label="Alias"
                        ref={field.ref}
                        inputRef={(el) => {
                          field.ref(el);
                        }}
                        {...params}
                      />
                    )}
                    noOptionsText="No Alias"
                  />
                )}
              />
              // <select
              //   {...register("alias")}
              //   defaultValue={user?.metadata?.alias}
              //   className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              // >
              //   <option value="">No Alias</option>
              //   {getAliasData?.getGmailMessages?.aliases?.map((email: any) => (
              //     <option key={email.sendAsEmail} value={email.sendAsEmail}>
              //       {email.sendAsEmail}
              //     </option>
              //   ))}
              // </select>
            )}
          </>
        )}
        <PhoneBox
          control={control}
          errors={errors}
          fieldName="phoneNumber"
          defaultValue={user?.phoneNumber}
          setValue={setValue}
        />
        <CountryBox
          control={control}
          register={register}
          errors={errors}
          fieldName="country"
          label="Country"
          defaultValue={user?.metadata?.countryCode}
          setValue={setValue}
        />
        {/* <CountryBox
          control={control}
          errors={errors}
          fieldName="country"
          setValue={setValue}
          placeholder="Country"
          defaultValue={user?.metadata?.countryCode}
        /> */}
        <AddressBox
          register={register}
          errors={errors}
          fieldName="location"
          setValue={setValue}
          defaultValue={{
            postalCode: user?.metadata?.postalCode,
            region: user?.metadata?.region,
            city: user?.metadata?.city,
            addressLine1: user?.metadata?.addressLine1,
            addressLine2: user?.metadata?.addressLine2,
          }}
          countryCode={countryCode}
        />
        {RESTRCITED_ROLES.indexOf(user?.defaultRole) < 0 && (
          <>
            <TextField
              multiline
              rows={4}
              label="Bio"
              {...register("bio")}
              defaultValue={profile?.bio}
            />
            {/* <textarea
              {...register("bio")}
              defaultValue={profile?.bio}
              id="bio"
              placeholder="Bio, Tell about yourself"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
            ></textarea> */}

            <TextField
              type="number"
              label="Basic Salary"
              {...register("basic_salary", { required: true })}
              error={errors["basic_salary"] ? true : false}
              defaultValue={profile?.basic_salary}
            />

            {/* <InputField
              type="number"
              register={register}
              errors={errors}
              defaultValue={profile?.basic_salary}
              fieldName="basic_salary"
              required={true}
              placeHolder="Monthly Basic Salary"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              type="number"
              label="Commission"
              {...register("commission")}
              defaultValue={profile?.commission}
            />

            {/* <InputField
              type="number"
              register={register}
              errors={errors}
              defaultValue={profile?.commission}
              fieldName="commission"
              placeHolder="Commission"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              label="Facebook"
              {...register("facebook")}
              defaultValue={profile?.facebook}
            />

            {/* <InputField
              register={register}
              errors={errors}
              defaultValue={profile?.facebook}
              fieldName="facebook"
              placeHolder="Facebook"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              label="Instagram"
              {...register("instagram")}
              defaultValue={profile?.instagram}
            />

            {/* <InputField
              register={register}
              errors={errors}
              defaultValue={profile?.instagram}
              fieldName="intagram"
              placeHolder="Intagram"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              label="LinkedIn"
              {...register("linkedIn")}
              defaultValue={profile?.linkedin}
            />

            {/* <InputField
              register={register}
              errors={errors}
              defaultValue={profile?.linkedin}
              fieldName="linkedIn"
              placeHolder="LinkedIn"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              label="Skype"
              {...register("skype")}
              defaultValue={profile?.skype}
            />

            {/* <InputField
              register={register}
              errors={errors}
              fieldName="skype"
              defaultValue={profile?.skype}
              placeHolder="Skype"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField
              label="Discord"
              {...register("discord")}
              defaultValue={profile?.discord}
            />

            {/* <InputField
              register={register}
              errors={errors}
              fieldName="discord"
              defaultValue={profile?.discord}
              placeHolder="discord"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}

            <TextField label="X" {...register("x")} defaultValue={profile?.x} />

            {/* <InputField
              register={register}
              errors={errors}
              fieldName="x"
              defaultValue={profile?.x}
              placeHolder="X"
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            /> */}
            {userId !== user?.id && (
              <Controller
                control={control}
                {...register("disabled")}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    ref={field.ref}
                    onChange={(_, data: any) => {
                      field.onChange(data.value);
                      setStatusValue(data);
                    }}
                    value={statusValue}
                    options={statusOptions}
                    getOptionLabel={(option: any) => option.name}
                    renderOption={(props, option: any) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        label="Status"
                        ref={field.ref}
                        inputRef={(el) => {
                          field.ref(el);
                        }}
                        {...params}
                      />
                    )}
                    noOptionsText=""
                  />
                )}
              />
            )}
          </>
        )}

        {/* {userId !== user?.id && (
          <div>
            <select
              {...register("disabled")}
              className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="1">Active</option>
              <option value="0" selected={user?.disabled}>
                Suspend
              </option>
            </select>
          </div>
        )} */}
        {brands && (
          <BrandsAssign
            brd_list={brd_list?.brd_list}
            user={user}
            register={register}
          />
        )}
      </div>
      <Button
        sx={{ marginTop: "20px" }}
        type="submit"
        fullWidth
        variant="contained"
      >
        Save
      </Button>
    </form>
  );
}
