import React from "react";
import User from "../../assets/img/user.png";
import Phone from "../../assets/img/phone.png";
import Email from "../../assets/img/email.png";
import Edit from "../../assets/img/edit.png";
import Address from "../../assets/img/address.png";
import { Link } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";

export default function UserBox({
  user,
  setEdit,
  editAble,
  pickedBy,
  inquiry,
  transactions,
}: any) {
  const userRole = useUserDefaultRole();

  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary self-stretch flex flex-col items-end justify-around min-h-[275px] gap-[12px] ">
      <div className="self-stretch flex flex-row pt-0 px-0 pb-[9px] items-center justify-start gap-[14px] text-[20px] text-steelblue-100 border-b-[1px] border-dashed border-steelblue-100">
        <img
          className="relative rounded-45xl w-[79.26px] h-[79px] overflow-hidden shrink-0 object-cover"
          alt=""
          src={User}
        />
        <div className="flex flex-col items-start justify-start gap-[9px]">
          <div className="flex flex-col items-start justify-start">
            <div className="relative leading-[20px] font-semibold capitalize text-basic cursor-pointer">
              <Link
                to={`/${userRole}/brands/${inquiry?.brd_id}/customer/${inquiry?.users?.id}`}
              >
                {`${user?.displayName || user?.name} (${
                  -transactions?.reduce((sum: any, transaction: any) => {
                    return transaction.type === "credit" &&
                      transaction?.def_acc_id === 4
                      ? sum + transaction.amount
                      : sum;
                  }, 0) -
                  transactions?.reduce((sum: any, transaction: any) => {
                    return transaction.type === "debit" &&
                      transaction?.def_acc_id === 4
                      ? sum + transaction.amount
                      : sum;
                  }, 0)
                })`}
              </Link>
            </div>
            <div className="relative text-xs leading-[20px] inline-block  h-4 shrink-0 capitalize text-basic">
              {user?.thp_list?.length > 0
                ? "Super Admin"
                : user?.defaultRole || user?.role}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[7px] text-center text-xs text-mediumseagreen">
            {!user?.disabled ? (
              <>
                <div className="relative rounded-[50%] bg-mediumseagreen w-2 h-2" />
                <div className="relative leading-[14px] capitalize">Active</div>
              </>
            ) : (
              <>
                <div className="relative rounded-[50%] bg-[red] w-2 h-2" />
                <div className="relative leading-[14px] text-[red] capitalize">
                  Suspended
                </div>
              </>
            )}
          </div>
        </div>
        {editAble && (
          <div className="flex items-center justify-start  gap-[9px] absolute top-[5%] right-[5%]">
            <img
              onClick={() => {
                setEdit(true);
              }}
              className="cursor-pointer relative w-[15px] h-[14.94px]"
              alt=""
              src={Edit}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-col w-full gap-4">
        <div className="flex-1 pd-4">
          <div className="self-stretch flex flex-row items-start justify-start gap-[5px]">
            <img
              className="relative w-[15.06px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src={Phone}
            />
            <div className="relative font-semibold">
              {" "}
              {(user?.phoneNumber || user?.metadata?.phone || user?.phone) ??
                "N/A"}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              <img
                className="relative w-[12.94px] h-[8.39px]"
                alt=""
                src={Email}
              />
              <div className="relative font-semibold">
                {" "}
                {user?.email || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              <img className="relative  h-[8.39px]" alt="" src={Address} />
              <div className="relative font-semibold">
                {" "}
                {user?.metadata?.country || "N/A"}
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              <img className="relative  h-[8.39px]" alt="" src={Address} />
              <div className="relative font-semibold">
                {" "}
                {user?.metadata?.addressLine1 || "N/A"}{" "}
                {user?.metadata?.addressLine2 || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
