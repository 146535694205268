import React from "react";

interface ConfrimPopupProps {
  confirm: any;
  closeModal: any;
}

const ConfrimPopup = ({ confirm, closeModal }: ConfrimPopupProps) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/70"
      style={{ zIndex: 50 }}
    >
      <div className="relative bg-secondary dark:bg-dark-secondary text-primary dark:text-dark-primary rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
        <h2 className="mb-6 text-2xl font-bold">Are you sure?</h2>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-50 rounded-full hover:bg-opacity-80"
          >
            No
          </button>
          <button
            onClick={() => {
              confirm();
              closeModal();
            }}
            className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfrimPopup;
