import { useState, useEffect } from "react";
import Plan from "./components/Plan";
import { useQuery } from "@apollo/client";
import { GET_DEF_SUBSCRIPTION_PLANS } from "~/graphql/def_subscription_plans/Query";
import useThpListDetail from "~/hooks/useThpListDetail";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { stripe } from "~/lib/stripe";

const Subscriptions = () => {
  const customUrl = GetCustomUrl(window.location.host);

  const { thpHouse } = useThpListDetail(customUrl);

  const { data } = useQuery(GET_DEF_SUBSCRIPTION_PLANS);

  const [currSub, setCurrSub] = useState(
    thpHouse?.[0]?.yearly === true ? "yearly" : "monthly"
  );

  const getCustomerPayDetail = async (custId: any) => {
    const res = await stripe.customers.retrieve(custId);
    console.log("sgfjsd", res);
  };

  useEffect(() => {
    setCurrSub(thpHouse?.[0]?.yearly === true ? "yearly" : "monthly");
    if (thpHouse?.[0]?.subscription_user_ref) {
      getCustomerPayDetail(thpHouse?.[0]?.subscription_user_ref);
    }
  }, [thpHouse?.[0]]);

  return (
    <div>
      <h1 className="font-bold text-lg">SUBSCRIPTIONS</h1>
      <div className="bg-basic rounded-full w-[200px] mx-auto flex items-center mt-3 h-[50px] cursor-pointer">
        <button
          onClick={() => setCurrSub("monthly")}
          className={`h-[93%] ml-[3px] w-[50%] rounded-full font-bold duration-500 ease-in-out transition-all ${
            currSub === "monthly" ? "bg-white text-black" : "text-white"
          }`}
        >
          Monthly
        </button>
        <button
          onClick={() => setCurrSub("yearly")}
          className={`h-[93%] mr-[3px] w-[50%] rounded-full font-bold duration-500 ease-in-out transition-all ${
            currSub === "yearly" ? "bg-white text-black" : "text-white"
          }`}
        >
          Yearly
        </button>
      </div>
      <div className="flex justify-center gap-10 mt-7 duration-500 ease-in-out transition-all">
        {data?.def_subscription_plans
          ?.filter(
            (item: any) => item.id !== "0833e55d-ef57-4797-859b-0d8b1e407cd9"
          )
          ?.map((item: any) => {
            return (
              <Plan
                key={item.id}
                data={item}
                currSub={currSub}
                travelHouse={thpHouse?.[0]}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Subscriptions;
