import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React from "react";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { GET_ACTIVE_MEMBERS_WITH_THP } from "../../../TeamList/components/TravelHouseWise/graphql/Query";
import SingleRecord from "./components/SingleRecord";
import { useForm } from "react-hook-form";
import { GET_USERS } from "./graphql/Query";
import moment from "moment";
import { ADD_ATTENDANCE, UPDATE_ATTENDANCE } from "./graphql/Mutation";
import { toast } from "react-toastify";
// import SingleRecord from "~/modules/TravelHousesModule/segments/TravelHouseList/SingleRecord";

export default function ResultsForm({ date }: any) {
  const { travelHouse }: any = travelHouseStore();

  const [add] = useMutation(ADD_ATTENDANCE);
  const [update] = useMutation(UPDATE_ATTENDANCE);
  const client = useApolloClient();

  const startDate = moment(date).format("YYYY-MM-DD");
  const endDate = moment(date).add(1, "day").format("YYYY-MM-DD");

  const timestart = `${startDate}T00:00:00.000+00:00`;
  const timeend = `${endDate}T00:00:00.000+00:00`;

  const { data, error, loading } = useQuery(GET_USERS, {
    variables: { thp_id: travelHouse?.id, timestart, timeend },
  });

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData: any) => {
    const addAttendanceData: any = [];
    const updateAttendanceData: any = [];

    formData.user.map((item: any) => {
      if (item.id) {
        updateAttendanceData.push(item);
      } else {
        item["thp_id"] = travelHouse.id;
        item["date"] = moment(date).format("YYYY-MM-DD");
        addAttendanceData.push(item);
      }
    });

    if (addAttendanceData.length > 0) {
      try {
        const res = await add({
          variables: {
            data: addAttendanceData,
          },
        });
        await client.resetStore();
        toast.success("Attendance added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (e) {
        console.error("error", e);
      }
    }
    if (updateAttendanceData.length > 0) {
      updateAttendanceData.map(async (item: any) => {
        const id = item.id;
        delete item.user_id;
        delete item.id;
        try {
          const res = await update({
            variables: {
              id,
              data: item,
            },
          });

          await client.resetStore();
        } catch (e) {
          console.error("error", e);
        }
      });

      toast.success("Attendance added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        {data?.users.map((user: any, index: any) => {
          return (
            <SingleRecord
              user={user}
              date={date}
              errors={errors}
              index={index}
              register={register}
            />
          );
        })}
        <div className="flex gap-2 w-full mt-4">
          <button
            className="w-full btn bg-basic text-white rounded-md p-2"
            type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
