import { useQuery } from "@apollo/client";
import { GET_TRAVEL_HOUSE_DETAIL } from "../../graphql/thp_list/Query";
import { useUserId } from "@nhost/react";

export default function useThpListDetail(url: any) {
  const userId = useUserId();

  const { data, error } = useQuery(GET_TRAVEL_HOUSE_DETAIL, {
    variables: { custom_url: url, user_id: userId },
  });

  return { thpHouse: data?.thp_list };
}
