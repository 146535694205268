import { useState } from "react";
import SelectButton from "./components/SelectButton";
import FlightDetail from "./components/FlightDetail";
import EditSuggestion from "./components/EditSuggestion";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import CostBreakDown from "./components/CostBreakDown";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_SUGGESTION } from "./graphql/Mutation";
import { toast } from "react-toastify";

const SuggestionBox = ({
  suggestion,
  selected_suggestion_id,
  selected,
  active,
  picked_by,
  user_email,
  brand_domain,
  brand_url,
  brand_name,
  user_id,
  inquiry,
  hideActions,
}: any) => {
  const [showAddModal, setShowAddModal] = useState<any>(false);
  const [showCostBreakdownModal, setShowCostBreakdownModal] = useState(false); // New state for cost breakdown modal
  const [deleteSuggestion] = useMutation(DELETE_SUGGESTION);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();

  const userRole = useUserDefaultRole();
  const userLoggedIn = useUserId();

  const removeSuggestion = async (id: any) => {
    setWait(true);
    const res: any = await deleteSuggestion({
      variables: { id: id },
    });

    if (res?.data?.delete_inq_suggestion_by_pk?.id) {
      toast.success("Suggestion deleted successfully");
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
    setWait(false);
  };

  return (
    <>
      {active && (
        <div
          className={` ${
            active ? `show` : `hide`
          } py-[55px] px-[15px] md:px-[55px] self-stretch relative bg-white-100 overflow-hidden flex flex-wrap mb-6 items-center justify-center gap-[60px] text-left text-xs text-darkslategray  border-[1px] border-solid border-silver`}
        >
          {!hideActions && (
            <>
              {(userLoggedIn === inquiry?.picked_by ||
                userRole === "admin") && (
                <>
                  {!(selected_suggestion_id === suggestion?.id) && (
                    <button
                      onClick={() => removeSuggestion(suggestion?.id)}
                      disabled={wait}
                      type="button"
                      className="text-red-500 absolute left-[20px] top-[20px] cursor-pointer"
                    >
                      {wait ? `...` : <FaTrash size={16} />}
                    </button>
                  )}
                </>
              )}
              {inquiry?.status !== "completed" && (
                <>
                  {!inquiry?.invoice_id && (
                    <>
                      {(userLoggedIn === inquiry?.picked_by ||
                        userRole === "admin") && (
                        <button
                          onClick={() => {
                            setShowAddModal(!showAddModal);
                          }}
                          className="absolute top-[0px] right-[-75px] z-[2]"
                        >
                          <span className=" bg-basic cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[100px] md:right-[130px]">
                            Edit
                          </span>
                        </button>
                      )}
                      <button
                        className="absolute top-[0px] right-[90px]  z-[2] w-[150px]"
                        onClick={() => setShowCostBreakdownModal(true)}
                      >
                        <span className=" bg-basic cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[100px] md:right-[130px] w-full">
                          Price Breakdown
                        </span>
                      </button>

                      <SelectButton
                        selected={selected}
                        brand_domain={brand_domain}
                        suggestion={suggestion}
                        inquiry={inquiry}
                        inquiry_id={inquiry?.id}
                        user_email={user_email}
                        brand_url={brand_url}
                        brand_name={brand_name}
                        selected_suggestion_id={selected_suggestion_id}
                        picked_by={picked_by}
                        user_id={user_id}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {suggestion?.inq_suggestion_legs
            ?.sort((a: any, b: any) =>
              a.type === "departing" ? -1 : b.type === "departing" ? 1 : 0
            )
            .map((suggestion_leg: any, index: any) => {
              return (
                <FlightDetail
                  key={index}
                  suggestion_leg={suggestion_leg}
                  index={index}
                />
              );
            })}

          <div className="absolute right-5 bottom-2 mb-[10px] mr-[15px]">
            <div className="self-stretch flex flex-col items-start justify-start mt-2">
              <div className="flex flex-row items-center justify-start gap-[41px]">
                {userRole !== "user" && (
                  <div className="flex flex-row items-center justify-start gap-[10px] ">
                    <strong className="">Cost:</strong>
                    <div className="relative inline-block shrink-0 text-[20px] font-bold">
                      £
                      {suggestion?.inq_suggestion_costs?.reduce(
                        (sum: any, obj: any) =>
                          sum +
                          parseFloat(obj.basic_fare) +
                          parseFloat(obj.tax_price) +
                          parseFloat(obj.atol_price),
                        0
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-row items-center justify-start gap-[6px]">
                  <strong className="">Sale:</strong>
                  <div className="relative inline-block shrink-0 text-[20px] font-bold">
                    £
                    {suggestion?.inq_suggestion_costs?.reduce(
                      (sum: any, obj: any) => sum + parseFloat(obj.sale_price),
                      0
                    ) + parseFloat(suggestion?.bookingFee)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCostBreakdownModal && (
        <CostBreakDown
          inq_suggestion_cost={suggestion.inq_suggestion_costs}
          setShowCostBreakdownModal={setShowCostBreakdownModal}
        />
      )}

      {showAddModal && (
        <EditSuggestion
          suggestion={suggestion}
          setShowAddModal={setShowAddModal}
          inquiry={inquiry}
          inquiry_id={inquiry.id}
          passengers={inquiry.inq_passengers}
          arrival={inquiry?.to}
          user_id={inquiry.users.id}
        />
      )}
    </>
  );
};

export default SuggestionBox;
