import { gql } from "@apollo/client";

export const GET_DEF_SUBSCRIPTION_PLANS = gql`
  query def_subscription_plans {
    def_subscription_plans {
      id
      plan_name
      price_monthly
      price_yearly
      monthly_plan_invoices
      monthly_plan_seats
      monthly_plan_brands
      yearly_plan_invoices
      yearly_plan_brands
      yearly_plan_seats
    }
  }
`;
