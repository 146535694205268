import React, { useEffect, useState } from "react";
import WhatsAppChat from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/WhatsAppChat";
import WhatsappNew from "~/modules/BrandModule/modules/WhatsappModule/components/Whatsapp/WhatsappNew";
import Filters from "./components/Filters";
import Statistics from "./components/Inquires";
import { useForm } from "react-hook-form";
import moment from "moment";
import { GET_ASSIGNED_BRANDS } from "./graphql/Query";
import { useUserId } from "@nhost/react";
import { useQuery } from "@apollo/client";
import Inquires from "./components/Inquires";
import Payments from "./components/Payments";
import VendorsPaidAmount from "./components/VendorsPaidAmount";
import Activity from "./components/Activity";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import Charts from "./components/Charts";
import dayjs from "dayjs";
import useAssignedBrands from "~/hooks/useAssignedBrands";

export default function DashboardStats() {
  const userID = useUserId();

  const { brdIds } = useAssignedBrands();

  const { travelHouse }: any = travelHouseStore();
  const { loading, data, error } = useQuery(GET_ASSIGNED_BRANDS, {
    variables: { id: userID, thp_id: travelHouse?.id },
  });

  const today = dayjs();
  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];

  const [dates, setDates] = useState(currentMonth);

  const [brands, setBrands] = useState([]);
  const [brandsRec, setBrandsRec] = useState([]);

  useEffect(() => {
    const brdRec: any = [];
    if (data) {
      if (data?.brd_users?.length > 0) {
        data?.brd_users?.map((brd: any) => {
          brdRec.push({ id: brd?.brd_id, name: brd.brd_list.name });
        });
      } else {
        data?.thp_list?.[0].brd_lists?.map((brd: any) => {
          brdRec.push({ id: brd?.id, name: brd.name });
        });
      }
      setBrands(brdRec);
      setBrandsRec(brdRec);
    }
  }, [loading]);
  //
  const brd_ids: any = [];

  brands.map((brd: any) => {
    brd_ids.push(brd?.id);
  });

  const [source, setSource] = useState<any>({
    source: null,
    source_place: null,
    source_position: null,
    source_keyword: null,
    source_add_id: null,
  });

  if (loading) return <>Loading..</>;
  if (error) return <>{error.message}</>;

  return (
    <>
      <div className="flex border-b-2 border-gray-300 dark:border-[#E7E3FC61] flex-col mb-2 justify-between w-full">
        <h1 className="text-[#38333F] dark:text-dark-primary uppercase text-[18px] font-bold ">
          Dashbaord
        </h1>
        <div>
          <Filters
            dates={dates}
            setDates={setDates}
            setBrands={setBrands}
            brands={brandsRec}
            setSource={setSource}
            source={source}
          />
        </div>
      </div>
      <div className="pt-4">
        {brandsRec?.length > 0 && (
          <Charts
            dates={dates}
            brands={brands}
            allBrand={brandsRec}
            source={source}
          />
        )}
      </div>
      {/* <div className="pt-4">
        <Inquires dates={dates} brands={brands} />
      </div>
      <div className="pt-4">
        <Payments dates={dates} brands={brands} />
      </div>
      <div className="pt-4">
        <VendorsPaidAmount dates={dates} brd_ids={brd_ids} />
      </div> */}
    </>
  );
}
