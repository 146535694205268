import { Autocomplete, Box, Button, Switch, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import _airlineData from "../../../../../../components/FormInputs/AirlineBox/data/airlines.json";
import { useState } from "react";
import { toast } from "react-toastify";
import { ALLOWED_IMAGE_FORMATS } from "~/config/constants";
import { useFileUpload } from "@nhost/react";
import { useApolloClient, useMutation } from "@apollo/client";
import { REMOVE_FILE, UPDATE_AIRLINE } from "./graphql/Mutation";

const AirlineDetail = ({ page }: any) => {
  const [featuredFile, setFeatureFile] = useState<any | null>(null);
  const [searchWithInquiry, setSearchWithInquiry] = useState<any | null>(
    page?.search_with_inquiry
  );
  const [showAirlineName, setShowAirlineName] = useState<any | null>(
    page?.show_header_airline_name
  );
  const [customLogo, setCustomLogo] = useState<any | null>(null);
  const [heroFile, setHeroFile] = useState<any | null>(null);
  const { upload: uploadLogo, progress: logoUploadProgress } = useFileUpload();

  const [deleteFile] = useMutation(REMOVE_FILE);
  const [updateAirline] = useMutation(UPDATE_AIRLINE);
  const client = useApolloClient();

  const [oldCustomLogo, setOldCustomLogo] = useState<any | null>(
    page?.custom_logo || null
  );
  const [oldHeroImage, setOldHeroImage] = useState<any | null>(
    page?.hero_image || null
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFeatureUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setFeatureFile({ file });
    }
  };

  const customLogoFile = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setCustomLogo({ file });
    }
  };

  const handleHeroUpload = async (event: any) => {
    const file = event.target.files[0];
    if (
      !ALLOWED_IMAGE_FORMATS.includes(file.type.split("/").at(1).toLowerCase())
    ) {
      toast.error("Invalid File format");
    } else {
      setHeroFile({ file });
    }
  };

  const onSubmit = async (inpData: any) => {
    // return 0;

    if (featuredFile) {
      const featuredFileRes = await uploadLogo(featuredFile);
      inpData["airline_image"] = featuredFileRes?.id || null;
    } else {
      inpData["airline_image"] = page?.airline_image;
    }
    if (heroFile) {
      const featuredFileRes = await uploadLogo(heroFile);
      inpData["hero_image"] = featuredFileRes?.id || null;
      if (oldHeroImage)
        await deleteFile({
          variables: {
            id: oldHeroImage,
          },
        });
    } else {
      if (oldHeroImage) {
        inpData["hero_image"] = oldHeroImage;
      } else {
        inpData["hero_image"] = null;
        if (page?.hero_image)
          await deleteFile({
            variables: {
              id: page?.hero_image,
            },
          });
      }
    }

    if (customLogo) {
      const customLogoRes = await uploadLogo(customLogo);
      inpData["custom_logo"] = customLogoRes?.id || null;
      if (oldCustomLogo)
        await deleteFile({
          variables: {
            id: oldCustomLogo,
          },
        });
    } else {
      if (oldCustomLogo) {
        inpData["custom_logo"] = oldCustomLogo;
      } else {
        inpData["custom_logo"] = null;
        if (page?.custom_logo)
          await deleteFile({
            variables: {
              id: page?.custom_logo,
            },
          });
      }
    }

    // search_with_inquiry
    const res = await updateAirline({
      variables: {
        id: page?.id,
        data: {
          airline_iata: inpData?.airline?.iata,
          airline_name: inpData?.airline?.marketing_name,
          airline_image: inpData?.airline_image || null,
          hero_image: inpData?.hero_image || null,
          custom_logo: inpData?.custom_logo || null,
          airline_primary_color: inpData?.primaryColor || null,
          airline_secondary_color: inpData?.secondaryColor || null,
          airline_header_color: inpData?.headerColor || null,
          airline_heading: inpData?.heroHeading,
          airline_heading_2: inpData?.heroHeading2,
          airline_sub_heading: inpData?.heroHeadingDescription,
          search_with_inquiry: inpData?.footerFlightLinks,
          show_header_airline_name: inpData?.show_header_airline_name,
          slug: inpData?.airline?.marketing_name
            ?.replaceAll(" ", "-")
            ?.toLowerCase(),
        },
      },
    });
    if (res?.data?.update_brd_airlines_pages_by_pk?.id) {
      toast.success("Airline details updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      await client.refetchQueries({
        include: "all",
      });
    }
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Airline Base Settings</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <Controller
              control={control}
              {...register("airline")}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  ref={field.ref}
                  onChange={(_, data: any) => {
                    field.onChange(data);
                  }}
                  filterOptions={(options, { inputValue }: any) => {
                    // If input is empty, don't show any suggestions
                    if (!inputValue.trim()) {
                      return [];
                    }

                    // Filter options to only include those that start with the user's input
                    const inputValueLowerCase = inputValue.toLowerCase();
                    return options.filter((option: any) =>
                      option.marketing_name
                        .toLowerCase()
                        .startsWith(inputValueLowerCase)
                    );
                  }}
                  options={_airlineData ? _airlineData : []}
                  getOptionLabel={(option: any) => option.marketing_name}
                  renderOption={(props, option: any) => (
                    <Box component="li" {...props}>
                      {option.marketing_name}
                    </Box>
                  )}
                  defaultValue={() => {
                    const data = _airlineData.find(
                      (option) => option.iata === page?.airline_iata
                    );
                    field.onChange(data);
                    return data;
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Airline"
                      ref={field.ref}
                      inputRef={(el) => {
                        field.ref(el);
                      }}
                      {...params}
                    />
                  )}
                  noOptionsText=""
                />
              )}
            />
            <TextField
              defaultValue={page?.airline_heading}
              {...register("heroHeading")}
              label="Hero Heading"
              type="text"
            />
            <TextField
              defaultValue={page?.airline_heading_2}
              {...register("heroHeading2")}
              label="Hero Heading 2"
              type="text"
            />
            <TextField
              defaultValue={page?.airline_sub_heading}
              {...register("heroHeadingDescription")}
              label="Hero Heading Description"
              type="text"
            />
            <TextField
              defaultValue={page?.airline_primary_color}
              {...register("primaryColor")}
              label="Primary Color"
              type="text"
            />
            <TextField
              defaultValue={page?.airline_secondary_color}
              {...register("secondaryColor")}
              label="Secondary Color"
              type="text"
            />
            <TextField
              defaultValue={page?.airline_header_color}
              {...register("headerColor")}
              label="Header Color"
              type="text"
            />
            <div className="flex justify-between xl:w-[50%] items-center">
              <label>Footer Flight Links</label>
              <Switch
                {...register("footerFlightLinks")}
                value={searchWithInquiry}
                checked={searchWithInquiry}
                onChange={() => setSearchWithInquiry(!searchWithInquiry)}
              />
            </div>
            <div>
              <label className="mb-2">Featured Image</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleFeatureUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 322x450px)"
              />
            </div>
            <div>
              <label className="mb-2">Custom Logo</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={customLogoFile}
                helperText="SVG, PNG, JPG or GIF (MAX. 322x450px)"
              />
            </div>
            <div>
              <label className="mb-2">Image</label>
              <TextField
                sx={{ width: "100%" }}
                // {...register("featuredImage")}
                type="file"
                inputProps={{
                  style: {
                    paddingTop: "7px",
                    paddingBottom: "27px",
                    paddingLeft: "23px",
                  },
                }}
                variant="outlined"
                onChange={handleHeroUpload}
                helperText="SVG, PNG, JPG or GIF (MAX. 322x450px)"
              />
            </div>
            <div className="flex justify-between xl:w-[60%] items-center">
              <label>Show Header Airline Name</label>
              <Switch
                {...register("show_header_airline_name")}
                value={showAirlineName}
                checked={showAirlineName}
                onChange={() => setShowAirlineName(!showAirlineName)}
              />
            </div>
          </div>
          <Button type="submit" sx={{ marginTop: "20px" }} variant="contained">
            Save
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AirlineDetail;
