import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import TipsSectionCard from "./components/TipsSectionCard";

const BrandTipSection = ({ tips, insertTipsSection }: any) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (inpData: any) => {
    insertTipsSection(inpData);
  };

  return (
    <>
      <div className="bg-secondary dark:bg-dark-secondary rounded-lg shadow p-5 mb-5 text-primary dark:text-dark-primary">
        <h1 className="text-xl">Important Tips Section</h1>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 mt-7">
            <TipsSectionCard
              index={0}
              ft={tips?.at(0)}
              register={register}
              setValue={setValue}
              errors={errors}
            />
            <TipsSectionCard
              index={1}
              ft={tips?.at(1)}
              register={register}
              setValue={setValue}
              errors={errors}
            />
            <TipsSectionCard
              errors={errors}
              index={2}
              ft={tips?.at(2)}
              register={register}
              setValue={setValue}
            />
            <TipsSectionCard
              errors={errors}
              index={3}
              ft={tips?.at(3)}
              register={register}
              setValue={setValue}
            />
          </div>
          <Button type="submit" sx={{ marginTop: "20px" }} variant="contained">
            Save Value Section
          </Button>
        </Box>
      </div>
    </>
  );
};

export default BrandTipSection;
