import { useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import SingleInputDateRangePicker from "~/modules/InquiriesModule/components/InquiryTable/components/InquiryFilterButtons/components/DateRangePicker";
import moment from "moment";

const BrandDetail = () => {
  const location = useLocation();
  const today = dayjs();
  const currentMonth = [
    today.startOf("month").subtract(1, "month"),
    today.endOf("month"),
  ];
  const [dates, setDates] = useState<any>(currentMonth);
  const startDateTime = moment(dates?.[0]?.$d).startOf("day").toISOString();
  const endDateTime = moment(dates?.[1]?.$d).endOf("day").toISOString();
  const { THP } = location.state || {};

  const Income = (
    transactions: Array<any>,
    startDate: string,
    endDate: string
  ) => {
    const creditIncome = transactions
      .filter(
        (trans) =>
          trans.def_acc_list?.type === "income" &&
          trans.type === "credit" &&
          moment(trans.created_at).isBetween(
            startDate,
            endDate,
            undefined,
            "[]"
          )
      )
      .reduce((sum, trans) => sum - trans.amount, 0)
      .toFixed(2);

    const debitIncome = transactions
      .filter(
        (trans) =>
          trans.def_acc_list?.type === "income" &&
          trans.type === "debit" &&
          moment(trans.created_at).isBetween(
            startDate,
            endDate,
            undefined,
            "[]"
          )
      )
      .reduce((sum, trans) => sum + trans.amount, 0)
      .toFixed(2);

    return creditIncome - debitIncome;
  };

  const Expense = (
    transactions: Array<any>,
    startDate: string,
    endDate: string
  ) => {
    const creditExpense = transactions
      .filter(
        (trans) =>
          trans.def_acc_list?.type === "expense" &&
          trans.type === "credit" &&
          moment(trans.created_at).isBetween(
            startDate,
            endDate,
            undefined,
            "[]"
          )
      )
      .reduce((sum, trans) => sum - trans.amount, 0)
      .toFixed(2);

    const debitExpense = transactions
      .filter(
        (trans) =>
          trans.def_acc_list?.type === "expense" &&
          trans.type === "debit" &&
          moment(trans.created_at).isBetween(
            startDate,
            endDate,
            undefined,
            "[]"
          )
      )
      .reduce((sum, trans) => sum + trans.amount, 0)
      .toFixed(2);

    return debitExpense - creditExpense;
  };

  const brandTotals = THP?.[0]?.brd_lists.map((brand: any) => {
    const income = Income(brand.acc_transactions, startDateTime, endDateTime);
    const expense = Expense(brand.acc_transactions, startDateTime, endDateTime);
    const profit = (income - expense).toFixed(2);

    return {
      brandId: brand.id,
      income,
      expense,
      profit,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "brdName",
      headerName: "Brands Name",
      width: 200,
      editable: false,
    },
    {
      field: "brdInq",
      headerName: "Total Inquiries",
      width: 150,
      editable: false,
    },
    {
      field: "brdInqComp",
      headerName: "Completed Inquiries",
      width: 150,
      editable: false,
    },
    {
      field: "brdInqPend",
      headerName: "Pending Inquiries",
      width: 150,
      editable: false,
    },
    {
      field: "brdInqRefund",
      headerName: "Refunded Inquiries",
      width: 150,
      editable: false,
    },
    {
      field: "brdInc",
      headerName: "Total Income",
      width: 150,
      editable: false,
    },
    {
      field: "brdExp",
      headerName: "Total Expense",
      width: 150,
      editable: false,
    },
    {
      field: "brdProf",
      headerName: "Total Profit",
      width: 150,
      editable: false,
    },
  ];

  const rows = Array.isArray(THP?.[0]?.brd_lists)
    ? THP?.[0]?.brd_lists?.map((brand: any, index: number) => {
        const brandTotal = brandTotals.find(
          (total: any) => total.brandId === brand.id
        );
        return {
          id: index,
          brdName: brand.name,
          brdInq:
            brand.brd_unique_inqs?.filter((inq: any) => {
              const createdAt = moment(inq?.main_inq?.created_at).toISOString();
              return (
                inq?.main_inq?.created_at &&
                createdAt >= startDateTime &&
                createdAt <= endDateTime
              );
            }).length || "No Inquiries",
          brdInqComp:
            brand.brd_unique_inqs?.filter((inq: any) => {
              const createdAt = moment(inq?.main_inq?.created_at).toISOString();
              return (
                inq?.main_inq?.created_at &&
                createdAt >= startDateTime &&
                createdAt <= endDateTime &&
                inq.main_inq?.status === "completed"
              );
            }).length || "No Inquiries",
          brdInqPend:
            brand.brd_unique_inqs?.filter((inq: any) => {
              const createdAt = moment(inq?.main_inq?.created_at).toISOString();
              return (
                inq?.main_inq?.created_at &&
                createdAt >= startDateTime &&
                createdAt <= endDateTime &&
                inq.main_inq?.status === "pending"
              );
            }).length || "No Inquiries",
          brdInqRefund:
            brand.brd_unique_inqs?.filter((inq: any) => {
              const createdAt = moment(inq?.main_inq?.created_at).toISOString();
              return (
                inq?.main_inq?.created_at &&
                createdAt >= startDateTime &&
                createdAt <= endDateTime &&
                inq.main_inq?.status === "refunded"
              );
            }).length || "No Inquiries",
          brdInc: brandTotal?.income || 0,
          brdExp: brandTotal?.expense || 0,
          brdProf: brandTotal?.profit || 0,
        };
      })
    : [];
  return (
    <div>
      <div className="flex justify-start md:justify-end">
        <div className="w-full md:w-1/4">
          <SingleInputDateRangePicker setDates={setDates} dates={dates} />
        </div>
      </div>
      <h1 className=" mt-10 mb-2 text-[#3A3541DE]">
        Company: {THP?.[0]?.name}
      </h1>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default BrandDetail;
