// import useNotifications from "@hooks/useNotifications";
// import commonStore from "@store/common";
// import { insertLog } from "@utils/logHelper";
import moment from "moment";
import { useState } from "react";
// import React, { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import PassengerEdit from "./components/PassengerEdit";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import { FaTimes } from "react-icons/fa";
import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_PASSENGER } from "./graphql/Mutation";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

const PassengerBox = ({
  passenger,
  inquiry,
  selected_suggestion_id,
  totalPassengers,
  hideActions,
}: any) => {
  // Get role from cookies
  const role = useUserDefaultRole();
  const [hideForm, setHideForm] = useState(false);
  const [deletePassanger] = useMutation(DELETE_PASSENGER);
  const [wait, setWait] = useState(false);
  const client = useApolloClient();
  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const userDefaultRole = useUserDefaultRole();
  const user_id = useUserId();

  const prices = passenger?.inq_suggestion_costs.filter(
    (sc: any) => sc.suggestion_id === selected_suggestion_id
  )?.[0];

  const removePassenger = async (id: string) => {
    // ;
    setWait(true);

    const res = await deletePassanger({ variables: { id: id } });
    await updateInqListUser({
      variables: { id: inquiry.id, updated_by: user_id },
    });
    if (res?.data?.delete_inq_passengers_by_pk?.id) {
      toast.success("Passnger removed successfully");
      await client.resetStore();
    } else {
      toast.error("Something went wrong!");
    }
    setWait(false);
  };

  return (
    <>
      {!hideForm ? (
        <div className="self-stretch relative flex-1 min-h-[350px] bg-white-100 flex flex-col p-[25px] items-center justify-between gap-[20px] text-left text-mini text-darkslategray  border-[1px] border-solid border-lightsteelblue">
          {!selected_suggestion_id && totalPassengers > 1 && (
            <button
              disabled={wait}
              onClick={() => removePassenger(passenger?.id)}
              className="bg-red-500 h-[20px] w-[20px] rounded-full text-white flex justify-center items-center absolute top-[-5px] right-[-5px]"
            >
              {wait ? "..." : <FaTimes />}
            </button>
          )}
          <div className="self-stretch flex flex-row items-start justify-between text-sm">
            <div className="flex flex-row items-start justify-start">
              <div className="flex flex-row items-center justify-start">
                <p>Passenger: </p>
                <b className="relative capitalize"> {passenger?.type}</b>
              </div>
            </div>
            {!inquiry?.selected_suggestion &&
              !hideActions &&
              (user_id === inquiry?.picked_by || userDefaultRole === "admin") &&
              inquiry?.status !== "completed" && (
                <button
                  onClick={() => setHideForm(true)}
                  className="cursor-pointer "
                >
                  Edit
                </button>
              )}
          </div>
          <div className="self-stretch flex flex-row items-start justify-between text-sm">
            <div className="flex flex-row items-start justify-start">
              <div className="flex flex-row items-center justify-start">
                <p>Gender: </p>
                <b className="relative capitalize">
                  {" "}
                  {passenger?.gender || (
                    <span className="text-[red] text-[11px] px-2 inline-block italic">
                      Missing!
                    </span>
                  )}
                </b>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-0 px-0 pb-2.5 items-center justify-start border-b-[1px] border-solid border-lightsteelblue">
            <div className="flex-1 rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[130%]">
                <p className="m-0">Name</p>
                <p className="m-0 text-mediumseagreen">
                  <b>
                    {passenger?.first_name || (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}{" "}
                    {passenger?.last_name || (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row pt-0  justify-between px-0 pb-2.5 items-center  border-b-[1px] border-solid border-lightsteelblue">
            <div className="flex-1 flex flex-row items-center justify-start">
              <div className="relative">
                <p className="m-0">Date Of Birth</p>
                <p className="m-0 text-steelblue-100">
                  <b>
                    {passenger?.dob ? (
                      moment(passenger?.dob).format("DD-MM-YYYY")
                    ) : (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
            <div className="flex-1 rounded-8xs flex flex-row items-center justify-end text-right">
              <div className="relative leading-[130%]">
                <p className="m-0">Naionality</p>
                <p className="m-0 text-steelblue-100">
                  <b>
                    {passenger?.nationality || (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between  border-b-[1px] border-solid border-lightsteelblue">
            <div className="rounded-8xs flex flex-col items-start justify-start">
              <div className="self-stretch relative">
                <p className="m-0">Passport No</p>
                <p className="m-0 text-steelblue-100">
                  <b>
                    {passenger?.passport_no || (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
            <div className="rounded-8xs flex flex-col items-end justify-start text-right">
              <div className="self-stretch relative">
                <p className="m-0">Passport Expiry</p>
                <p className="m-0 text-steelblue-100">
                  <b>
                    {passenger?.passport_expiry ? (
                      moment(passenger?.passport_expiry).format("DD-MM-YYYY")
                    ) : (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            {role !== "user" && (
              <>
                <div className="rounded-8xs flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    <p className="m-0">Cost Price</p>
                    <p className="m-0 text-steelblue-100">
                      <b>
                        {prices?.basic_fare || (
                          <span className="text-[red] text-[11px] px-2 inline-block italic">
                            Missing!
                          </span>
                        )}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="rounded-8xs flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    <p className="m-0">Tax Price</p>
                    <p className="m-0 text-steelblue-100">
                      <b>
                        {prices?.tax_price || (
                          <span className="text-[red] text-[11px] px-2 inline-block italic">
                            Missing!
                          </span>
                        )}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="rounded-8xs flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    <p className="m-0">Atol Price</p>
                    <p className="m-0 text-steelblue-100">
                      <b>
                        {prices?.atol_price || (
                          <span className="text-[red] text-[11px] px-2 inline-block italic">
                            Missing!
                          </span>
                        )}
                      </b>
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="rounded-8xs flex flex-col items-end justify-start text-right">
              <div className="self-stretch relative">
                <p className="m-0">Sale Price</p>
                <p className="m-0 text-steelblue-100">
                  <b>
                    {prices?.sale_price || (
                      <span className="text-[red] text-[11px] px-2 inline-block italic">
                        Missing!
                      </span>
                    )}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PassengerEdit
          inquiry_id={inquiry?.id}
          user_id={inquiry?.users?.id}
          picked_by={inquiry?.picked_by}
          hideForm={setHideForm}
          passenger={passenger}
        />
      )}
    </>
  );
};

export default PassengerBox;
