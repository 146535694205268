import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
// import gql queries
import { GET_TRAVEL_HOUSE } from "./graphql/Query";
import travelHouseStore from "../../../../store/travelHouse/travelHouseStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserDefaultRole, useUserId } from "@nhost/react";
import NoTravelHouse from "./components/NoTravelHouse";
import { URLs } from "~/config/enums";
import { GetCustomUrl } from "~/utils/GetCustomUrl";
import { INSERT_FINGERPRINT_IDS } from "~/graphql/user_fingerprint_ids/Mutation";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { INSERT_THP_USER_ATTENDANCE } from "~/graphql/thp_user_attendance/Mutation";

export default function VerifyUser() {
  const navigate = useNavigate();
  const userDefaultRole = useUserDefaultRole();
  const userId = useUserId();
  const { setTravelHouse }: any = travelHouseStore();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const customUrl = GetCustomUrl(window.location.host);
  // get data based on query
  const { loading, data, error } = useQuery(GET_TRAVEL_HOUSE, {
    variables: { custom_url: customUrl, user_id: userId },
  });

  const [addUserFingerPrintId] = useMutation(INSERT_FINGERPRINT_IDS);
  const [addUserAttendence] = useMutation(INSERT_THP_USER_ATTENDANCE);

  const fpPromise = FingerprintJS.load();

  useEffect(() => {
    if (userId) {
      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          const visitorId = result.visitorId;
          await addUserFingerPrintId({
            variables: { fingerprintID: visitorId, userID: userId },
          });
        })
        .catch(() => {});

      const runFunc = async () => {
        try {
          await addUserAttendence({
            variables: {
              userID: userId,
            },
          });
        } catch (error) {}
      };
      runFunc();
    }
  }, [userId]);

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query verify {error.message}</div>;

  if (data.thp_list.length !== 0) {
    setTravelHouse(data.thp_list[0]);
    navigate(from);
  } else {
    if (userDefaultRole === "super-admin") {
      navigate(from);
    }
  }

  return (
    <div className="">
      {data.thp_list.length !== 0 && (
        <h2>
          Travel House Assigned, But something went wrong with you! {":("}
        </h2>
      )}
      {data.thp_list.length === 0 && <NoTravelHouse />}
    </div>
  );
}
