import React from "react";
import { useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_AIRLINE_DETAILS } from "./graphql/Query";
import TipsSection from "./components/TipsSection";
import AirlinePageContent from "./components/AirlinePageContent";
import AirlineFares from "./components/AirlineFares";
import AirlineDetail from "./components/AirlineDetail";
import BrandTipSection from "~/components/common/BrandTipSection";
import { useFileUpload } from "@nhost/react";
import {
  INSERT_TIPS,
  UPDATED_TIPS,
} from "./components/TipsSection/graphql/Mutation";
import { DELETE_TIP } from "../FlightTips/graphql/Mutation";
import { toast } from "react-toastify";
import BrandSeoSetting from "~/components/common/BrandSeoSetting";
import {
  INSERT_SEO,
  UPDATE_SEO,
} from "./components/SeoSettings/graphql/Mutation";

const AirlineUpdate = () => {
  const { page_id } = useParams();
  const { loading, data } = useQuery(GET_AIRLINE_DETAILS, {
    variables: { page_id: page_id },
  });

  const { upload, progress: logoUploadProgress } = useFileUpload();
  const [updateTip] = useMutation(UPDATED_TIPS);
  const [insertTip] = useMutation(INSERT_TIPS);
  const [deleteTip] = useMutation(DELETE_TIP);
  const [insertSeo] = useMutation(INSERT_SEO);
  const [updateSeo] = useMutation(UPDATE_SEO);
  const client = useApolloClient();

  const pageData = data?.brd_airlines_pages?.at(0);
  const tips = pageData?.flights_pages_tips;
  const seo = pageData?.seo_settings?.[0];

  const updateTipsSection = async (inpData: any) => {
    inpData?.tips?.map(async (ft: any) => {
      if (ft?.id) {
        // ;
        if (ft?.title !== "") {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
          };
          if (ft?.icon?.length > 0) {
            const iconRes = await upload(ft?.icon[0]);
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await updateTip({
            variables: {
              id: { id: ft.id },
              data: finalObj,
            },
          });

          if (res?.data?.update_brd_flights_pages_tips_by_pk?.id) {
            toast.success("Tips updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        } else if (ft?.title === "" && ft?.content === "") {
          // ;
          const res = await deleteTip({
            variables: {
              id: ft?.id,
            },
          });
        }
      } else {
        if (ft?.title) {
          const finalObj: any = {
            heading: ft?.title,
            description: ft?.content,
            airlines_page_id: pageData?.id,
          };
          if (ft?.icon?.length > 0) {
            // ;
            const iconRes = await upload(ft?.icon[0]);
            // ;
            finalObj["icon_url"] = iconRes?.id || null;
          }
          const res = await insertTip({
            variables: {
              data: finalObj,
            },
          });
          if (res?.data?.insert_brd_flights_pages_tips_one?.id) {
            toast.success("Tips updated successfully");
            await client.refetchQueries({
              include: "all",
            });
          }
        }
      }
    });
  };

  const insertSeoSettings = async (inpData: any) => {
    if (seo?.id) {
      const res = await updateSeo({
        variables: {
          id: { id: seo?.id },
          data: {
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,
          },
        },
      });

      if (res?.data?.update_brd_seo_setting_by_pk?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertSeo({
        variables: {
          data: {
            brd_id: pageData.brd_id,
            default_title: inpData?.title,
            default_description: inpData?.description,
            default_keywords: inpData?.keywords,
            canonical_url: inpData?.canonicalUrl,
            og_image_url: inpData?.openGraphImgUrl,
            airline_page_id: page_id,
          },
        },
      });

      if (res?.data?.insert_brd_seo_setting_one?.id) {
        toast.success("SEO  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  if (loading) return <p>Loading ...</p>;
  // ;

  if (pageData) {
    return (
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 ">
        <div className="mb-4 col-span-full xl:mb-2">
          <AirlineDetail page={pageData} />
          {/* <TipsSection page={pageData} />s */}
          <BrandTipSection tips={tips} insertTipsSection={updateTipsSection} />
          <AirlinePageContent page={pageData} />
          <AirlineFares page={pageData} brd_id={pageData.brd_id} />
          <BrandSeoSetting seo={seo} insertSeoSettings={insertSeoSettings} />
        </div>
      </div>
    );
  } else {
    return <p>No page details found</p>;
  }
};

export default AirlineUpdate;
