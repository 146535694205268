import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { GET_USER } from "./graphql/Query";
import moment from "moment";
import InputField from "~/components/FormInputs/InputField";
import { GET_ATTENDANCE } from "./graphql/Query";
import CheckMark from "~/assets/svg/CheckMark";

export default function SingleRecord({
  user,
  date,
  register,
  errors,
  index,
}: any) {
  const { data, error, loading } = useQuery(GET_ATTENDANCE, {
    variables: { user_id: user.id, date: moment(date).format("YYYY-MM-DD") },
  });

  let sendData: any = [];

  const Activity = user.def_user_activities;
  Activity.map((ite: any) => {
    let obj = { ...ite };
    obj["action"] = obj["action"] === "enter" ? "leave" : "enter";
    sendData.push(obj);
  });
  // ;

  let totalHours = 0;
  let enterTime: any = null;

  for (let item of sendData) {
    if (item.action === "enter") {
      // ;
      enterTime = new Date(item.timestamp);
    } else if (item.action === "leave" && enterTime) {
      // ;
      const leaveTime: any = new Date(item.timestamp);
      totalHours += leaveTime - enterTime;
      enterTime = null;
    }
  }

  // Convert time difference to hours and minutes
  const hours = Math.floor(totalHours / (1000 * 60 * 60));
  const minutesS = Math.floor((totalHours % (1000 * 60 * 60)) / (1000 * 60));

  // return loading while loading data
  if (loading) return <div>Loading...</div>;
  // Return error if get gql error
  if (error) return <div>Error in the query {error.message}</div>;

  return (
    <div className="flex gap-4 mt-4 p-4 text-primary dark:text-dark-primary border-gray-200 border-b-[5px] w-[50%]">
      <div className="w-[500px]">
        <h1 className="capitalize">
          {user.displayName} ({user.defaultRole})
        </h1>
        <span
          onClick={() => {}}
          className="cursor-pointer text-primary dark:text-dark-primary mt-2 text-[12px] text-bold underline"
        >
          Tracked: {hours}:{minutesS}
        </span>
      </div>

      {data?.thp_attendance?.[0]?.id && (
        <input
          {...register(`user.${index}.id`)}
          type="hidden"
          value={data?.thp_attendance?.[0]?.id}
        />
      )}

      <input
        {...register(`user.${index}.user_id`)}
        type="hidden"
        value={user.id}
      />

      <InputField
        register={register}
        errors={errors}
        type="text"
        fieldName={`user.${index}.hours`}
        required={true}
        placeHolder="Hour"
        className="w-full"
        defaultValue={
          data?.thp_attendance?.[0]?.id
            ? data?.thp_attendance?.[0]?.hours
            : hours
        }
      />
      <div className="w-[30px]">:</div>

      <InputField
        register={register}
        errors={errors}
        type="text"
        fieldName={`user.${index}.minutes`}
        required={true}
        placeHolder="Minutes"
        defaultValue={
          data?.thp_attendance?.[0]?.id
            ? data?.thp_attendance?.[0]?.minutes
            : minutesS
        }
      />
      <div>
        {data?.thp_attendance?.[0]?.id && (
          <span className="pt-2 block">
            <CheckMark color={"green"} />
          </span>
        )}
      </div>
    </div>
  );
}
