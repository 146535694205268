import React from "react";

export default function SuggestionNav({ suggestions, active, setActive }: any) {
  return (
    <div className="self-stretch flex flex-row pt-0 px-0 pb-[5px] items-center justify-start gap-[10px] border-b-[1px] border-solid border-lightsteelblue">
      <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
        {suggestions.map((sg: any, i: any) => (
          <>
            <>
              <div
                key={i}
                onClick={() => {
                  setActive(sg?.id);
                }}
                className={`cursor-pointer flex flex-row py-0 pr-2.5 pl-0 items-center justify-start ${
                  active === sg.id ? `text-darkslategray` : `text-gray-400`
                } border-r-[1px] border-solid border-lightsteelblue`}
              >
                <div className="flex flex-row py-0 pr-0 pl-2.5 items-start justify-start">
                  <b className="relative leading-[20px]">
                    Suggestion # {i + 1}
                  </b>
                </div>
              </div>
            </>
          </>
        ))}
      </div>
    </div>
  );
}
