import { Box, Button, Divider, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { GET_DEFAULT_SETTINGS } from "./graphql/Query";

const AirlineSettings = ({ setSidePopup, sidePopup }: any) => {
  const { brd_id } = useParams();
  const { loading, data } = useQuery(GET_DEFAULT_SETTINGS, {
    variables: { id: brd_id },
  });
  const defSettings = data?.brd_default_setting?.at(0);
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const client = useApolloClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inpData: any) => {
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            airline_page_heading: inpData?.airline_page_heading,
            airline_page_sub_heading: inpData?.airline_page_sub_heading,
            airline_page_sub_heading_2: inpData?.airline_page_sub_heading_2,
            airline_tips_heading: inpData?.airline_tips_heading,
            airline_tips_sub_heading: inpData?.airline_tips_sub_heading,
            airline_blog_heading: inpData?.airline_blog_heading,
            airline_blog_sub_heading: inpData?.airline_blog_sub_heading,
            airline_links_heading: inpData?.airline_links_heading,
            airline_links_sub_heading: inpData?.airline_links_sub_heading,
            airline_review_heading: inpData?.airline_review_heading,
            airline_review_sub_heading: inpData?.airline_review_sub_heading,
            airline_best_flights_heading: inpData?.airline_best_flights_heading,
            airline_best_flights_sub_heading:
              inpData?.airline_best_flights_sub_heading,
          },
        },
      });
      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            airline_page_heading: inpData?.airline_page_heading,
            airline_page_sub_heading: inpData?.airline_page_sub_heading,
            airline_page_sub_heading_2: inpData?.airline_page_sub_heading_2,
            airline_tips_heading: inpData?.airline_tips_heading,
            airline_tips_sub_heading: inpData?.airline_tips_sub_heading,
            airline_blog_heading: inpData?.airline_blog_heading,
            airline_blog_sub_heading: inpData?.airline_blog_sub_heading,
            airline_links_heading: inpData?.airline_links_heading,
            airline_links_sub_heading: inpData?.airline_links_sub_heading,
            airline_review_heading: inpData?.airline_review_heading,
            airline_review_sub_heading: inpData?.airline_review_sub_heading,
            airline_best_flights_heading: inpData?.airline_best_flights_heading,
            airline_best_flights_sub_heading:
              inpData?.airline_best_flights_sub_heading,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="lg:w-[1000px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          Airline Page Setting
        </h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 mt-10 gap-5">
            <TextField
              error={errors["airline_page_heading"] ? true : false}
              {...register("airline_page_heading", { required: true })}
              label="Airline Main Heading"
              type="text"
              defaultValue={defSettings?.airline_page_heading}
            />
            <TextField
              error={errors["airline_page_sub_heading"] ? true : false}
              {...register("airline_page_sub_heading", { required: true })}
              label="Airline Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_page_sub_heading}
            />
            <TextField
              error={errors["airline_page_sub_heading_2"] ? true : false}
              {...register("airline_page_sub_heading_2", { required: true })}
              label="Airline Sub Heading 2"
              type="text"
              defaultValue={defSettings?.airline_page_sub_heading_2}
            />
            <TextField
              error={errors["airline_tips_heading"] ? true : false}
              {...register("airline_tips_heading", { required: true })}
              label="Airline Tips Heading"
              type="text"
              defaultValue={defSettings?.airline_tips_heading}
            />
            <TextField
              error={errors["airline_tips_sub_heading"] ? true : false}
              {...register("airline_tips_sub_heading", { required: true })}
              label="Airline Tips Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_tips_sub_heading}
            />
            <TextField
              error={errors["airline_blog_heading"] ? true : false}
              {...register("airline_blog_heading", { required: true })}
              label="Airline Blogs Heading"
              type="text"
              defaultValue={defSettings?.airline_blog_heading}
            />
            <TextField
              error={errors["airline_blog_sub_heading"] ? true : false}
              {...register("airline_blog_sub_heading", { required: true })}
              label="Airline Blogs Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_blog_heading}
            />
            <TextField
              error={errors["airline_review_heading"] ? true : false}
              {...register("airline_review_heading", { required: true })}
              label="Airline Reviews Heading"
              type="text"
              defaultValue={defSettings?.airline_review_heading}
            />
            <TextField
              error={errors["airline_review_sub_heading"] ? true : false}
              {...register("airline_review_sub_heading", { required: true })}
              label="Airline Reviews Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_review_sub_heading}
            />
            <TextField
              error={errors["airline_best_flights_heading"] ? true : false}
              {...register("airline_best_flights_heading", { required: true })}
              label="Airline Best Flights Heading"
              type="text"
              defaultValue={defSettings?.airline_best_flights_heading}
            />
            <TextField
              error={errors["airline_best_flights_sub_heading"] ? true : false}
              {...register("airline_best_flights_sub_heading", {
                required: true,
              })}
              label="Airline Best Flights Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_best_flights_sub_heading}
            />
            <TextField
              error={errors["airline_links_heading"] ? true : false}
              {...register("airline_links_heading", {
                required: true,
              })}
              label="Airline Link Section Heading"
              type="text"
              defaultValue={defSettings?.airline_links_heading}
            />
            <TextField
              error={errors["airline_links_sub_heading"] ? true : false}
              {...register("airline_links_sub_heading", {
                required: true,
              })}
              label="Airline Link Section Sub Heading"
              type="text"
              defaultValue={defSettings?.airline_links_sub_heading}
            />
          </div>
          <Button
            sx={{ width: "100%", marginTop: "15px" }}
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AirlineSettings;
