import { gql } from "@apollo/client";

export const GET_THP_USER_ATTENDANCE = gql`
  query getThpUserAttendance($userId: uuid!, $attendanceDate: date!) {
    thp_user_attendance(
      where: {
        user_id: { _eq: $userId }
        attendance_date: { _eq: $attendanceDate }
      }
    ) {
      current_breaktime
    }
  }
`;
