import React from "react";

export default function AirPlane() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92195 8.02632L5.46844 13.5L4.10152 13.5L5.82794 8.02632L2.16461 8.02632L1.02597 10.0789L0.000782548 10.0789L0.684239 7L0.000782817 3.92105L1.02597 3.92105L2.16529 5.97368L5.82862 5.97368L4.10152 0.5L5.46844 0.5L8.92195 5.97368L12.6447 5.97368C12.9166 5.97368 13.1774 6.08181 13.3697 6.27429C13.5619 6.46676 13.6699 6.7278 13.6699 7C13.6699 7.2722 13.5619 7.53324 13.3697 7.72572C13.1774 7.91819 12.9166 8.02632 12.6447 8.02632L8.92195 8.02632Z"
        fill="#393939"
      />
    </svg>
  );
}
