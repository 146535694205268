import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { CalendarBox } from "~/components/FormInputs/CalendarBox";

export default function SearchForm({
  date,
  setDates,
  onSearch,
  setLoadForm,
  control,
  errors,
  setValue,
}: any) {
  return (
    <>
      <div className="flex gap-2 w-[50%] mt-4">
        {/* <CalendarBox
          control={control}
          setValue={setValue}
          errors={errors}
          fieldName="date"
          label="Select Date"
          defaultValue={date}
          maxDate={moment(new Date()).subtract(1, "days").format()}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker label="Mark Your Attendance" />
          </DemoContainer>
        </LocalizationProvider>
        <button
          className="mt-[20px] btn bg-basic px-[10px] text-white rounded-md w-[200px] "
          type="button"
          onClick={() => {
            onSearch();
          }}
        >
          Get Record
        </button>
      </div>
    </>
  );
}
