import { useApolloClient, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ADD_TRANSACTIONS } from "../../graphql/Mutations";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

export default function BankPaymentForm({
  paymentMethods,
  inquiry_id,
  picked_by,
  inquiry,
  user_id,
  setHideForm,
  payment_method_id,
}: any) {
  const emailSend = useEmail();
  const [addTransactions] = useMutation(ADD_TRANSACTIONS);
  const client = useApolloClient();
  const [isloading, setIsloading] = useState(false);
  const userUserName = useUserDisplayName();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const alert = useNotifications();
  const userRole = useUserDefaultRole();

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);
  const userId = useUserId();

  const onSubmit = async (formData: any) => {
    setIsloading(true);
    formData["brd_id"] = inquiry?.brd_id;
    formData["inq_id"] = inquiry_id; // add inquiry id to formdata
    formData["status"] = "request"; // add status  to formdata

    formData["ref_no"] = ""; // add receipt to formdata
    formData["receipt"] = null; // add receipt to formdata

    const tenDigitCode: any = generateRandom10DigitCode();
    formData["transactions_no"] = tenDigitCode; // add status  to formdata
    try {
      // Create Suggestion function call
      const res = await addTransactions({
        variables: { transactions: [formData] },
      });

      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      if (res.data?.insert_inq_transection?.returning?.length > 0) {
        const variables: any = {
          inquiryNo: inquiry?.inquiry_no,
          userFirstName: inquiry?.users?.displayName,
          userLastName: "",
          consultantName: userUserName,
          brandName: inquiry?.brd_list?.name,
          amount: formData.amount,
          bankName: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].name,
          accountTitle: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].account_title,
          accountNumber: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].account_no,
          sortCode: paymentMethods.filter(
            (pay: any) => pay.id === formData.payment_method_id
          )[0].swift_code,
        };

        // ;
        await emailSend(
          8,
          inquiry.brd_id,
          inquiry.users?.id,
          inquiry.users?.email,
          inquiry.users?.phoneNumber,
          variables
        );

        toast.success("Transaction added successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await alert.newNotification(
          "accountant",
          `Pending payment approval. Please check your portal`,
          "",
          ``
        );

        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Customer created the bank payment, please check your portal`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your payment is created by consultant, please check your portal`,
            "",
            ``
          );
        }

        await client.resetStore();
      }
      setIsloading(false);
      setHideForm(false); // hide form modal
    } catch (e) {
      setIsloading(false);
      setHideForm(false);
      console.error("error", e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full">
          {userRole !== "user" ? (
            <div className="mb-4">
              <select
                {...register("payment_method_id", {
                  required: "Type is required",
                })}
                aria-invalid={errors.type ? "true" : "false"}
                className="w-full px-3 py-3 border rounded focus:outline-none focus:border-green-500 leading-tight border-gray-300"
              >
                <option value="">--Select Bank --</option>
                {paymentMethods.map((pay: any) => {
                  return (
                    <option key={`${pay?.id}`} value={`${pay?.id}`}>
                      {pay.name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div className="mb-4">
              <div>Bank: {paymentMethods[0].name}</div>
              <div>Account Title: {paymentMethods[0].account_title}</div>
              <div>Account No: #{paymentMethods[0].account_no}</div>
              <div>Sort Code: {paymentMethods[0].swift_code}</div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="">
            <input
              className="border border-slate-300 py-[5px] h-[44px] px-[15px] text-[14px] rounded-[5px] w-full"
              type="number"
              placeholder="Amount"
              {...register("amount", {
                required: "Amount is required",
              })}
              aria-invalid={errors.amount ? "true" : "false"}
            />
          </div>
        </div>
        <button
          type="submit"
          className="bg-basic block w-full text-white px-5 py-2 rounded hover:cursor-pointer hover:bg-purple-700 transition-all mt-[20px]"
        >
          {isloading ? "Adding.." : "Add Payment"}
        </button>
      </form>
    </>
  );
}
