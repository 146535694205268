import "./App.css";
import "./content-style.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Import Multi User Routes
import AdminRoutes from "./routes/AdminRoutes";
import AccountantRoutes from "./routes/AccountantRoutes";
import ConsultantRoutes from "./routes/ConsultantRoutes";
import UserRoutes from "./routes/UserRoutes";

// Import Pages
import Login from "./pages/Login";
import Verify from "./pages/Verify";
import SuperAdminRoutes from "./routes/SuperAdminRoutes";
import BrandsRoutes from "./routes/BrandsRoutes";
import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { EMAIL_SYNC } from "./graphql/brd_list/Mutation";
import { useApolloClient, useMutation } from "@apollo/client";
import moment from "moment";
import { UPDATE_USER_LAST_SEEN } from "./graphql/users/Mutation";
import {
  UPDATE_THP_USER_ATTENDENCE,
  INSERT_USER_BREAK,
} from "./graphql/thp_user_attendance/Mutation";
import { useUserId } from "@nhost/react";
import { GET_THP_USER_ATTENDANCE } from "./graphql/thp_user_attendance/Query";

function App() {
  const handleStorageChange = () => {
    const storedData = localStorage.getItem("darkMode");
    setDarkMode(storedData ? JSON.parse(storedData) : false);
  };

  const userId = useUserId();

  const client = useApolloClient();

  const [updateUserLastSeen] = useMutation(UPDATE_USER_LAST_SEEN);
  const [updateThpUserAtendance] = useMutation(UPDATE_THP_USER_ATTENDENCE);
  const [insertBreak] = useMutation(INSERT_USER_BREAK);

  const storedData = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(
    storedData ? JSON.parse(storedData) : false
  );
  window.addEventListener("storage", handleStorageChange);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#9155FD",
      },
      background: {
        default: "#312D4B",
        paper: "#312D4B",
      },
      text: {
        primary: "#E7E3FCDE",
        secondary: "#E7E3FCDE",
      },
    },
  });

  const setUserLastOnlineTime = async () => {
    if (userId) {
      await updateUserLastSeen({
        variables: {
          userId: userId,
          lastSeen: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"),
        },
      });
    }
  };

  const checkHiddenDuration = async () => {
    if (userId) {
      await updateThpUserAtendance({
        variables: {
          userID: userId,
          attendanceDate: moment().format("YYYY-MM-DD"),
          currentBreakTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"),
          nullBreakTime: true,
          nullOutTime: true,
        },
      });
    }
  };

  const updateBreakTimeFunc = async () => {
    const thpUserAttendance = await client.query({
      query: GET_THP_USER_ATTENDANCE,
      variables: {
        userId: userId,
        attendanceDate: moment().format("YYYY-MM-DD"),
      },
      fetchPolicy: "network-only",
    });
    if (thpUserAttendance?.data?.thp_user_attendance?.[0]?.current_breaktime) {
      if (
        moment().diff(
          moment(
            thpUserAttendance?.data?.thp_user_attendance?.[0]?.current_breaktime
          ),
          "minutes"
        ) > 0
      ) {
        await insertBreak({
          variables: {
            userID: userId,
            startDate:
              thpUserAttendance?.data?.thp_user_attendance?.[0]
                ?.current_breaktime,
            endDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZZ"),
            breakTime: moment().diff(
              moment(
                thpUserAttendance?.data?.thp_user_attendance?.[0]
                  ?.current_breaktime
              ),
              "minutes"
            ),
          },
        });
      }
      await updateThpUserAtendance({
        variables: {
          userID: userId,
          attendanceDate: moment().format("YYYY-MM-DD"),
          currentBreakTime: null,
          nullBreakTime: false,
          nullOutTime: true,
        },
      });
    }
  };
  useEffect(() => {
    if (userId) {
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check if the tab is active before updating
      if (document.visibilityState === "visible") {
        setUserLastOnlineTime();
      } else if (document.visibilityState === "hidden") {
        checkHiddenDuration();
      }
    }, 55555); // Run every 60 seconds

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateBreakTimeFunc();
      }
    };

    window.addEventListener("beforeunload", checkHiddenDuration);

    updateBreakTimeFunc();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userId]);

  const theme2 = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#9155FD",
      },
      background: {
        default: "#FFFFFF",
        paper: "#FFFFFF",
      },
      text: {
        primary: "#3A3541DE",
        secondary: "#3A3541DE",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={darkMode ? theme : theme2}>
        <BrowserRouter>
          <SuperAdminRoutes />
          <AdminRoutes />
          <BrandsRoutes />
          <ConsultantRoutes />
          <AccountantRoutes />
          <UserRoutes />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/" element={<Navigate to={"/login"} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
