import Layout from "~/components/common/Layout";
import DashboardModule from "~/modules/DashboardModule";
import DashboardStats from "~/modules/DashboardModule/segments/DashboardStats";
import SuperAdmDashboard from "~/modules/DashboardModule/segments/SuperAdmDashboard";

export default function Dashboard() {
  return (
    <div>
      <Layout>
        <DashboardModule>
          {/* <DashboardStats /> */}

          
          <SuperAdmDashboard />
        </DashboardModule>
      </Layout>
    </div>
  );
}

