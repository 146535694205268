import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import React, { useState } from "react";
import { EDIT_INQUIRY } from "../../../../graphql/Mutation";
import { toast } from "react-toastify";
import useNotifications from "hooks/useNotifications";
import {
  useUserDefaultRole,
  useUserDisplayName,
  useUserId,
} from "@nhost/react";
import { CustomerTemplates } from "~/utils/TemplatesAll";
import { SEND_EMAIL } from "~/config/Mutation";
import { sendGenericEmail } from "~/utils/sendEmail";

import {
  GET_EMAILS,
  GET_TRAVEL_HOUSE_BY_ID,
} from "~/modules/InboxModule/segments/Inbox/graphql/Query";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useUserData } from "@nhost/react";
import useEmail from "~/hooks/emails/useEmail";
import { ADD_INVOICE } from "./graphql/Mutation";
import { UPDATE_INQ_UPDATE_USER } from "~/graphql/inq_list/Mutation";

export default function SuggestionSelect({
  inquiry_id,
  user_id,
  picked_by,
  user_email,
  brand_url,
  brand_domain,
  brand_name,
  suggestion_id,
  suggestion,
  inquiry,
}: any) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const emailSend = useEmail();
  const userUserName = useUserDisplayName();
  const [sendEmail] = useMutation(SEND_EMAIL);
  const client = useApolloClient();
  const userRole = useUserDefaultRole();
  const { travelHouse }: any = travelHouseStore();
  const userData = useUserData();
  const userId = useUserId();

  const { loading: travelHouseLoading, data: travelHouseData } = useQuery(
    GET_TRAVEL_HOUSE_BY_ID,
    {
      variables: { id: travelHouse?.id },
      fetchPolicy: "network-only",
    }
  );
  const [editInquiry] = useMutation(EDIT_INQUIRY);

  const [updateInqListUser] = useMutation(UPDATE_INQ_UPDATE_USER);

  const alert = useNotifications();
  const handleConfirm = async (event: any) => {
    event.stopPropagation();
    setIsloading(true);

    const invoiceItems: any = [];
    suggestion.inq_suggestion_costs.map((cost: any) => {
      invoiceItems.push({
        amount: cost.sale_price,
        description: `${cost?.inq_passenger?.first_name} ${cost?.inq_passenger?.last_name} (${cost?.inq_passenger?.type})`,
      });
    });

    try {
      await updateInqListUser({
        variables: { id: inquiry.id, updated_by: userId },
      });

      // Create passenger function call
      const res = await editInquiry({
        variables: { id: inquiry_id, selected_suggestion_id: suggestion_id },
      });

      if (res.data?.update_inq_list?.returning?.length > 0) {
        if (userRole === "user") {
          await alert.newNotification(
            picked_by,
            `Your customer has selected fare options for you.`,
            "",
            ``
          );
        } else {
          await alert.newNotification(
            user_id,
            `Your consultant has selected fare options for you.`,
            "",
            ``
          );
          const variables: any = {
            inquiryNo: inquiry?.inquiry_no,
            userFirstName: inquiry?.users?.displayName,
            userLastName: "",
            consultantName: userUserName,
            brandName: inquiry?.brd_list?.name,
          };

          // ;
          await emailSend(
            5,
            inquiry.brd_id,
            inquiry.users?.id,
            inquiry.users?.email,
            inquiry.users?.phoneNumber,
            variables
          );
        }

        toast.success("Suggestion Selected successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        await client.resetStore();
      }
      //   setIsloading(false); // change button loading state
      setModalOpen(false);
      setIsloading(false);
    } catch (e) {
      setModalOpen(false);
      setIsloading(false);
      console.error("error", e);
    }
  };
  return (
    <>
      <span
        onClick={() => {
          setModalOpen(true);
        }}
        className=" bg-basic cursor-pointer rounded-[5px] mt-2 py-2 px-5 text-white absolute top-[-1px] right-[100px] md:right-[130px]"
      >
        {isloading ? "Selecting.." : "Select"}
      </span>

      {modalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 50 }}
        >
          <div className="relative bg-white rounded-xl shadow-2xl p-8 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
            <h2 className="mb-6 text-2xl font-bold text-gray-700">
              Are you sure?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={(event: any) => setModalOpen(false)}
                className="px-5 py-2 font-semibold text-gray-700 bg-red-500 bg-opacity-10 rounded-full hover:bg-opacity-20"
              >
                No
              </button>
              <button
                onClick={(event: any) => handleConfirm(event)}
                className="px-5 py-2 font-semibold text-white bg-green-500 rounded-full hover:bg-green-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
