import { Box, Button, Divider, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  INSERT_DEFAULT_SETTING,
  UPDATE_DEFAULT_SETTING,
} from "./graphql/Mutation";
import { toast } from "react-toastify";

const DestinationSetting = ({ defSettings, brd_id }: any) => {
  const [updateDefaultSettings] = useMutation(UPDATE_DEFAULT_SETTING);
  const [insertDefaultSettings] = useMutation(INSERT_DEFAULT_SETTING);
  const client = useApolloClient();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (inpData: any) => {
    // ;
    if (defSettings?.id) {
      const res = await updateDefaultSettings({
        variables: {
          id: { id: defSettings?.id },
          data: {
            ...inpData,
          },
        },
      });

      if (res?.data?.update_brd_default_setting_by_pk?.id) {
        toast.success("Settings  updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    } else {
      const res = await insertDefaultSettings({
        variables: {
          data: {
            brd_id: brd_id,
            ...inpData,
          },
        },
      });

      if (res?.data?.insert_brd_default_setting_one?.id) {
        toast.success("Settings updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
      }
    }
  };

  return (
    <>
      <div className="lg:w-[1000px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
        <h1 className="text-center text-3xl text-basic ">
          Destination Page Setting
        </h1>
        <Divider />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid md:grid-cols-2 grid-cols-1 mt-10 gap-5">
            <TextField
              error={errors["hot_destinations_heading"] ? true : false}
              {...register("hot_destinations_heading", { required: true })}
              label="Homepage Destination Heading"
              type="text"
              defaultValue={defSettings?.hot_destinations_heading}
            />
            <TextField
              error={errors["hot_destinations_sub_heading"] ? true : false}
              {...register("hot_destinations_sub_heading", { required: true })}
              label="Homepage Destination Sub Heading"
              type="text"
              defaultValue={defSettings?.hot_destinations_sub_heading}
            />
            <TextField
              error={errors["destinations_main_heading"] ? true : false}
              {...register("destinations_main_heading", {
                required: true,
              })}
              label="Craft Your Destination Page Main Heading"
              type="text"
              helperText="Available variables are {{depart_destination}}, {{arrival_destination}}, {{cheap_price}}"
              defaultValue={defSettings?.destinations_main_heading}
            />
            <TextField
              error={errors["destinations_button_text"] ? true : false}
              {...register("destinations_button_text", {
                required: true,
              })}
              label="Homepage Button Title For Hot Destinations"
              type="text"
              helperText="Available variables are {{depart_destination}}, {{arrival_destination}}"
              defaultValue={defSettings?.destinations_button_text}
            />
            <TextField
              error={errors["destinations_footer_text"] ? true : false}
              {...register("destinations_footer_text", {
                required: true,
              })}
              label="Footer Link Title For Hot Destinations"
              type="text"
              helperText="Available variables are {{depart_destination}}, {{arrival_destination}}"
              defaultValue={defSettings?.destinations_footer_text}
            />
            <TextField
              error={errors["destination_blogs_heading"] ? true : false}
              {...register("destination_blogs_heading", {
                required: true,
              })}
              label="Destination Page Blogs Heading"
              type="text"
              defaultValue={defSettings?.destination_blogs_heading}
            />
            <TextField
              error={errors["destination_blogs_sub_heading"] ? true : false}
              {...register("destination_blogs_sub_heading", {
                required: true,
              })}
              label="Destination Page Blogs Sub Heading"
              type="text"
              defaultValue={defSettings?.destination_blogs_sub_heading}
            />
            <TextField
              error={errors["destination_tips_heading"] ? true : false}
              {...register("destination_tips_heading", {
                required: true,
              })}
              label="Destination Page Tips Heading"
              type="text"
              defaultValue={defSettings?.destination_tips_heading}
            />
            <TextField
              error={errors["destination_tips_sub_heading"] ? true : false}
              {...register("destination_tips_sub_heading", {
                required: true,
              })}
              label="Destination Page Tips Sub Heading"
              type="text"
              defaultValue={defSettings?.destination_tips_sub_heading}
            />
            <TextField
              error={errors["gallery_heading"] ? true : false}
              {...register("gallery_heading", {
                required: true,
              })}
              label="Gallery Heading"
              type="text"
              defaultValue={defSettings?.gallery_heading}
            />
            <TextField
              error={errors["gallery_sub_heading"] ? true : false}
              {...register("gallery_sub_heading", {
                required: true,
              })}
              label="Gallery Sub Heading"
              type="text"
              defaultValue={defSettings?.gallery_sub_heading}
            />
            <TextField
              error={errors["destination_contact_heading"] ? true : false}
              {...register("destination_contact_heading", {
                required: true,
              })}
              label="Destination Page Contact Heading"
              type="text"
              defaultValue={defSettings?.destination_contact_heading}
            />
            <TextField
              error={errors["destination_contact_sub_heading"] ? true : false}
              {...register("destination_contact_sub_heading", {
                required: true,
              })}
              label="Destination Page Contact Sub Heading"
              type="text"
              defaultValue={defSettings?.destination_contact_sub_heading}
            />
            <TextField
              error={errors["flight_links_heading"] ? true : false}
              {...register("flight_links_heading", {
                required: true,
              })}
              label="Flight Links Section Heading"
              type="text"
              defaultValue={defSettings?.flight_links_heading}
            />
            <div className="md:col-span-2">
              <TextField
                sx={{ width: "100%" }}
                error={errors["flight_links_sub_heading"] ? true : false}
                {...register("flight_links_sub_heading", {
                  required: true,
                })}
                label="Flight Links Section Sub Heading"
                type="text"
                defaultValue={defSettings?.flight_links_sub_heading}
              />
            </div>
            <div className="md:col-span-2 my-3">
              <Divider />
              <Divider />
            </div>
            <TextField
              error={errors["better_deal_inquiry_submit_text"] ? true : false}
              {...register("better_deal_inquiry_submit_text", {
                required: true,
              })}
              label="Better Deal Submit Button Text"
              type="text"
              defaultValue={defSettings?.better_deal_inquiry_submit_text}
            />
            <TextField
              error={errors["better_deal_heading"] ? true : false}
              {...register("better_deal_heading", {
                required: true,
              })}
              label="Better Deal Heading"
              type="text"
              defaultValue={defSettings?.better_deal_heading}
            />
            <TextField
              error={errors["reserve_fare_text"] ? true : false}
              {...register("reserve_fare_text", {
                required: true,
              })}
              label="Reserve Fare Text"
              type="text"
              defaultValue={defSettings?.reserve_fare_text}
            />
            <TextField
              error={errors["better_deal_text"] ? true : false}
              {...register("better_deal_text", {
                required: true,
              })}
              label="Better Deal Text"
              type="text"
              defaultValue={defSettings?.better_deal_text}
            />
            <TextField
              error={errors["call_only_fare_text"] ? true : false}
              {...register("call_only_fare_text", {
                required: true,
              })}
              label="Call Only Fare Text"
              type="text"
              defaultValue={defSettings?.call_only_fare_text}
            />
            <TextField
              error={errors["request_call_back_text"] ? true : false}
              {...register("request_call_back_text", {
                required: true,
              })}
              label="Request Call Back Text"
              type="text"
              defaultValue={defSettings?.request_call_back_text}
            />
            <div className="md:col-span-2">
              <TextField
                sx={{ width: "100%" }}
                error={errors["share_button_text"] ? true : false}
                {...register("share_button_text", {
                  required: true,
                })}
                label="Share Button Text"
                type="text"
                defaultValue={defSettings?.share_button_text}
              />
            </div>
          </div>
          <Button
            variant="contained"
            sx={{ marginTop: "20px", width: "100%" }}
            type="submit"
          >
            Update
          </Button>
        </Box>
      </div>
    </>
  );
};

export default DestinationSetting;
