import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PAYMENT_METHODS } from "../PaymentAdd/graphql/Query";
import { toast } from "react-toastify";
import moment from "moment";
import { generateRandom10DigitCode } from "~/utils/generateRandomId";
import {
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
} from "~/modules/AccountModule/segments/PaymentRequest/components/PaymentRecord/graphql/Mutation";
import {
  COMPLETE_INQ,
  EDIT_INQUIRY,
} from "../../../TicketRequest/components/CompleteInquiry/graphql/Mutation";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  outline: "3px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

const RefundPopup = ({ open, setOpen, inquiry }: any) => {
  const [showChildMdl, setShowChildMdl] = useState(false);
  const [amount, setAmount] = useState<any>(null);
  const [remIncAmt, setRemIncAmt] = useState<any>(false);

  const [selectedPaymentMeth, setSelectedPaymentMeth] = useState<any>();

  const [date, setDate] = useState<any>(new Date());

  const { travelHouse }: any = travelHouseStore();

  const { data: paymentMethods } = useQuery(GET_PAYMENT_METHODS, {
    variables: { th_id: travelHouse.id, brd_id: inquiry?.brd_id },
  });

  const [addTransaction] = useMutation(ADD_TRANSACTION);
  const [editTransaction] = useMutation(UPDATE_TRANSACTION);
  const [editInquiry] = useMutation(COMPLETE_INQ);

  const createBankRefund = async () => {
    const tenDigitCode: any = generateRandom10DigitCode();
    const transactions: any = [
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: 4,
        inq_id: inquiry?.id,
        user_id: inquiry?.users?.id,
        payment_method_id: selectedPaymentMeth?.id,
        transactions_no: `${tenDigitCode}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "debit",
        amount: !remIncAmt
          ? parseFloat(amount)
          : parseFloat(
              inquiry?.inq_transections
                ?.filter((transaction: any) =>
                  ["paid", "partiallyRefunded"].includes(transaction.status)
                )
                ?.map((transaction: any) =>
                  transaction.status === "partiallyRefunded"
                    ? transaction.amount - (transaction.refund_amount || 0)
                    : transaction.amount
                )
                ?.reduce((sum: number, amount: number) => sum + amount, 0) || 0
            ).toFixed(2),
      },
      {
        brd_id: inquiry?.brd_id,
        def_acc_id: selectedPaymentMeth?.type === "bank" ? 3 : 14,
        inq_id: inquiry?.id,
        payment_method_id: selectedPaymentMeth?.id,
        transactions_no: `${tenDigitCode}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        user_id: inquiry?.users?.id,
        note: "Refund",
        type: "credit",
        amount: -parseFloat(amount),
      },
    ];
    if (
      amount <
        inquiry?.inq_transections
          ?.filter((transaction: any) =>
            ["paid", "partiallyRefunded"].includes(transaction.status)
          )
          ?.map((transaction: any) =>
            transaction.status === "partiallyRefunded"
              ? transaction.amount - (transaction.refund_amount || 0)
              : transaction.amount
          )
          ?.reduce((sum: number, amount: number) => sum + amount, 0) &&
      remIncAmt
    ) {
      transactions.push({
        brd_id: inquiry?.brd_id,
        def_acc_id: 16,
        inq_id: inquiry?.id,
        payment_method_id: selectedPaymentMeth?.id,
        transactions_no: `${tenDigitCode}-RF`,
        date: moment(date).format("YYYY-MM-DD"),
        created_at: moment(date).format("YYYY-MM-DD"),
        type: "credit",
        amount: -(
          parseFloat(
            inquiry?.inq_transections
              ?.filter((transaction: any) =>
                ["paid", "partiallyRefunded"].includes(transaction.status)
              )
              ?.map((transaction: any) =>
                transaction.status === "partiallyRefunded"
                  ? transaction.amount - (transaction.refund_amount || 0)
                  : transaction.amount
              )
              ?.reduce((sum: number, amount: number) => sum + amount, 0) || 0
          ) - parseFloat(amount)
        ),
      });
    }

    const res = await addTransaction({
      variables: {
        transactions: transactions,
      },
    });

    inquiry?.inq_transections
      ?.filter((transaction: any) =>
        ["paid", "partiallyRefunded"].includes(transaction.status)
      )
      ?.map(async (transaction: any) => {
        const payload = {
          id: transaction.id,
          status: "adjusted",
          refundAmount: parseFloat(amount),
        };

        await editTransaction({ variables: payload });
      });

    if (res.data?.insert_acc_transactions?.returning?.length > 0) {
      await editInquiry({
        variables: {
          id: inquiry.id,
          status: "refunded",
          completedAt: moment(date).format("YYYY-MM-DD"),
        },
      });
      toast.success("Transaction Refunded Successfully");
      setShowChildMdl(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 800 }}>
          <h2 className="font-bold" id="parent-modal-title">
            Refund Your Amount
          </h2>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                defaultValue={dayjs(new Date())}
                onChange={(date: any) => {
                  setDate(date?.$d);
                }}
                sx={{ width: "100%" }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Autocomplete
            disablePortal
            onChange={(_, data) => {
              setSelectedPaymentMeth(data);
            }}
            options={paymentMethods?.acc_payment_methods?.filter(
              (pay: any) =>
                pay.type === "bank" || pay.card_terminal_type === "Manual"
            )}
            getOptionLabel={(option: any) => option.name}
            renderOption={(props, option: any) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField {...params} label="Select Payment Method" />
            )}
          />

          <TextField
            onChange={(e: any) => setAmount(e.target.value)}
            value={amount}
            label="Amount"
          />

          {amount <
            inquiry?.inq_transections
              ?.filter((transaction: any) =>
                ["paid", "partiallyRefunded"].includes(transaction.status)
              )
              ?.map((transaction: any) =>
                transaction.status === "partiallyRefunded"
                  ? transaction.amount - transaction.refund_amount
                  : transaction.amount
              )
              ?.reduce((sum: number, amount: number) => sum + amount, 0) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={remIncAmt}
                  onChange={() => setRemIncAmt(!remIncAmt)}
                />
              }
              label="Remaining amount is your income?"
            />
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (!amount) {
                toast.error("Enter your Refund Amount");
                return;
              }
              setShowChildMdl(true);
            }}
          >
            Refund
          </Button>
        </Box>
      </Modal>
      <Modal
        open={showChildMdl}
        onClose={() => setShowChildMdl(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <h2 className="font-bold" id="parent-modal-title">
            Are You Sure?
          </h2>
          <div className="flex gap-3">
            <Button
              variant="contained"
              onClick={() => {
                if (
                  amount >
                  inquiry?.inq_transections
                    ?.filter((transaction: any) =>
                      ["paid", "partiallyRefunded"].includes(transaction.status)
                    )
                    ?.map((transaction: any) =>
                      transaction.status === "partiallyRefunded"
                        ? transaction.amount - transaction.refund_amount
                        : transaction.amount
                    )
                    ?.reduce((sum: number, amount: number) => sum + amount, 0)
                ) {
                  toast.error("Refund amount is greater then actual amount");
                } else if (inquiry?.inq_invoice) {
                  toast.error("First Void your Invoice");
                } else {
                  createBankRefund();
                }
              }}
            >
              Yes
            </Button>
            <Button variant="outlined" onClick={() => setShowChildMdl(false)}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RefundPopup;
